import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoUtil, LoggedInCallback} from '../service/cognito.service';
import {UserLoginService} from '../service/user-login.service';
import {SettingsService} from '../service/settings.service';
import {UserProfileService} from '../service/user-profile.service';
import {NavbarService} from '../service/navbar.service';
import {UserProfile} from '../users/UserProfile';
import {StripeCheckoutService} from '../service/stripe_checkout.service';
import {PsSubscriptionPlanService} from '../service/ps_subscription_plan.service';
import {SubscriptionPlanResponse} from './subscription.plan.response';
import {Observable, of} from 'rxjs';


@Component({
    selector: '[id=content-wrapper]',
    templateUrl: './subscription-plan.component.html',
    styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit, LoggedInCallback {
    public currentUserProfile: UserProfile;
    public userBelongsToGroup: boolean;
    public subscriptionPlanResponse$: Observable<SubscriptionPlanResponse[]>;
    public stripePlanId: string;
    public collaborationPlatformName: string;
    public errorMessage: string;

    constructor(public nav: NavbarService,
                private http: HttpClient,
                private router: Router,
                public cognitoUtil: CognitoUtil,
                private activatedRoute: ActivatedRoute,
                private auth: UserLoginService,
                private settingsService: SettingsService,
                private userProfileService: UserProfileService,
                private stripeCheckoutService: StripeCheckoutService,
                private  subscriptionPlanService: PsSubscriptionPlanService) {
        this.auth.isAuthenticated(this);
    }

    ngOnInit() {
        // Hide the Navbar component
        setTimeout(() => {
            this.nav.hide();
        }, 0);

        const myThis = this;
        this.userProfileService.loggedInUserProfile.subscribe(profile => {
            myThis.currentUserProfile = profile;
            if (profile === undefined || !profile.hasGroup) {
                myThis.userBelongsToGroup = false;
                return;
            } else {
                myThis.userBelongsToGroup = this.currentUserProfile.hasGroup;
            }
        });

        if (!myThis.userBelongsToGroup) {
            return;
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.auth.logout();
            window.location.href = '/#/login';
        } else {
            this.userProfileService.changeProfile(JSON.parse(this.activatedRoute.snapshot.data['userProfile']));
            let userName = this.cognitoUtil.getCurrentUser().getUsername();
            this.currentUserProfile = JSON.parse(localStorage.getItem(userName));
            this.doGetSubscriptionPlanDetails();
        }
    }

    doGetSubscriptionPlanDetails(): any {
        if (this.currentUserProfile.enterprisePlanUser === true) {
            return;
        }
        let myThis = this;
        this.subscriptionPlanService
            .doGetSubscriptionPlanDetails()
            .then(function (result: any) {
                if (result.success) {
                    const data = result.data;
                    myThis.collaborationPlatformName = data.collaborationPlatformName;
                    myThis.subscriptionPlanResponse$ = of(data.subscriptionPlans);
                    myThis.stripePlanId = data.subscriptionPlans.length === 1 ? data.subscriptionPlans[0].planId : null;
                } else {
                    if (result.errors) {
                        let _errorMessage = '';
                        result.errors.forEach(function (e) {
                            _errorMessage += e.message;
                        });
                        myThis.errorMessage = _errorMessage;
                    }
                }
            });
    }

    doAddPaymentMethodOption() {
        if (this.stripePlanId == null || this.stripePlanId.length === 0) {
            this.errorMessage = 'Please choose between the Monthly or Annual subscription plan and then select Proceed to Checkout.';
            return;
        }
        const myThis = this;
        this
            .stripeCheckoutService
            .getStripeSessionId(this.stripePlanId, "PS_SUBSCRIPTION", null)
            .then(function (result: any) {
                const checkoutSessionId = result.data;
                myThis
                    .stripeCheckoutService
                    .doAddPaymentMethodOption(checkoutSessionId)
                    .then(function (result) {
                        if (result.error && result.error.message) {
                            myThis.errorMessage = result.error.message;
                        }
                    });
            })
            .catch(function (rejectedResult) {
                if (rejectedResult.errors) {
                    let _errorMessage = '';
                    rejectedResult.errors.forEach(function (e) {
                        _errorMessage += e.message;
                    });
                    myThis.errorMessage = _errorMessage;
                }
            });
    }

    closeAndResetErrorMessage() {
        this.errorMessage = null;
    }
}
