import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PasswordValidator} from '../../register/PasswordValidator';
import {Observable} from "rxjs";

declare var $: any;

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './signup-as-invited-admin.component.html',
  styleUrls: ['./signup-as-invited-admin.component.scss']
})
export class SignupAsInvitedAdminComponent implements OnInit {

  public invitedEmail: string;
  public collaborationPlatformName: string;
  public errorMessage: string;
  public name: string;
  public password: string;
  public cpassword: string;
  public token: string;
  public isInvited: boolean;
  public isInvitedByAnotherAdmin: boolean;
  public invalidPassword: boolean;
  private sub: any;
  private activatedRoute: ActivatedRoute;

  constructor(activatedRoute: ActivatedRoute, private http: HttpClient) {
    this.activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      if (params['invited'] === undefined || params['invited'] === null) {
        this.isInvited = false;
      } else {
        this.isInvited = params['invited'] === 'true';
      }
      if (params['invitedByAnotherAdmin'] === undefined || params['invitedByAnotherAdmin'] === null) {
        this.isInvitedByAnotherAdmin = false;
      } else {
        this.isInvitedByAnotherAdmin = params['invitedByAnotherAdmin'] === 'true';
      }
    });
    this.doVerifyAndFetchUserDetailsFromSlack();
    $('#signupAsInvitedAdminSuccessDialog').on('hidden.bs.modal', function () {
      window.location.href = '/#/login';
    });
  }

  private doVerifyAndFetchUserDetailsFromSlack(): any {
    const myThis = this;

    const tokenVerifyUrl = `${environment.apiRoot}/user/get-signup-user-details`;
    myThis.http.post(tokenVerifyUrl, {
      token: this.token,
      isForInvitedAdmin: this.isInvited,
      isInvitedByAnotherAdmin: this.isInvitedByAnotherAdmin || false
    })
      .subscribe(
        (response: any) => {
          myThis.errorMessage = '';
          response.errors = response.errors || null;
          response.errorMessage = response.errorMessage || null;
          if (response.success === true && response.errors === null) {
            const data = response.data;
            myThis.name = data.name;
            myThis.invitedEmail = data.email;
            myThis.collaborationPlatformName = data.collaborationPlatformName;
          } else {
            response.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
          }
        },
        (error) => {
          this.errorMessage = error.error || error.errorMessage || 'Something weird happened. Please try again.';
        }
      );
  }

  public doValidatePassword(event: any) {
    this.invalidPassword = PasswordValidator.isValid(event.target.value);
  }

  public doSignupAsInvitedAdmin(): any {
    const myThis = this;
    if (!PasswordValidator.isValid(this.password) || !PasswordValidator.isValid(this.cpassword)) {
      this.invalidPassword = true;
      this.errorMessage = 'Password must contains at least one small letter, one capital letter, one special character, one number and at least 8 characters long';
      return;
    }
    myThis.doSignupAsInvitedAdminHelper()
      .subscribe(
        (data: any) => {
          data.errors = data.errors || null;
          myThis.errorMessage = '';
          if (data.success === true && data.errors === null) {
            const dialog = $('#signupAsInvitedAdminSuccessDialog .modal-body p.text-center');
            dialog
              .attr('success-code', '200')
              .html(
                'Great job! Just one last step before getting started. Please check your inbox and confirm your email address.'
              );
            $('#signupAsInvitedAdminSuccessDialog').modal({
              backdrop: 'static', // remove ability to close modal with click
              keyboard: true,
              show: true // Display loader!
            });
          } else {
            data.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
          }
        },
        (error) => {
          error.errors?.error.errors.forEach(function (e, index) {
            myThis.errorMessage += e.message;
            if (e[index + 1]) {
              myThis.errorMessage += '<br />';
            }
          });
          myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
        }
      );
  }

  doSignupAsInvitedAdminHelper() : Observable<any> {
    const myThis = this;

    if (!myThis.isInvitedByAnotherAdmin) {
      return myThis.http.post(`${environment.apiRoot}/user/signup-as-invited-admin`, {
        token: this.token,
        invitedEmail: this.invitedEmail,
        name: this.name,
        password: this.password,
        confirmPassword: this.cpassword,
        collaborationPlatformName: myThis.collaborationPlatformName
      });
    } else {
      return myThis.http.post(`${environment.apiRoot}/admins/invited-admin-signup`, {
        token: this.token,
        password: this.password,
        confirmPassword: this.cpassword
      });
    }
  }
}
