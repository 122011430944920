<div class="wrapper">
  <form class="form-signin">
    <h2 class="form-signin-heading d-flex justify-content-center">Forgot Password?</h2>
    <hr>
    <div class="form-group" *ngIf="errorMessage">
      <div class="alert alert-danger alert-dismissable">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        <strong>{{ errorMessage }}</strong>
      </div>
    </div>
    <div class="form-group" *ngIf="successMessage">
      <div class="alert alert-success alert-dismissable">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        <strong>{{ successMessage }}</strong>
      </div>
    </div>
    <div class="form-group">
      <label for="email" style="font-family: roboto,sans-serif; font-weight: 300;">Enter the email address associated with your Please Share account and we will send you an email that will allow you to reset your password.</label>
      <input type="email" class="form-control" [(ngModel)]="forgotEmail" name="email" id="email">
    </div>
    <button class="btn btn-post val-button" (click)="onForgotPassword()" type="submit">SEND VERIFICATION EMAIL</button>
  </form>
</div>


<div class="login-help d-flex justify-content-center">
  <p style="margin-top: 10px">
    <small><a routerLink="/login">Back to sign in page</a></small>
  </p>
</div>
