export class User {
  static default = new User(false);

  constructor(public signedIn: boolean, public username?: string, public userId?: string) {
  }
}

export enum ContainerEvents {
  TwitterUpload,
  TwitterCancel,
  TwitterDelete,
  LinkedInUpload,
  LinkedInCancel,
  LinkedInDelete
}

export enum SocialNetworkType {
  TW,
  LI
}

export enum FileObjectStatus {
  NotStarted,
  Uploading,
  Uploaded,
  Canceled,
  Deleted,
  Failed
}

export class FileObject {
  status = FileObjectStatus.NotStarted;
  uploadKeyPrefix = '';
  isDefaultImage: boolean = false;
  static TWITTER_MAX_IMAGE_ALLOWED_SIZE_IN_BYTES = 5242880; // 5MB
  static TWITTER_MAX_GIF_ALLOWED_SIZE_IN_BYTES = 15728640; // 15MB
  static TWITTER_MAX_VIDEO_ALLOWED_SIZE_IN_BYTES = 524288000; // 500 MB
  static TWITTER_MAX_ATTACHED_IMAGE_NUMBER = 4;
  static TWITTER_MAX_ATTACHED_GIF_NUMBER = 1;
  static TWITTER_MAX_ATTACHED_VIDEO_NUMBER = 1;


  static LINKEDIN_MAX_IMAGE_ALLOWED_SIZE_IN_BYTES = 5242880; // 5MB
  static LINKEDIN_MAX_GIF_ALLOWED_SIZE_IN_BYTES = 5242880; // 15MB
  static LINKEDIN_MIN_VIDEO_ALLOWED_SIZE_IN_BYTES = 76800; // 75 KB
  static LINKEDIN_MAX_VIDEO_ALLOWED_SIZE_IN_BYTES = 209715200; // 200 MB max from pleaseshare app only
  static LINKEDIN_MAX_ATTACHED_IMAGE_NUMBER = 9;
  static LINKEDIN_MAX_ATTACHED_GIF_NUMBER = 1;
  static LINKEDIN_MAX_ATTACHED_VIDEO_NUMBER = 1;

  constructor(public file: File, public networkType: SocialNetworkType, public objectUrl: string | ArrayBuffer, public isS3Object:boolean) {
  }
}

export interface S3ConfigParams {
  bucketName: string;
  folderPath?: string;
}
