import {Component, OnInit} from '@angular/core';
import {UserProfileService} from "../service/user-profile.service";
import {CognitoUtil, LoggedInCallback} from "../service/cognito.service";
import {NavbarService} from "../service/navbar.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../service/user-login.service";
import {SettingsService} from "../service/settings.service";
import {UserProfile} from "../users/UserProfile";

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit, LoggedInCallback {

  constructor(public nav: NavbarService,
              private http: HttpClient,
              private router: Router,
              public cognitoUtil: CognitoUtil,
              private activatedRoute: ActivatedRoute,
              private userLoginService: UserLoginService,
              private settingsService: SettingsService,
              private userProfileService: UserProfileService) {

    let currentUser = this.cognitoUtil.getCurrentUser();
    if (!currentUser) return;
    let userName = currentUser.getUsername();

    let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
    let groupName = profile.groupName;
    let myThis = this;

    this.userProfileService.doGetUserProfileByGroupName(groupName).then(function (_profile: UserProfile) {
      localStorage.removeItem(userName);
      profile.paidUser = _profile.paidUser;
      profile.nextPaymentDate = _profile.nextPaymentDate;
      profile.currentPlanName = _profile.currentPlanName;
      profile.currentPlanPricing = _profile.currentPlanPricing;
      profile.currentPlanCurrency = _profile.currentPlanCurrency;
      profile.paymentMethodEditable = _profile.paymentMethodEditable;
      profile.subscriptionCancelable = _profile.subscriptionCancelable;
      profile.nextPaymentDate = _profile.nextPaymentDate;
      localStorage.setItem(userName, JSON.stringify(profile));
      myThis.userProfileService.changeProfile(profile);
    })
  }

  ngOnInit(): void {
    //If navbar component is hidden, show it
    setTimeout(() => {
      this.nav.hide();
    }, 0);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.userLoginService.logout();
      window.location.href = '/#/login';
    }
  }
}
