import {FileObject} from "../home/file-upload";
import {Injectable} from "@angular/core";
import {MediaAssetsValidationService} from "./media-assets-validation-service"
import LinkedInMediaAssetsValidationError from "./error/LinkedInMediaAssetsValidationError";

@Injectable()
export class LinkedInMediaAssetsValidationService extends MediaAssetsValidationService {
  validate(currentUploadedFiles: FileObject[], files: FileList, optionalImageUrl?: string) {
    if (!this.isValidImageUrl(optionalImageUrl)) {
      throw new LinkedInMediaAssetsValidationError("Please make sure you provided a valid image url on Linkedin Image url field.");
    }
    if (!optionalImageUrl && !files) {
      throw new LinkedInMediaAssetsValidationError("Please make sure that attached file is not empty");
    }
    const fileLength = (files ? files.length : 0) + (optionalImageUrl ? 1 : 0) + (currentUploadedFiles ? currentUploadedFiles.length : 0);
    if (fileLength > FileObject.LINKEDIN_MAX_ATTACHED_IMAGE_NUMBER) {
      throw new LinkedInMediaAssetsValidationError("A maximum of nine images may be attached to a LinkedIn post.");
    }
    // This might be an optional image URI
    if (!files) return;

    let numberOfImages = 0;
    let numberOfGifImages = 0;
    let numberOfVideos = 0;

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file.type === "image/gif" && (file.size < 0 || file.size > FileObject.LINKEDIN_MAX_GIF_ALLOWED_SIZE_IN_BYTES)) {
        throw new LinkedInMediaAssetsValidationError("LinkedIn animated GIF size must be <= " + (FileObject.LINKEDIN_MAX_GIF_ALLOWED_SIZE_IN_BYTES / 1024 / 1024) + " MB");
      }
      if (file.type !== "image/gif" && file.type.indexOf("image") !== -1 && (file.size < 0 || file.size > FileObject.LINKEDIN_MAX_IMAGE_ALLOWED_SIZE_IN_BYTES)) {
        throw new LinkedInMediaAssetsValidationError("LinkedIn supported image size must be <= " + (FileObject.LINKEDIN_MAX_IMAGE_ALLOWED_SIZE_IN_BYTES / 1024 / 1024) + " MB");
      }
      if (file.type.indexOf("video") !== -1 && (file.size < FileObject.LINKEDIN_MIN_VIDEO_ALLOWED_SIZE_IN_BYTES || file.size > FileObject.LINKEDIN_MAX_VIDEO_ALLOWED_SIZE_IN_BYTES)) {
        throw new LinkedInMediaAssetsValidationError("LinkedIn supported video size must be >= " + (FileObject.LINKEDIN_MIN_VIDEO_ALLOWED_SIZE_IN_BYTES / 1024) + " KB" + " and <= " + (FileObject.LINKEDIN_MAX_VIDEO_ALLOWED_SIZE_IN_BYTES / 1024 / 1024) + " MB");
      }
      if (file.type === "image/gif") {
        numberOfGifImages++;
      } else if (file.type !== "image/gif" && file.type.indexOf("image") !== -1) {
        numberOfImages++;
      } else if (file.type.indexOf("video") !== -1) {
        numberOfVideos++;
      }
    }
    if (currentUploadedFiles && currentUploadedFiles.length > 0) {
      currentUploadedFiles.forEach(_fileObj => {
        let file: File = _fileObj.file;
        if (file && file.type) {
          if (file.type === "image/gif") {
            numberOfGifImages++;
          } else if (file.type !== "image/gif" && file.type.indexOf("image") !== -1) {
            numberOfImages++;
          } else if (file.type.indexOf("video") !== -1) {
            numberOfVideos++;
          }
        }
      })
    }
    if (numberOfImages > FileObject.LINKEDIN_MAX_ATTACHED_IMAGE_NUMBER) {
      throw new LinkedInMediaAssetsValidationError("A maximum of nine images may be attached to a LinkedIn post.");
    }

    if (numberOfGifImages > FileObject.LINKEDIN_MAX_ATTACHED_GIF_NUMBER) {
      throw new LinkedInMediaAssetsValidationError("Only one animated GIF may be attached to a LinkedIn post.")
    }
    if (numberOfVideos > FileObject.LINKEDIN_MAX_ATTACHED_VIDEO_NUMBER) {
      throw new LinkedInMediaAssetsValidationError("Only one video may be attached to a LinkedIn Post.")
    }
    if (numberOfImages === 0 && numberOfGifImages > 0 && numberOfVideos > 0) {
      throw new LinkedInMediaAssetsValidationError(
        "You may attach up to " + FileObject.LINKEDIN_MAX_ATTACHED_IMAGE_NUMBER + " photos, " +
        FileObject.LINKEDIN_MAX_ATTACHED_GIF_NUMBER + " animated GIF or " + FileObject.LINKEDIN_MAX_ATTACHED_VIDEO_NUMBER + " video in a LinkedIn post.");
    }
    if (numberOfImages > 0 && (numberOfGifImages > 0 || numberOfVideos > 0)) {
      throw new LinkedInMediaAssetsValidationError(
        "You may attach up to " + FileObject.LINKEDIN_MAX_ATTACHED_IMAGE_NUMBER + " photos, " +
        FileObject.LINKEDIN_MAX_ATTACHED_GIF_NUMBER + " animated GIF or " + FileObject.LINKEDIN_MAX_ATTACHED_VIDEO_NUMBER + " video in a LinkedIn post.");
    }
  }
}
