declare var AWS: any;

export class S3Factory {
    private static s3Clients = {};
    static getS3(regionName: string) {
      if (!S3Factory.s3Clients[regionName]) {
        S3Factory.s3Clients[regionName] = new AWS.S3({ region: regionName });
      }
      return S3Factory.s3Clients[regionName];
    }
  }
