import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserProfile} from '../users/UserProfile';
import {CognitoCallback, CognitoUtil} from './cognito.service';
import * as snakeToCamelCase from 'to-camel-case';
import {PasswordResetCallback} from '../settings/account-settings/account-settings.component';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class UserProfileService {

  private profileSource = new BehaviorSubject(new UserProfile('', false, '', '', '', '', '', '', ''));
  public loggedInUserProfile = this.profileSource.asObservable();

  constructor(private cognitoUtil: CognitoUtil, private http: HttpClient) {
  }

  changeProfile(profile: UserProfile) {
    this.profileSource.next(profile);
    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser) {
      const cognitoUserName = cognitoUser.getUsername();
      localStorage.removeItem(cognitoUserName)
      localStorage.setItem(cognitoUserName, JSON.stringify(profile))
    }
  }

  isObjectEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  updateLoggedInAttributes(attributeList, successMessage: string, callback: CognitoCallback) {
    const cognitoUser = this.cognitoUtil.getCurrentUser();
    const myThis = this;
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          callback.cognitoCallback('Unable to update your profile.', null);
        } else {
          cognitoUser.updateAttributes(attributeList, function (error, result) {
            // For email change case we, logout is occurred within 4 seconds, and hence localstorage is empty
            if (localStorage.getItem(cognitoUser.getUsername()) === null) {
              callback.cognitoCallback(null, successMessage);
              return;
            }
            if (error !== null && error !== undefined && !myThis.isObjectEmpty(error)) {
              callback.cognitoCallback('Unable to update your profile.', null);
            } else {
              // Success
              const profile: UserProfile = JSON.parse(localStorage.getItem(cognitoUser.getUsername()));
              attributeList.map(function (attr) {
                profile[snakeToCamelCase(attr.Name)] = attr.Value;
              });

              localStorage.removeItem(cognitoUser.getUsername());
              localStorage.setItem(cognitoUser.getUsername(), JSON.stringify(profile));
              myThis.changeProfile(profile);
              callback.cognitoCallback(null, successMessage);
            }
          });
        }

      });
    } else {
      callback.cognitoCallback('Unable to update your profile.', null);
    }
  }

  doGetUserProfileByGroupName(groupName: string) {

    const myThis = this;

    const url = `${environment.apiRoot}/user?groupName=` + groupName;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json');
    return new Promise((resolve, reject) => {
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (response: any) => {
            const profile: UserProfile = response.data;
            resolve(profile);
          },
          (error) => {
            console.error('Error loading profile updates', JSON.stringify(error));
            reject(error);
          }
        );
    });
  }

  changePassword(oldPassword: string, newPassword: string, passwordResetCallback: PasswordResetCallback) {
    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          passwordResetCallback.passwordResetCallback(
            'Unable to change your Please Share account password.', err.message || JSON.stringify(err)
          );
        } else {
          cognitoUser.changePassword(oldPassword, newPassword, function (error, result) {
            if (error) {
              passwordResetCallback.passwordResetCallback(
                'Unable to change your Please Share account password.', error.message || JSON.stringify(error)
              );
              return;
            }
            passwordResetCallback.passwordResetCallback(null, 'Your Please Share account password has been reset.');
          });
        }
      });
    }

  }
}
