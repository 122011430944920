import {Component, OnInit} from '@angular/core';
import {CognitoUtil, LoggedInCallback} from "../service/cognito.service";
import {UserLoginService} from "../service/user-login.service";

@Component({
  selector: 'app-federated-auth-callback',
  templateUrl: './federated-auth-callback.component.html',
  styleUrls: ['./federated-auth-callback.component.scss']
})
export class FederatedAuthCallbackComponent implements OnInit, LoggedInCallback {

  constructor(
    private auth: UserLoginService,
    private cognitoUtil: CognitoUtil,
  ) {}

  ngOnInit(): void {
    this.cognitoUtil.oktaProcessSignInCallbackUrl(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      window.location.href = '/';
    } else {
      this.auth.logout();
    }
  }

}
