<!-- Bread crumb and right sidebar toggle -->
<div class="row page-titles">
  <div class="col-md-8 align-self-center">
    <h2 class="text-themecolor m-b-0 m-t-0" style="padding-bottom:10px">Settings</h2>
    <ul class="list-inline">
      <li class="list-inline-item"><a routerLink="/settings">Account</a></li>
      <li class="list-inline-item"><a routerLink="/users">Users</a></li>
      <li class="list-inline-item"><a routerLink="/add-billing">Billing</a></li>
      <li class="list-inline-item"><a routerLink="/integrations">Integrations</a></li>
    </ul>
    <!-- Nav tabs -->
  </div>
</div>
<!-- End Bread crumb and right sidebar toggle -->
<!-- Start Page Content -->
<!-- Column -->
<div class="row">
  <div class="col-sm-12">
    <div class="card" style="min-height: 800px">
      <div class="card-body">
        <h4 class="card-title">Integration</h4>
        <h6 class="card-subtitle">Instructions for How to Integration with Slack.</h6>
        <hr>


        <!-- End Form -->
      </div>
    </div>

  </div>
</div>
