import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserSetup} from '../user-setup/user-setup';

@Injectable()
export class UserSetupService {

  constructor(private http: HttpClient) {
  }

  linkUserWithCognitoGroup(user_name: string, accessToken: string, accountJwtToken: string) {
    const myThis = this;
    const url = `${environment.apiRoot}/user/setup`;
    const userSetup = new UserSetup();
    userSetup.accountJwtToken = accountJwtToken;

    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json')
        .set('PSAccessToken', accessToken);
      myThis.http.post(url, userSetup, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
             console.log('Error occurred: ', error);
          }
        );
    });
  }
}
