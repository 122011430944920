import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PasswordValidator} from '../../register/PasswordValidator';
import {Observable, of} from "rxjs";

declare var $: any;

@Component({
  templateUrl: './sign-up-as-admin.component.html',
  styleUrls: ['./sign-up-as-admin.component.scss']
})
export class SignUpAsAdminComponent implements OnInit {
  public signupAsAdmin: boolean;
  public assignPSAdmin: boolean;
  public adminEmailForPS: string;
  public email: string;
  public name: string;
  public password: string;
  public cpassword: string;
  public invalidPassword: boolean;
  public token: string;
  public errorMessage: string;
  public collaborationPlatformName$: Observable<string>;
  public collaborationPlatformWorkspaceStr$: Observable<string>;
  private sub: any;
  private activatedRoute: ActivatedRoute;


  constructor(activatedRoute: ActivatedRoute, private http: HttpClient) {
    this.activatedRoute = activatedRoute;

  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    $('#signupAsSlackAdminSuccessDialog').on('hidden.bs.modal', function () {
      window.location.href = '/#/login';
    });

    this.getCustomerDetailsByToken(this.token);
  }

  private getCustomerDetailsByToken(token: string): any {
    const myThis = this;
    const signupUserDetailsUrl = `${environment.apiRoot}/user/get-signup-user-details`;
    myThis.http.post(signupUserDetailsUrl, {token: token})
      .subscribe(
        (response: any) => {
          response.errors = response.errors || null;
          myThis.errorMessage = '';
          if (response.success === true && response.errors === null) {
            const data = response.data;
            myThis.email = data.email;
            myThis.name = data.name;
            if (data.collaborationPlatformName === "Slack") {
              myThis.collaborationPlatformName$ = of("Slack");
              myThis.collaborationPlatformWorkspaceStr$ = of("workspace");
            } else if (data.collaborationPlatformName === "Teams") {
              myThis.collaborationPlatformName$ = of("Teams");
              myThis.collaborationPlatformWorkspaceStr$ = of("account");
            }
          } else {
            response.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
          }
        },
        (error) => {
          myThis.errorMessage = error.errorMessage || error.error || 'Something weird happened, please try again.';
        }
      );
  }

  public doAssignAnAdminForPleaseShare(): any {
    const myThis = this;
    const signupAsAdminUrl = `${environment.apiRoot}/user/signup-invite-an-admin`;
    myThis.http.post(signupAsAdminUrl, {token: this.token, invitedEmail: this.adminEmailForPS})
      .subscribe(
        (data: any) => {
          myThis.errorMessage = '';
          data.errors = data.errors || null;
          if (data.success === true && data.errors === null) {
            const dialogId = $('#signupAsSlackAdminSuccessDialog .modal-body p.text-center');
            dialogId.html(
              'Thank you for assigning an administrator for Please Share.' +
              ' We have sent an email with a link to the email address you provided.'
            );
            dialogId.attr('success-code', '200');
            $('#signupAsSlackAdminSuccessDialog').modal({
              backdrop: 'static', // remove ability to close modal with click
              keyboard: true,
              show: true // Display loader!
            });
          } else {
            data.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
          }
        },
        (error) => {
          error.errors?.error.errors.forEach(function (e, index) {
            myThis.errorMessage += e.message;
            if (e[index + 1]) {
              myThis.errorMessage += '<br />';
            }
          });
          myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
        }
      );
  }

  public doValidatePassword(event: any) {
    if (!PasswordValidator.isValid(event.target.value)) {
      $('#password-tool-tip')
        .removeClass('d-none')
        .popover({
          html: true,
          animation: true,
          placement: 'right',
          container: 'body',
          selector: '#password',
          offset: 100
        });
    }
  }

  public doSignupAsAdmin(): any {
    const myThis = this;
    const signupAsAdminUrl = `${environment.apiRoot}/user/signup-as-admin`;
    if (!PasswordValidator.isValid(this.password) || !PasswordValidator.isValid(this.cpassword)) {
      this.invalidPassword = true;
      this.errorMessage = 'Password must contains at least one small letter, one capital letter, one special character, one number and at least 8 characters long';
      return;
    }
    myThis.http.post(signupAsAdminUrl, {token: this.token, password: this.password, confirmPassword: this.cpassword})
      .subscribe(
        (data: any) => {
          data.errors = data.errors || null;
          myThis.errorMessage = '';
          if (data.success === true && data.errors === null) {
            $('#signupAsSlackAdminSuccessDialog .modal-body p.text-center').attr('success-code', '200');
            $('#signupAsSlackAdminSuccessDialog').modal({
              backdrop: 'static', // remove ability to close modal with click
              keyboard: true,
              show: true // Display loader!
            });
          } else {
            data.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
          }
        },
        (error) => {
          error.errors?.error.errors.forEach(function (e, index) {
            myThis.errorMessage += e.message;
            if (e[index + 1]) {
              myThis.errorMessage += '<br />';
            }
          });
          myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
        }
      );

  }

  public reload(): any {
    window.location.reload();
  }

  public showSignupAsAdminSection(): any {
    this.signupAsAdmin = true;
    this.assignPSAdmin = false;
  }

  public showAssignAnAdminForPleaseShareSection(): any {
    this.assignPSAdmin = true;
    this.signupAsAdmin = false;
  }
}
