import {Component, OnInit} from '@angular/core';
import {NavbarService} from "../service/navbar.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: '[id="user-setup-wrapper"][style="width:100%"]',
  templateUrl: './user-setup-error.component.html',
  styleUrls: ['./user-setup-error.component.scss']
})
export class UserSetupErrorComponent implements OnInit {

  public messageType: string;

  constructor(public nav: NavbarService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.nav.hide();
    }, 0);

    this.activatedRoute.queryParams.subscribe(params => {
      this.messageType = params['msgType'];
    });
  }

  public isInstallationMessageType(messageType: string) {
    return (
      (messageType === 'private_channel_install_issue') ||
      (messageType === 'invalid_slack_verification') ||
      (messageType === 'unknown_install_exception') ||
      (messageType === 'install_persistence_exception') ||
      (messageType === 'unknown_slack_userinfo_install')
    );
  }

  public isNonInstallationMessageType(messageType: string) {
    return (
      (messageType === 'li_user_authenticated_issue') ||
      (messageType === 'li_feed_engagement_authorize_issue') ||
      (messageType === 'tw_user_authenticated_issue') ||
      (messageType === 'tw_feed_engagement_authorize_issue')
    );
  }

  public isUnknownMessageType(messageType: string) {
    return (
      !this.isInstallationMessageType(messageType) &&
      !this.isNonInstallationMessageType(messageType)
    );
  }
}
