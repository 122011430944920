<ng-http-loader
  [backgroundColor]="'#ff0000'"
  [spinner]="spinkit.skSpinnerPulse"
  [debounceDelay]="200"
  [filteredUrlPatterns]="[
    'previewurl',
    'c-platform/list-channels',
    '/reporting/*'
  ]"
>
</ng-http-loader>
<div *ngIf="isAuthenticated || isUserSetupPage">
  <app-header></app-header>
  <div id="wrapper">
    <ul left-sidebar-component *ngIf="nav.visible" class="sidebar navbar-nav pt-25" style="padding-top: 25px;">
    </ul>
    <div style="width: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div *ngIf="(!isAuthenticated && !isUserSetupPage && !isBetaPeriodRunning)">
  <app-header></app-header>
  <router-outlet></router-outlet>
</div>

<div *ngIf="isBetaPeriodRunning">
  <router-outlet></router-outlet>
</div>
