import {Setting} from '../settings/Setting';

export class UserProfile {
  customRole: string;
  hasGroup: boolean;
  groupName: string;
  email: string;
  // Do not edit this variable name
  customCompanyname: string;
  // Do not edit this variable name
  customWorkspacename: string;
  accountId: string;
  givenName: string;
  familyName: string;
  customAddress: string;
  workspaceName: string;
  channelToBePosted: string;
  channelIdToBePosted: string;
  settings: Setting[];
  accountStatus: string;
  activeWorkspaceUsersCount: number;
  activeWorkspaceUsersCountCheckDate: string;
  trailPeriodLeftInDays: number;
  paidUser: boolean;
  enterprisePlanUser: boolean;
  currentPlanName: string;
  currentPlanPricing: string;
  currentPlanCurrency: string;
  nextPaymentDate: string;
  trialEndDate: string;
  subscriptionCancelable: boolean;
  paymentMethodEditable: boolean;
  collaborationPlatformName: string;
  enabledFeatures: string[];
  psbotInstalledInPrivateChannel: boolean;
  displayFeedEngagementPostHeaderSection: boolean;
  currencyConvertedValue: number;
  autoDeleteShareRequestsAfterDay: number;
  adminAuthType: string;
  billableUserCount: number;
  tzName: string;

  constructor(customRole?: string,
              hasGroup?: boolean,
              groupName?: string,
              email?: string,
              companyName?: string,
              workspaceName?: string,
              accountId?: string,
              givenName?: string,
              familyName?: string,
              customAddress?: string,
              settings?: Setting[],
              channelToBePosted?: string,
              channelIdToBePosted?: string,
              accountStatus?: string,
              activeWorkspaceUsersCount?: number,
              activeWorkspaceUsersCountCheckDate?: string,
              trailPeriodLeftInDays?: number,
              paidUser?: boolean,
              enterpriseUser?: boolean,
              currentPlanName?: string,
              currentPlanPricing?: string,
              currentPlanCurrency?: string,
              nextPaymentDate?: string,
              trialEndDate?: string,
              subscriptionCancelable?: boolean,
              paymentMethodEditable?: boolean,
              collaborationPlatformName?: string,
              enabledFeatures?: string[],
              psbotInstalledInPrivateChannel?: boolean,
              displayFeedEngagementPostHeaderSection?: boolean,
              currencyConvertedValue?: number,
              autoDeleteShareRequestsAfterDay?: number,
              adminAuthType?: string,
              billableUserCount?: number,
              tzName?: string) {
    this.customRole = customRole;
    this.hasGroup = hasGroup;
    this.groupName = groupName;
    this.email = email;
    this.customCompanyname = companyName;
    this.customWorkspacename = this.workspaceName = workspaceName;
    this.accountId = accountId;
    this.givenName = givenName;
    this.familyName = familyName;
    this.customAddress = customAddress;
    this.settings = settings;
    this.channelToBePosted = channelToBePosted;
    this.channelIdToBePosted = channelIdToBePosted;
    this.accountStatus = accountStatus;
    this.activeWorkspaceUsersCount = activeWorkspaceUsersCount;
    this.activeWorkspaceUsersCountCheckDate = activeWorkspaceUsersCountCheckDate;
    this.trailPeriodLeftInDays = trailPeriodLeftInDays;
    this.paidUser = paidUser;
    this.enterprisePlanUser = enterpriseUser;
    this.currentPlanName = currentPlanName;
    this.currentPlanPricing = currentPlanPricing;
    this.currentPlanCurrency = currentPlanCurrency;
    this.nextPaymentDate = nextPaymentDate;
    this.trialEndDate = trialEndDate;
    this.subscriptionCancelable = subscriptionCancelable;
    this.paymentMethodEditable = paymentMethodEditable;
    this.collaborationPlatformName = collaborationPlatformName;
    this.enabledFeatures = enabledFeatures;
    this.psbotInstalledInPrivateChannel = psbotInstalledInPrivateChannel;
    this.displayFeedEngagementPostHeaderSection = displayFeedEngagementPostHeaderSection;
    this.currencyConvertedValue = currencyConvertedValue;
    this.autoDeleteShareRequestsAfterDay = Number(autoDeleteShareRequestsAfterDay);
    this.adminAuthType = adminAuthType;
    this.billableUserCount = billableUserCount;
    this.tzName = tzName;
  }
}
