import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ShareClick} from '../schedules/ShareClick';
import {ActivatedRoute, Router} from '@angular/router';
import {Setting} from '../settings/Setting';
import {EarnedMedia} from '../settings/EarnedMedia';
import {LoggedInCallback} from '../service/cognito.service';
import {UserLoginService} from '../service/user-login.service';
import {UserProfileService} from '../service/user-profile.service';
import {UserProfile} from '../users/UserProfile';
import {NavbarService} from '../service/navbar.service';
import {ScheduleService} from '../service/schedule.service';
import {environment} from '../../environments/environment';
import {FeedsEngagementStats} from "../schedules/FeedsEngagementStats";
import {StatisticsService} from "../service/statistics.service";
import {AngularCsv} from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StatisticsComponent implements OnInit, LoggedInCallback {
  public shareClick$: Observable<ShareClick[]>;
  public feedsEngagementStats$: Observable<FeedsEngagementStats[]>;
  public monthlyShares$: Observable<number>;
  public updatePostedThisMonth$: Observable<number>;
  public updatePostedThisYear$: Observable<number>;
  public earnedMediaThisYear$: Observable<number>;
  public monthlyclicks$: Observable<number>;
  public earnedMediaClickValue$: Observable<number>;
  public earnedMediaCurrency$: Observable<string>;
  public last1YearMonthsList: Date[];
  public yearsList: number[];
  public selectedMonth: Date;
  private dateFrom: string;
  private dateTo: string;
  public userBelongsToGroup: boolean;
  public userAccountStatus: string;
  public currentUserProfile: UserProfile;
  public slackAppDownloadLink;
  public slackAppNotificationMsg;
  public downloadAsCsvSelectedFromYear;
  p: number = 1;
  loading: boolean;

  constructor(public nav: NavbarService,
              private scheduleService: ScheduleService,
              public router: Router,
              private auth: UserLoginService,
              private activatedRoute: ActivatedRoute,
              private userProfileService: UserProfileService,
              private statisticsService: StatisticsService) {

    this.slackAppNotificationMsg = environment.slackAppNotificationMsg;
    this.auth.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.auth.logout();
      window.location.href = '/#/login';
    } else {
      this.userProfileService.changeProfile(JSON.parse(this.activatedRoute.snapshot.data['userProfile']));
    }
  }

  ngOnInit() {
    //If navbar component is hidden, show it
    setTimeout(() => {
      this.nav.show();
    }, 0);

    this.userProfileService.loggedInUserProfile.subscribe(profile => {
      this.currentUserProfile = profile;
      this.slackAppDownloadLink = environment.slackAppDownloadLink.replace("_state_", profile.email);
      if (!profile.hasGroup) {
        this.userBelongsToGroup = false;
      } else {
        this.userBelongsToGroup = this.currentUserProfile.hasGroup;
      }
      if (this.userBelongsToGroup) {
        this.userAccountStatus = profile.accountStatus;
      }
    });
    this.fetchAndUpdateStatistics();
    this.populateYearsFrom2020ToCurrent();
    this.fetchAndUpdateFeedsEngagementStatistics();
  }

  private fetchAndUpdateStatistics() {
    if (this.userBelongsToGroup && this.userAccountStatus === 'ENABLED') {
      const myThis = this;
      this.last1YearMonthsList = [];
      if (myThis.dateTo === undefined || myThis.dateFrom === undefined) {
        this.adjustDateFromAndDateTo(new Date().toISOString());
      }
      myThis.selectedMonth = new Date(myThis.dateTo) || new Date();

      this.populateLast1YearMonths();
      this.scheduleService.getShareClickCount(myThis.dateFrom, myThis.dateTo).then(function (_shareClick: ShareClick[]) {
        myThis.shareClick$ = of(_shareClick);
        myThis.updatePostedThisMonth$ = of(_shareClick[0].updatePostedThisMonth);
        myThis.updatePostedThisYear$ = of(_shareClick[0].updatePostedThisYear);
        myThis.earnedMediaThisYear$ = of(_shareClick[0].earnedMediaCostOfThisYear);
        let monthlyShares = [];
        monthlyShares.push(_shareClick[0].shareButtonClickedInTW);
        monthlyShares.push(_shareClick[0].shareButtonClickedInFB);
        monthlyShares.push(_shareClick[0].shareButtonClickedInLI);
        myThis.monthlyShares$ = of(monthlyShares.reduce((a, b) => a + b, 0));

        let monthlyClicks = [];
        monthlyClicks.push(_shareClick[0].sharedUrlClickedInTW);
        monthlyClicks.push(_shareClick[0].sharedUrlClickedInFB);
        monthlyClicks.push(_shareClick[0].sharedUrlClickedInLI);

        myThis.monthlyclicks$ = of(monthlyClicks.reduce((a, b) => a + b, 0));

        let earnMedia: EarnedMedia = StatisticsComponent.parseEarnedMediaFromSettings(myThis.currentUserProfile.settings);
        myThis.earnedMediaClickValue$ = of(earnMedia.clickValue);
        myThis.earnedMediaCurrency$ = of(earnMedia.currency);
      });
    }
  }

  public fetchAndUpdateFeedsEngagementStatistics(dateFrom?: string) {
    if (this.userBelongsToGroup && this.userAccountStatus === 'ENABLED') {
      const myThis = this;
      this.last1YearMonthsList = [];
      dateFrom = dateFrom || this.dateFrom || new Date().toISOString();
      this.adjustDateFromAndDateTo(dateFrom);
      myThis.selectedMonth = new Date(myThis.dateTo) || new Date();
      this.populateLast1YearMonths();
      this.scheduleService.getFeedsEngagementStatistics(myThis.dateFrom, myThis.dateTo).then(function (_feedsEngagementStats: FeedsEngagementStats[]) {
        myThis.feedsEngagementStats$ = of(_feedsEngagementStats);
      });
    }
  }

  private static parseEarnedMediaFromSettings(settings: Setting[]) {
    if (!settings) return;
    let earnMedia = new EarnedMedia();
    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name == 'earned.media.factor') {
        earnMedia.clickValue = settings[i].value;
      }
      if (settings[i].name == 'earned.media.currency') {
        earnMedia.currency = settings[i].value;
      }
    }
    return earnMedia;
  }

  loadHistoriesWithCustomDate(_date: string) {
    this.adjustDateFromAndDateTo(_date);
    this.ngOnInit();
  }

  onRefreshStatistics() {
    this.fetchAndUpdateStatistics();
    this.fetchAndUpdateFeedsEngagementStatistics();
  }

  onDownloadStatsAsCSVButtonClick() {
    this.statisticsService.getDownloadableStatistics(this.downloadAsCsvSelectedFromYear).then(function (feedsEngagementDownloadAsCsvStatsResponse: any) {
      let options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Statistics Summary',
        useBom: true,
        noDownload: false,
        useHeader: false,
        nullToEmptyString: true,
      };
      new AngularCsv(feedsEngagementDownloadAsCsvStatsResponse.data, 'please-share-statistics', options);
    });
  }

  private adjustDateFromAndDateTo(_date: string) {
    let date = new Date(_date), y = date.getFullYear(), m = date.getMonth();
    let firstDayOfSelectedMonth = new Date(y, m, 1);
    let lastDayOfSelectedMonth = new Date(y, m + 1, 0, 23, 59, 59, 0);

    this.dateFrom = new Date(firstDayOfSelectedMonth.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().replace(/(.*)\..*/g, '$1');
    this.dateTo = new Date(lastDayOfSelectedMonth.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().replace(/(.*)\..*/g, '$1');
  }

  private populateLast1YearMonths() {
    let date = new Date();
    date.setDate(1);

    for (let i = 1; i <= 12; i++) {
      date.setMonth(date.getMonth() - 1);
      let newDate = new Date(date);
      this.last1YearMonthsList.push(newDate);
    }
  }

  private populateYearsFrom2020ToCurrent() {
    let date = new Date();
    this.yearsList = [];
    let currentYear = date.getFullYear();
    this.downloadAsCsvSelectedFromYear = currentYear;
    for (let y = currentYear; y >= 2020; y--) {
      this.yearsList.push(y);
    }
  }

  changeDownloadAsCsvSelectedFromDateValue(year: number) {
    this.downloadAsCsvSelectedFromYear = year;
  }
}
