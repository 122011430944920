<div class="wrapper">
  <div *ngIf="errorMessage" class="d-flex justify-content-center align-self-center">
    <div [innerHTML]="errorMessage" class="alert-danger alert alert-dismissible d-flex justify-content-center"
         style="width: 100%"></div>
  </div>
  <div class="col">
    <div>
      <div class="row" *ngIf="!assignPSAdmin && !signupAsAdmin">
        <div class="col">
          <div>
            <div class="col">
              <!-- Start Form -->
              <form class="form-signin">
                <h2 class="form-signin-heading d-flex justify-content-center">Please Share Registration</h2>
                <hr>
                <div class="form-group">
                  <label style="font-family: roboto,sans-serif">Thank you for installing the Please Share app in your
                    company's {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}.
                    <br><br>You are moments away from empowering your team members to
                    promote noteworthy news and announcements on Twitter, LinkedIn and Facebook – all from
                    within {{collaborationPlatformName$ | async}}.<br><br>Before
                    going any further, are you the primary person who will be using Please Share (i.e., the admin for
                    Please
                    Share)?</label>
                </div>

                <div class="text-center">
                  <button type="button" class="btn btn-post reg-button" (click)="showSignupAsAdminSection()">Yes
                  </button>
                  <button type="button" class="btn btn-secondary reg-button"
                          (click)="showAssignAnAdminForPleaseShareSection()">No
                  </button>
                </div>

              </form><!-- End Form -->
            </div><!-- End Col -->
            <div class="col"></div>
          </div><!-- End Row -->
        </div><!-- End Wrapper -->


        <div class="container-fluid">
          <h4 style="color: #3B5072; padding-top: 40px; line-height: 35px; text-align: center;">Common Questions About
            This
            Step</h4>
          <div class="row page-titles justify-content-center">
            <!-- Begin Page Title -->

            <div class="col-4">
              <p style="color: #3B5072; padding-top: 20px; line-height: 30px; font-size: 16px;">
                <strong>Why are you (Please Share) asking this question?</strong><br/>
                Depending on a
                company's {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}
                configuration, individual employees may or may not have the ability to add
                apps to their {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}
                .<br/><br/>Provided that Please Share will most likely be administered by a
                marketing, PR or HR team member – not someone from IT – it's common for the primary Please Share user to
                not
                have the ability to add {{collaborationPlatformName$ | async}} apps to a
                company's {{collaborationPlatformWorkspaceStr$ | async}}.<br/><br/>In other words, the person who can
                install the Please Share app and the person who will use this Please Share service are often different
                people.<br/><br/>

                <strong>What happens if I select "Yes"?</strong><br/>
                Selecting "Yes" takes you to a page where <strong><i>you</i></strong> can complete the Please Share
                registration process.</p>
            </div>
            <div class="col-4">
              <p style="color: #3B5072; padding-top: 20px; line-height: 30px; font-size: 16px;">
                <strong>What happens if I select "No"?</strong><br/>
                Selecting "No" takes you to a page where you can invite <i>another member</i> to complete
                the Please Share registration process.<br/><br/>
                <strong>If I invite someone else to be the Please Share admin, how are they verified?</strong><br/>
                We validate that the person you invite to be the Please Share admin is a member of
                your {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}
                through an API call to {{collaborationPlatformName$ | async}}. The invited user's email address must
                match an <i>existing and
                active</i> user in your
                company's {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}
                .<br/><br/>

                <strong>Can I complete the registration now and invite someone else to be a Please Share admin
                  later?
                </strong><br/>
                This functionality is not available at this time, but will be added in the near future.
              </p>
            </div>

          </div>
        </div>
      </div>
      <div class="row" *ngIf="signupAsAdmin">
        <div class="col">
          <!-- Start Form -->
          <form class="form-signin">
            <h2 class="form-signin-heading d-flex justify-content-center">Register for Please Share</h2>
            <hr>
            <div class="form-row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name" style="font-family: roboto,sans-serif">NAME</label>
                  <input type="text" class="form-control" name="name" id="name" value="{{name}}" [disabled]="true">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email-address" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
              <input type="email" name="email" required value="{{email}}" [disabled]="true" class="form-control"
                     id="email-address">
            </div>
            <div class="form-group">
              <label for="password" style="font-family: roboto,sans-serif">PASSWORD</label>
              <input type="password" required [(ngModel)]="password" name="password" class="form-control" id="password"
                     data-toggle="popover" data-trigger="hover" data-placement="bottom"
                     data-content="My popover content."/>
            </div>
            <div class="form-group">
              <label for="cpassword" style="font-family: roboto,sans-serif">CONFIRM PASSWORD</label>
              <input type="password" name="confirmPassword" [(ngModel)]="cpassword"
                     required class="form-control"
                     id="cpassword">
            </div>

            <button class="btn btn-post cta-button" (click)="doSignupAsAdmin()" type="submit">Sign Up</button>
          </form><!-- End Form -->
          <div class="login-help d-flex justify-content-center">
            <p style="margin-top: 10px"><small><a href="javascript:void(0)" (click)="reload()">Go back to the previous
              page</a></small></p>
          </div>
        </div><!-- End Col -->
      </div>
      <div class="row" *ngIf="assignPSAdmin">
        <div class="col">
          <div>
            <div class="col">
              <!-- Start Form -->
              <form #f="ngForm" class="form-signin">
                <h2 class="form-signin-heading d-flex justify-content-center">Assign an Administrator</h2>
                <hr>
                <div class="form-group">
                  <label for="email" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
                  <input type="email" [(ngModel)]="adminEmailForPS" [email]="true" required name="adminEmailForPS"
                         class="form-control" id="email">
                </div>
                <button [disabled]="!f.valid" class="btn btn-post cta-button mt-2"
                        (click)="doAssignAnAdminForPleaseShare()"
                        type="submit">Send
                </button>
              </form><!-- End Form -->
              <div class="login-help d-flex justify-content-center">
                <p style="margin-top: 10px"><small><a href="javascript:void(0)" (click)="reload()">Go back to the
                  previous
                  page</a></small></p>
              </div>
            </div><!-- End Col -->
            <div class="col"></div>
          </div><!-- End Row -->
        </div><!-- End Wrapper -->


        <div class="container-fluid">
          <h4 style="color: #3B5072; padding-top: 40px; line-height: 35px; text-align: center;">Additional Details</h4>
          <div class="row page-titles justify-content-center">
            <!-- Begin Page Title -->

            <div class="col-4">
              <p style="color: #3B5072; padding-top: 20px; line-height: 30px; font-size: 16px;">
                <strong>About This Step</strong><br/>
                If you are <strong><i>not</i></strong> planning to be the primary end user of the Please Share
                application,
                you can designate another colleague to be the Please Share administrator.<br/><br/>Just add his or her
                email
                address, select "Send" and we will take it from there.<br/><br/>Note: Invited
                users are verified with an API call that they are an active member of your
                company's {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}.</p>


            </div>
            <div class="col-4">
              <p style="color: #3B5072; padding-top: 20px; line-height: 30px; font-size: 16px;">
                <strong>Email Copy</strong><br/>
                Here's the copy included in the email invitation...<br/><br/><i>Hi there,<br/><br/>Your colleague,
                %%YOUR_NAME%%, who recently installed the Please Share app in your
                company's {{collaborationPlatformName$ | async}} {{collaborationPlatformWorkspaceStr$ | async}}, has
                invited
                you to register as the administrator for Please Share. Click the link below to complete the registration
                process.<br/><br/>%%UNIQUE_URL/BUTTON%%<br/><br/>The Please Share Team</i></p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="signupAsSlackAdminSuccessDialog" tabindex="-1" role="dialog"
         aria-labelledby="signupAsSlackAdminSuccessDialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center">
              Great job! Just one last step before getting started. Please check your inbox and confirm your email
              address.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary cancel-subscription-plan-btn-no" data-dismiss="modal">OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
