import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-invite-user-success',
  templateUrl: './invite-user-success.component.html',
  styleUrls: ['./invite-user-success.component.scss']
})
export class InviteUserSuccessComponent implements OnInit {

  private sub: any;
  public email: string;
  constructor(public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['email'];

    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
