import {Component, OnInit} from '@angular/core';
import {NavbarService} from "../service/navbar.service";

@Component({
  selector: '[id="linkedin-authorization-success-wrapper"][style="width:100%"]',
  templateUrl: './beta/authentication/linkedin-success.component.html',
  styleUrls: ['./linkedin-authorization-confirmation.component.scss']
})
export class LinkedinAuthorizationConfirmationComponent implements OnInit {
  public currentYear: number;

  constructor(public nav: NavbarService) {

  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    setTimeout(() => {
      this.nav.hide();
    }, 0);
  }

}
