import * as moment from 'moment-timezone';

import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export class DateUtil {

  public static epochMilli2LocalMoment(epochMilli: number) {
    return epochMilli == null ? null : moment(epochMilli).utc().local();
  }

  public static epochMilli2LocalFromNow(epochMilli: number) {
    return epochMilli == null ? '' : moment(epochMilli).utc().local().fromNow();
  }

  public static moment2NgbDate(m: moment.Moment): NgbDateStruct {
    const mo: moment.MomentObjectOutput = m.toObject();
    return {
      year: mo.years,
      month: mo.months + 1,
      day: mo.date
    };
  }

  public static moment2NgbTime(m: moment.Moment): NgbTimeStruct {
    const mo: moment.MomentObjectOutput = m.toObject();
    return {
      hour: mo.hours,
      minute: mo.minutes,
      second: mo.seconds
    };
  }

  public static ngbDate2moment(d: NgbDateStruct): moment.Moment {
    const date = new Date(d.year, d.month - 1, d.day);
    return moment(date);
  }

  public static ngbDateTime2moment(d: NgbDateStruct, t: NgbTimeStruct): moment.Moment {
    const date = new Date(d.year, d.month - 1, d.day, t.hour, t.minute);
    return moment(date);
  }

  public static ngbDateTime2moment_tz(d: NgbDateStruct, t: NgbTimeStruct, tZName: string = 'GMT'): moment.Moment {
    // Use moment to create a moment object in the specified timezone directly
    return moment.tz({
      year: d.year,
      month: d.month - 1, // Month is 0-indexed in Moment.js
      date: d.day,
      hour: t.hour,
      minute: t.minute,
      second: t.second || 0, // Assuming 0 if not specified
      millisecond: 0, // If needed, set this as well
    }, tZName);
  }

}
