import {Injectable} from '@angular/core';
import {CognitoUtil} from './cognito.service';
import {Setting} from '../settings/Setting';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ChannelChange} from '../settings/channel-change';

@Injectable()
export class SettingsService {
  public static DEFAULT_CURRENCY: string[] = ['United States Dollars – USD', '&#x24;'];
  public static CURRENCIES: string[][] = [
    //ref: http://symbologic.info/currency.htm
    ['Euro – EUR', '&#x20AC;'],
    ['Argentina Pesos – ARS', '&#x0024;'],
    ['Australia Dollars – AUD', 'A&#x0024;'],
    ['Bangladesh Taka – BDT', '&#2547;'],
    ['Brazil Reais – BRL', '&#x52;&#x24;'],
    ['Canada Dollars – CAD', 'C&#24;'],
    ['China Yuan Renminbi – CNY', '&#165;'],
    ['Denmark Kroner – DKK', '&#107;&#114;'],
    ['India Rupees – INR', '&#8377;'],
    ['Indonesia Rupiahs – IDR', '&#82;&#112;'],
    ['Israel New Shekels – ILS', '&#8362;'],
    ['Japan Yen – JPY', '&#165;'],
    ['Korea (South) Won – KRW', '&#8361;'],
    ['Mexico Pesos – MXN', '&#36;'],
    ['New Zealand Dollars – NZD', 'NZ&#36;'],
    ['Norway Kroner – NOK', '&#107;&#114;'],
    ['Russia Rubles – RUB', 'руб'],
    ['Singapore Dollars – SGD', 'S&#36;'],
    ['South Africa Rand – ZAR', '&#82;'],
    ['Sweden Kronor – SEK', '&#107;&#114;'],
    ['Switzerland Francs – CHF', 'Fr'],
    ['United Kingdom Pounds – GBP', '&#163;'],
    ['United States Dollars – USD', '&#x24;']
  ]

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtil) {
  }

  doGetAllSettings() {
    let myThis = this;

    const url = `${environment.apiRoot}/settings`;
    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders
        .set('Content-Type', 'application/json');
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data.settings as Setting[]);
          },
          (error) => {
            reject(error)
          }
        );
    });
  }

  doChangeCollaborationPlatformChannelNameToBePosted(newChannelName: string, newChannelId, oldChannelName: string) {
    let myThis = this;
    const url = `${environment.apiRoot}/c-platform/channel-change`;
    let collaborationPlatformChannel = new ChannelChange();
    collaborationPlatformChannel.newChannelName = newChannelName;
    collaborationPlatformChannel.newChannelId = newChannelId;
    collaborationPlatformChannel.oldChannelName = oldChannelName;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.post(url, collaborationPlatformChannel, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            console.log('Error occurred: ', error);
            reject(error)
          }
        );
    });
  }

  doGetCollaborationPlatformChannelNames(prefix: string) {
    let myThis = this;
    const url = `${environment.apiRoot}/c-platform/list-channels?prefix=` + prefix;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            console.log('Error occurred: ', error);
            reject(error);
          }
        );
    });
  }
}
