import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationCallback, UserRegistrationService} from '../service/user-registration.service';
import {CognitoCallback} from '../service/cognito.service';
import {PasswordValidator} from './PasswordValidator';
import {SpinnerVisibilityService} from "ng-http-loader";

export class RegistrationUser {
  readonly BETA_REGISTRATION_KEY_LENGTH = 12;
  public betaRegistrationKey: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public email: string;
  public phone_number: string;
  public password: string;
  public confirmPassword: string;
  public group: string;
  public role: string;
  public slackWorkspaceName: string;
  public pleaseShareAppInstalled: string = "false";
}

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements CognitoCallback, RegistrationCallback {
  private router: Router;
  public isValidBetaAccessKey = false;
  registrationUser: RegistrationUser;
  errorMessage: string;
  public isStrongPassword = true;

  constructor(public userRegistrationService: UserRegistrationService, router: Router, private spinner: SpinnerVisibilityService) {
    this.router = router;
    this.onInit();
  }

  onInit() {
    this.registrationUser = new RegistrationUser();
    // Initialize Beta Registration Key as Empty
    this.registrationUser.betaRegistrationKey = "";
    this.errorMessage = null;
  }

  onBetaKeyChangeCheckLength() {
    return this.registrationUser.betaRegistrationKey &&
      this.registrationUser.betaRegistrationKey.length !== this.registrationUser.BETA_REGISTRATION_KEY_LENGTH;
  }

  onValidateRegistrationFirstStep() {
    let _betaAccessKey = this.registrationUser.betaRegistrationKey || "";
    this.errorMessage = null;
    if (_betaAccessKey.length !== this.registrationUser.BETA_REGISTRATION_KEY_LENGTH) {
      this.errorMessage = "Please provide a valid beta access key.";
      return;
    }
    if (!this.registrationUser.slackWorkspaceName || this.registrationUser.slackWorkspaceName === "") {
      this.errorMessage = "Please provide a valid slack workspace name";
      return;
    }
    this.userRegistrationService.doValidateRegistrationFirstStep(this.registrationUser.betaRegistrationKey, this.registrationUser.slackWorkspaceName, this);
  }

  onRegister() {
    this.errorMessage = null;
    this.registrationUser.password = this.registrationUser.password || "";
    this.registrationUser.betaRegistrationKey = this.registrationUser.betaRegistrationKey || "";
    if (!this.registrationUser.firstName || !this.registrationUser.lastName) {
      this.errorMessage = "First and Last name must not be empty";
      return;
    }
    if (!this.registrationUser.email) {
      this.errorMessage = "Please provide a valid email address.";
      return;
    }

    if (!PasswordValidator.isValid(this.registrationUser.password)) {
      this.isStrongPassword = false;
      return;
    }

    if (this.registrationUser.betaRegistrationKey.length !== this.registrationUser.BETA_REGISTRATION_KEY_LENGTH) {
      this.errorMessage = "Please provide a valid beta access key.";
      return;
    }
    this.spinner.show();
    this.userRegistrationService.register(this.registrationUser, this);
  }

  doValidatePassword(event: any) {
    this.isStrongPassword = PasswordValidator.isValid(event.target.value);
  }

  cognitoCallback(message: string, result: any) {
    this.spinner.hide();
    if (message != null) { //error
      this.errorMessage = message;
    } else { //success
      //move to the next step
      this.router.navigate(['/registration-success', result.user.username], {queryParamsHandling: 'preserve'});
    }
  }

  registrationCallback(errorMessage: string, result: any): void {
    if (errorMessage) {
      this.errorMessage = errorMessage;
    }
    this.isValidBetaAccessKey = result !== null && result === "REGISTRATION_STEP1_VALID";
  }

  closeAndResetErrorMessage() {
    this.errorMessage = null;
  }
}
