<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
  <div class="login-register" style="background-color:#f2f7f8;">
    <div class="login-box card">
      <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <div class="card-body">
        <form class="form-signin" method="POST" action="#" role="form">
          <div class="form-group">
            <h2>Confirm Registration</h2>
          </div>
          <div *ngIf="errorMessage!=null" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="form-group">
            <label class="control-label" for="email">Email</label>
            <input id="email" type="email" maxlength="50" class="form-control"
                   [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group">
            <label class="control-label" for="temporaryPassword">Temporary Password</label>
            <input id="temporaryPassword" type="password" maxlength="50" class="form-control"
                   [(ngModel)]="temporaryPassword" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group">
            <button (click)="onConfirmRegistration()" id="confirmRegistration" type="submit"
                    class="btn btn-info btn-block">Confirm
            </button>
          </div>
          <hr>
          <p>
          <a
            [routerLink]="['login']"> <i class="fa fa-fw fa-user"></i> Login</a>
          </p>
        </form>

      </div>
    </div>
  </div>

</section>


