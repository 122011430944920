import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FlashMessagesService} from "angular2-flash-messages";

@Injectable()
export class PsFlashMessageUtil {
  private static defaultErrorMessage: string = 'Something weird happened, please try again.';
  private static defaultTimeout: number = 5000;

  constructor(private _flashMessagesService: FlashMessagesService) {
  }

  public handleHttpObservable<T>(observable: Observable<any>, responseFunc: (response: T) => void) {
    const myThis = this;
    PsFlashMessageUtil
      .validateHttpObservable<T>(observable)
      .toPromise()
      .then(
        response => responseFunc(response),
        error => myThis.handleHttpError(error, msg => myThis._flashMessage(myThis, msg)))
      .catch(error => myThis.handleHttpError(error, msg => myThis._flashMessage(myThis, msg)));
  }

  public flashMessage(msg: string, error: boolean = true) {
    this._flashMessage(this, msg, error);
  }

  public _flashMessage(myThis: PsFlashMessageUtil, msg: string, error: boolean = true) {
    try {
      if (error) {
        myThis._flashMessagesService
          .show(msg, {
            cssClass: 'alert-danger',
            timeout: PsFlashMessageUtil.defaultTimeout
          });
      } else {
        myThis._flashMessagesService
          .show(msg, {
            cssClass: 'alert-success',
            timeout: PsFlashMessageUtil.defaultTimeout
          });
      }
    } catch (e) {
      console.error(`flashMessage failed for msg: ${msg} :: ${e}`);
    }
  }

  public handleHttpError(response: any, errorFunc: (msg: string) => void): void {
    if (errorFunc != null) {
      let errorMessage: string;

      if (response instanceof Error) {
        errorMessage = response.message;
      } else {
        errorMessage = PsFlashMessageUtil.parseErrors(response)
      }

      errorFunc(errorMessage || PsFlashMessageUtil.defaultErrorMessage);
    }
  }

  public static validateHttpObservable<T>(observable: Observable<any>): Observable<T> {
    return observable.pipe(map(response => PsFlashMessageUtil.validateResponse<T>(response)));
  }

  public static validateResponse<T>(response: any): T {
    if (response == null || !response.success) {
      throw new Error(PsFlashMessageUtil.parseErrors(response));
    }

    return response['data'] as T;
  }

  public static parseErrors(response: any): string {
    if (response == null || response.success) {
      return PsFlashMessageUtil.defaultErrorMessage;
    }

    let errorMessage = '';
    response.errors?.forEach((e: any, index: number) => {
      errorMessage += e.message;
      if (e[index + 1]) {
        errorMessage += '<br />';
      }
    });

    return errorMessage;
  }
}
