import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: '[id="app-register-success"][style="width:100%"]',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit, OnDestroy {

  private sub: any;
  public email: string;

  constructor(public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['email'];
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
