import {Injectable} from '@angular/core';
import {CognitoUtil} from './cognito.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SubscriptionPlanUpdateSessionResponse} from '../subscription-plan-update/subscription.plan.update.session.response';


declare var Stripe: any;

@Injectable()
export class StripeCheckoutService {
  private stripe: any;

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtil) {
    this.stripe = Stripe(environment.STRIPE_PUBLISHABLE_KEY);
  }

  doAddPaymentMethodOption(sessionId: string) {
    return this
      .stripe
      .redirectToCheckout({
        sessionId: sessionId
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        return new Promise((resolve, reject) => {
          if (result && result.error && result.error.message) {
            reject(result.error);
          } else {
            resolve(result);
          }
        });
      });
  }

  doUpdatePaymentMethodOption(response: SubscriptionPlanUpdateSessionResponse) {
    const myThis = this;
    return new Promise((resolve, reject) => {
      if (!response.data) {
        console.error('Unable to obtain stripe session for your account');
        reject({success: false, message: 'Unable to obtain stripe session for your account'});
        return;
      }
      myThis.stripe.redirectToCheckout({
        sessionId: response.data
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result && result.error && result.error.message) {
          reject({success: false, message: result.error.message});
        } else {
          resolve({success: true, message: 'Successfully updated your payment information.'});
        }
      });
    });
  }

  getStripeSessionId(stripePlanId: string, sessionType: string, savedCampaignId?: number) {
    const myThis = this;
    const url = `${environment.apiRoot}/checkout/createSessionId`;

    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.post(url, {
        stripePlanId: stripePlanId,
        sessionType: sessionType,
        savedCampaignId: savedCampaignId
      }, {headers: httpHeaders})
        .subscribe(
          (response: any) => {
            if (response.data != null) {
              resolve(response);
            } else {
              reject(response);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
