import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserLoginService} from './user-login.service';
import {CognitoUtil} from './cognito.service';
import {Observable} from 'rxjs';
import {UserProfile} from '../users/UserProfile';

import * as jwt_decode from 'jwt-decode';
import {map} from 'rxjs/operators';

@Injectable()
export class HeaderDetailsResolver implements Resolve<UserProfile> {

  constructor(
    private userLoginService: UserLoginService,
    private cognitoUtil: CognitoUtil
  ) {
  }


  public static hasAccountId(profile) {
    return profile.accountId !== undefined && profile.accountId !== null;
  }

  resolve(): Observable<UserProfile> {
    const myThis = this;
    const currentUser = this.cognitoUtil.getCurrentUser();
    if (!currentUser) {
      return;
    }

    const userName = currentUser.getUsername();
    const decodedPayload = myThis.getDecodedAccessToken(myThis.cognitoUtil.idToken);
    const group = decodedPayload['custom:psGroupName'];

    let groupName;
    if (typeof group !== 'undefined' && group !== '') {
      groupName = group;
    }

    return myThis.userLoginService.getLoggedInUserProfile(userName, groupName).pipe(map((response: any) => {
      const profile: UserProfile = response.data;
      let displayFeedEngagementPostHeaderSection: boolean = false;
      let autoDeleteShareRequestsAfterDay: number = -1;
      if (localStorage.getItem(userName) === undefined || localStorage.getItem(userName) === null) {
        profile.settings && profile.settings.map(setting => {
          if (setting.name === 'display.feed.engagement.post.header.section') {
            displayFeedEngagementPostHeaderSection = setting.value === 'true';
          }
          if (setting.name === 'auto.delete.share.requests.after.day') {
            autoDeleteShareRequestsAfterDay = Number(setting.value);
          }
        })

        const _profile = new UserProfile(
          decodedPayload['custom:role'],
          HeaderDetailsResolver.hasAccountId(profile),
          groupName,
          decodedPayload.email,
          decodedPayload['custom:companyName'],
          profile.workspaceName,
          profile.accountId,
          decodedPayload['given_name'],
          decodedPayload['family_name'],
          decodedPayload['custom:address'],
          profile.settings,
          profile.channelToBePosted,
          profile.channelIdToBePosted,
          profile.accountStatus,
          profile.activeWorkspaceUsersCount,
          profile.activeWorkspaceUsersCountCheckDate,
          profile.trailPeriodLeftInDays,
          profile.paidUser,
          profile.enterprisePlanUser,
          profile.currentPlanName,
          profile.currentPlanPricing,
          profile.currentPlanCurrency,
          profile.nextPaymentDate,
          profile.trialEndDate,
          profile.subscriptionCancelable,
          profile.paymentMethodEditable,
          profile.collaborationPlatformName,
          profile.enabledFeatures,
          profile.psbotInstalledInPrivateChannel,
          displayFeedEngagementPostHeaderSection,
          profile.currencyConvertedValue,
          autoDeleteShareRequestsAfterDay,
          profile.adminAuthType,
          profile.billableUserCount,
          profile.tzName);

        localStorage.setItem(this.cognitoUtil.getCurrentUser().getUsername(), JSON.stringify(_profile));
        return _profile;
      }
      return profile;
    }));
  }

  public getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
