<!-- Bread crumb and right sidebar toggle -->
<div class="row page-titles">
  <div class="col-md-8 align-self-center">
    <h2 class="text-themecolor m-b-0 m-t-0" style="padding-bottom:10px">Users</h2>
    <!-- Nav tabs -->
  </div>
</div>
<!-- End Bread crumb and right sidebar toggle -->
<!-- Start Page Content -->
<!-- Column -->
<div class="row">
  <div class="col-sm-12">
    <div class="card" style="min-height: 800px">
      <div class="card-body">
        <h4 class="card-title">Manage Users</h4>
        <h6 class="card-subtitle">Add or remove account users and/or change permission settings.</h6>
        <hr>
        <div class="table-responsive" style="padding-top:20px">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>User</th>
              <th>Email Address</th>
              <th>Permissions</th>
              <th>Update</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Joe Smith</td>
              <td>joe@test.com</td>
              <td>Admin</td>
              <td>Edit</td>
            </tr>
            <tr>
              <td>Bob Jones</td>
              <td>bob@test.com</td>
              <td>Poster</td>
              <td>Edit</td>
            </tr>
            <tr>
              <td>Suzy Walker</td>
              <td>suzy@test.com</td>
              <td>Poster</td>
              <td>Edit</td>
            </tr>
            </tbody>
          </table>
          <div>
            <button type="submit" class="btn btn-success pull-right" style="margin-top:40px;">Add User</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
