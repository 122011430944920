import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BaseHttpService} from "./base.http.service";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {TzInfo} from "./dtos/tz.info.dto";
import {LocalStorageCacheService} from "./localstorage.cache.service";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class TimezoneService extends BaseHttpService {

  private static DEFAULT_TZ_INFO: TzInfo = {
    name: 'UTC',
    shortName: 'UTC',
    label: '(UTC+00:00) UTC',
    isDaylightSavings: false,
    offsetTotalSeconds: 0,
  } as TzInfo;

  constructor(http: HttpClient, private localStorageCacheService: LocalStorageCacheService) {
    super(http);
  }

  getTzInfo(tzName: string): Observable<TzInfo> {
    if (tzName == null || tzName.trim().length == 0) {
      return of(TimezoneService.DEFAULT_TZ_INFO);
    }

    return this.getAllTzInfo().pipe(
      map(tzInfoList => {
        const tzInfoData = tzInfoList.find(tzInfo => tzInfo.name == tzName);
        return tzInfoData || TimezoneService.DEFAULT_TZ_INFO;
      })
    );
  }

  getAllTzInfo(): Observable<TzInfo[]> {
    const observable = this.getObservable(`${environment.apiRoot}/c-platform/tz-info`);
    return this.localStorageCacheService.get("tz_info", observable);
  }

  updateTzName<T>(tzName: string) {
    return this.putObservable<T>(`${environment.apiRoot}/c-platform/tz-info`, {'tzName': tzName});
  }
}
