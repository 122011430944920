import {Component, OnInit} from '@angular/core';
import {ChallengeParameters, CognitoCallback, CognitoUtil, LoggedInCallback} from '../service/cognito.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginService} from '../service/user-login.service';
import {UserSetupService} from '../service/user-setup.service';
import {UserRegistrationService} from '../service/user-registration.service';
import {RegistrationUser} from '../register/register.component';
import {UserProfileService} from '../service/user-profile.service';
import {UserProfile} from '../users/UserProfile';
import {PasswordValidator} from '../register/PasswordValidator';
import {DataService} from '../service/data.service';
import {NavbarService} from '../service/navbar.service';

export interface SetupUserCallback {
  setupUserCallback(message: string, result: any): void;
}


@Component({
  selector: '[id="user-setup-wrapper"][style="width:100%"]',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss']
})
export class UserSetupComponent implements OnInit, CognitoCallback, SetupUserCallback, LoggedInCallback {
  public userLoggedIn: boolean;
  public accountJWTToken: string;
  public errorMessage: string;
  public registrationUser: RegistrationUser;
  public isStrongPassword = true;
  public email: string;
  public password: string;

  constructor(private cognitoUtil: CognitoUtil, private router: Router,
              private activatedRoute: ActivatedRoute,
              private loginService: UserLoginService,
              private userSetupService: UserSetupService,
              private userLoginService: UserLoginService,
              private userRegistrationService: UserRegistrationService,
              private userProfileService: UserProfileService,
              private dataService: DataService,
              public nav: NavbarService) {
    this.activatedRoute.params.subscribe(params => {
      this.accountJWTToken = params['accountJWTToken'];
    });
    this.registrationUser = new RegistrationUser();
    this.errorMessage = null;
  }

  ngOnInit() {
    setTimeout(() => {
      this.nav.hide();
    }, 0);
    this.userLoginService.isAuthenticated(this);
  }

  public onRegister() {
    // TODO: Need to find out a better way to set admin role
    // Initially we are assuming that user's with slack admin privilege be an admin user
    this.registrationUser.role = 'admin';

    // This is the group attribute and if it is set then from backend
    // we are associating this particular user to this group
    this.registrationUser.group = this.accountJWTToken;

    this.userRegistrationService.register(this.registrationUser, this);
  }

  doValidatePassword(event: any) {
    this.isStrongPassword = PasswordValidator.isValid(event.target.value);
  }

  onLogin() {
    if (this.email == null || this.password == null) {
      this.errorMessage = 'Please provide valid login credentials';
      return;
    }
    this.errorMessage = null;
    this.userLoginService.authenticate(this.email, this.password, this);
  }

  cognitoCallback(err: string, result: any): void {
    if (err != null) {
      alert('Something weird happened. Please reinstall our slack bot app.');
    } else {
      this.router.navigate(['/registration-success', this.registrationUser.email]);
    }
  }

  setupUserCallback(err: string, result: any): void {
    if (err != null) {
      window.location.reload();
    } else {
      alert('Something weird happened. Please reinstall our slack bot app.');
    }
  }


  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    throw new Error('Method not implemented.');
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    this.userLoggedIn = loggedIn;
    let myThis = this;
    if (loggedIn) {
      this.cognitoUtil.getAccessToken({
        callback() {

        },
        callbackWithParam(accessToken: any) {
          myThis.userSetupService.linkUserWithCognitoGroup(null, accessToken, myThis.accountJWTToken).then(
            (response: any) => { // Success
              const data = response.data;
              if (data.userLinked === true) {
                // Remove user profile information from session
                let userName = myThis.cognitoUtil.getCurrentUser().getUsername();
                let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
                profile.hasGroup = true;
                profile.groupName = data.groupName;
                profile.accountStatus = data.accountStatus;
                profile.workspaceName = data.workspaceName;
                profile.accountId = data.accountId;
                profile.settings = data.settings;
                profile.currentPlanCurrency = data.currentPlanCurrency;
                profile.currentPlanPricing = data.currentPlanPricing;
                profile.currentPlanName = data.currentPlanName;
                profile.paidUser = data.paidUser;
                profile.nextPaymentDate = data.nextPaymentDate;
                profile.activeWorkspaceUsersCountCheckDate = data.activeWorkspaceUsersCountCheckDate;
                profile.activeWorkspaceUsersCount = data.activeWorkspaceUsersCount;
                profile.trailPeriodLeftInDays = data.trailPeriodLeftInDays;

                localStorage.removeItem(userName);
                localStorage.setItem(userName, JSON.stringify(profile));
                myThis.userProfileService.changeProfile(profile);
                myThis.dataService.changeMessage('appInstalled/true');
                myThis.cognitoUtil.refresh();
                myThis.router.navigate(['/home']);
              } else {
                alert('Something weird happened. Please reinstall our slack bot app.');
                window.location.href = '/';
              }
            }
          );
        },
        callbackWithParams(idToken: any, accessToken: any) {

        }
      });
    }
  }
}
