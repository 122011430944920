export const environment = {
  production: false,
  environmentName: 'dev',
  region: 'us-west-2',
  uploadBucketName: 'dev.social.network.images.and.videos.pleaseshare.co',
  uploadKeyPrefix:"dev/pleaseshare",
  "pleaseshare.co.video.attached.image.url": "https://pleaseshare.co/assets/images/video_attached.png",
  identityPoolId: 'us-west-2:ffe7fa0a-771d-4e5d-9352-e061c605abe3',
  superAdminRole: 'super_admin',
  federationConfig: {
    AppWebDomain: 'dev-ps-cg-domain.auth.us-west-2.amazoncognito.com',
    RedirectUriSignIn: 'https://dev-app.pleaseshare.co/okta-dev.html',

    // don't append '/' at the end of RedirectUriSignOut
    RedirectUriSignOut: 'https://dev-app.pleaseshare.co',
    TokenScopesArray: ['openid', 'email', 'profile'],
    AdvancedSecurityDataCollectionFlag: false,
  },
  userPoolId: 'us-west-2_mmXck5BMe',
  clientId: '525ghnul7967dbrvjsd6q4nvv5',
  apiRoot: 'https://dev.pls.sh/v1',
  publicUrlPath: [
    'confirm-register/',
    'user/setup/',
    'user/setup-error',
    'user/sign-up-as-admin',
    'user/sign-up-as-invited-admin',
    'registration-success/',
    'login?',
    'register',
    'forgot-pw',
    'reset-pw',
    'reset-password',
    'twitter-authorization-success',
    'linkedin-authorization-success',
    'user/workspace-exist',
    'user/verify-login-creds',
  ],
  // 8 Hours
  applicationIdleTimeout: 8 * 60 * 60,
  historyPerPage: 10,
  leaderboardTeamMembersPerPage: 10,
  maxPaginationLinksToDisplay: 5,
  INTERCOM_ENABLED: false,
  INTERCOM_APP_ID: 'dev1234',
  intercomProductTourId: 89524,
  slackAppNotificationMsg: 'Your <strong>Please Share</strong> account is not currently associated to a Slack workspace. Click the button on the right to add it now.',
  slackAppDownloadLink: 'https://slack.com/oauth/v2/authorize?client_id=204053010417.510069736416&scope=incoming-webhook,channels:join,channels:read,chat:write,emoji:read,users:read,users:read.email&user_scope=channels:read,groups:write,groups:read&state=_state_',
  googleAnalyticsKey: 'fake-google-analytics-key',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_2Q3yksU0pIHK74MYxQuyVYeH'
};
