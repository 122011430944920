<div class="container-fluid" style="padding: 40px 20px 20px 60px;">
    <div class="row page-titles" style="padding: 20px 0 20px 0;">
    </div>

    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <form class="form-signin">
                <div class="form-group">
                    <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible">
                        <button type="button" class="close" (click)="closeAndResetErrorMessage()" data-hide="alert">
                            &times;
                        </button>
                        <div [innerHTML]="errorMessage | keepHtml"></div>
                    </div>
                </div>
                <div *ngIf="currentUserProfile.enterprisePlanUser === true" class="col-md-12">
                    <div class="frb-group">
                        Your Please Share subscription has expired. If you would like to sign up again, email us at support@pleaseshare.co.
                    </div>
                </div>
                <h2 *ngIf="!currentUserProfile.enterprisePlanUser" class="form-signin-heading d-flex justify-content-center">Just a moment please…</h2>
                <hr *ngIf="!currentUserProfile.enterprisePlanUser">
                <p *ngIf="!currentUserProfile.enterprisePlanUser">You will be automatically redirected to the payment screen momentarily.</p>
            </form>
        </div>
        <div class="col-lg-3"></div>
        <div class="col-lg-12" style="padding-top:40px">
            <div>
                <form style="max-width: 580px;margin: 0 auto;">
                    <div class="frb frb-ps">
                        <div class="float-left">
                            <a routerLink="/settings/account-settings"> Back to settings page </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
