import {HttpClient} from "@angular/common/http";
import {jsonIgnoreReplacer} from 'json-ignore';

export abstract class BaseHttpService {

  private static BASE_HEADERS: any = {
    headers: {'Content-Type': 'application/json'}
  };

  protected constructor(private http: HttpClient) {}

  protected getObservable<T>(url: string) {
    return this.http.get<T>(url, BaseHttpService.BASE_HEADERS);
  }

  protected postObservable<T>(url: string, body: any) {
    const data = JSON.stringify(body, jsonIgnoreReplacer);
    return this.http.post<T>(url, data, BaseHttpService.BASE_HEADERS);
  }

  protected putObservable<T>(url: string, body: any) {
    const data = JSON.stringify(body, jsonIgnoreReplacer);
    return this.http.put<T>(url, data, BaseHttpService.BASE_HEADERS);
  }

  protected deleteObservable<T>(url: string) {
    return this.http.delete<T>(url, BaseHttpService.BASE_HEADERS);
  }
}
