<div class="wrapper" style="width:100%; padding: 40px 20px 20px 60px;">
  <div class="d-flex justify-content-center align-self-center">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-12" style="padding-bottom: 40px;">
        <div class="form-payment table-box">
          <div class="form-group">
            <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible">
              <button type="button" class="close" (click)="closeAndResetErrorMessage()" data-hide="alert">&times;
              </button>
              <div [innerHTML]="errorMessage | keepHtml"></div>
            </div>
          </div>
          <h2 *ngIf="!errorMessage" class="form-payment-heading d-flex justify-content-center">Subscription Details</h2>
          <hr *ngIf="!errorMessage">
          <!-- Column -->
          <div *ngIf="currentUserProfile.enterprisePlanUser === true" class="col-md-12">
            <div class="frb-group">
              Your Please Share subscription has expired. If you would like to sign up again, email us at support@pleaseshare.co.
            </div>
          </div>
          <div class="col-md-12" *ngFor="let _subscriptionPlan of subscriptionPlanResponse$ | async; let i = index">
            <div class="frb-group">
              <div class="frb frb-ps" *ngIf="i === 0">
                <label for="radio-button-{{i}}">
                  <span class="frb-title" style="margin-bottom: 10px;" *ngIf="_subscriptionPlan.subscriptionPlanVersion === 'FIXED_AUTHENTICATED_USER_PLAN_VERSION'">
                    With {{_subscriptionPlan.numberOfActiveBillableUsers}} active users in your company’s
                    {{(collaborationPlatformName|lowercase) === "slack"?"Slack workspace":"Teams account"}},
                    you qualify for the following pricing options.
                    Please choose between the Monthly or Annual subscription plan and then select Proceed to Checkout.
                  </span>
                  <span class="frb-title" style="margin-bottom: 10px;" *ngIf="_subscriptionPlan.subscriptionPlanVersion === 'PER_AUTHENTICATED_USER_PLAN_VERSION' || _subscriptionPlan.subscriptionPlanVersion === 'PER_AUTHENTICATED_USER_WITH_FLAT_FEE_PLAN_VERSION'">
                    Please Share charges $4.00 USD per month per "active user" within your company's {{(collaborationPlatformName|lowercase) === "slack"?"Slack workspace":"Teams account"}}.
                    An active user is an employee who has signed into their Twitter and/or LinkedIn account through Please Share and, via our service,
                    has engaged with or shared at least one social media update in the past 30 days.
                    Deactivated Slack users are automatically removed on a monthly basis and you can cancel your subscription at any time.
                  </span>
                </label>
              </div>
              <div class="frb frb-ps" *ngIf="(subscriptionPlanResponse$ | async)?.length > 1">
                <input type="radio" [(ngModel)]="stripePlanId"
                       id="radio-button-{{i}}"
                       name="radio-button"
                       value="{{_subscriptionPlan.planId}}">
                <label for="radio-button-{{i}}">
                  <span class="frb-title" style="margin-bottom: 10px;" *ngIf="_subscriptionPlan.subscriptionType === 'MONTHLY'">
                    Monthly Subscription ({{_subscriptionPlan.subscriptionPricing}} per month)
                  </span>
                  <span class="frb-title" style="margin-bottom: 10px;" *ngIf="_subscriptionPlan.subscriptionType === 'ANNUAL'">
                    Annual Subscription ({{_subscriptionPlan.subscriptionPricing}} per year)
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div>
          <form style="max-width: 580px;margin: 0 auto;">
            <div class="frb frb-ps">
              <div class="float-left">
                <a routerLink="/settings/account-settings"> Back to settings page </a>
              </div>
              <div *ngIf="currentUserProfile.enterprisePlanUser !== true" class="float-right">
                <button type="submit" (click)="doAddPaymentMethodOption()" class="btn btn-checkout cta-button">Proceed
                  to Checkout
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
