<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
  <div class="login-register" style="background-color:#f2f7f8;">
    <div class="login-box card">
      <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <div class="card-body">
        <form class="form-horizontal form-material" id="loginform">
          <h3 class="box-title m-b-20 text-center">Invite a New User</h3>
          <hr>
          <div class="form-group">
            <div class="col-xs-12">
              <input class="form-control" type="text" [(ngModel)]="registrationUser.firstName" name="firstName"
                     placeholder="First Name">
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input class="form-control" [(ngModel)]="registrationUser.lastName" type="text" name="lastName"
                     placeholder="Last Name">
            </div>
          </div>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="email" [(ngModel)]="registrationUser.email" name="email" required
                     placeholder="Email">
            </div>
          </div>

          <div class="form-group text-center m-t-20">
            <select class="form-control" id="sel1">
              <option>Select Company Name</option>
              <option>Joinin</option>
              <option>Google</option>
              <option>Yahoo</option>
              <option>Netop</option>
            </select>
          </div>

          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-info btn-lg btn-block waves-effect waves-light" type="submit"
                      (click)="onInvite()">Send Invitation
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</section>
