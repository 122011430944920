import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginService} from '../../../service/user-login.service';
import {CognitoCallback} from '../../../service/cognito.service';
import {SpinnerVisibilityService} from "ng-http-loader";
import {FlashMessagesService} from 'angular2-flash-messages';

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './forgotPassword.html',
})
export class ForgotPasswordStep1Component implements CognitoCallback {
  public forgotEmail: string;
  public errorMessage: string;
  public successMessage: string;

  constructor(public router: Router,
              public userService: UserLoginService,
              private spinner: SpinnerVisibilityService) {
    this.errorMessage = null;
    this.forgotEmail = null;
  }

  onForgotPassword() {
    this.errorMessage = null;
    this.spinner.hide();
    if (!this.forgotEmail) {
      this.errorMessage = 'Please provide a valid email address.';
      return;
    }
    this.spinner.show();
    this.userService.forgotPassword(this.forgotEmail, this);
  }

  cognitoCallback(message: string, result: any) {
    this.spinner.hide();
    if (message == null && result == null) { //success
      this.errorMessage = null;
      this.successMessage = 'Please check your inbox in a few moments for a link where you can reset your password.';
    } else { //error
      this.errorMessage = message.replace("Username/client id combination not found.", "Email address not found.");
    }
  }
}


@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './forgotPasswordStep2.html'
})
export class ForgotPassword2Component implements CognitoCallback, OnInit, OnDestroy {

  private triggerSource: string;
  private verificationCode: string;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public errorMessage: string;
  public successMessage: string;
  private sub: any;

  constructor(public router: Router, public route: ActivatedRoute,
              public userService: UserLoginService, private spinner: SpinnerVisibilityService,
              private _flashMessagesService: FlashMessagesService) {
  }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.verificationCode = params['confirmation_code'];
      this.triggerSource = params['trigger_source'];
    });
    this.errorMessage = null;
    this.successMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onPasswordReset() {
    this.errorMessage = null;
    if (this.password !== this.confirmPassword) {
      this.errorMessage = 'Password and confirm password must be same';
      return;
    }
    this.spinner.show();
    switch (this.triggerSource) {
      case "CustomMessage_ForgotPassword": {
        this.userService.confirmNewPassword(this.email, this.verificationCode, this.password, this);
        break;
      }

      case "CustomMessage_AdminCreateUser": {
        this.userService.adminCreateUserResetPassword(this.email, this.verificationCode, this.password, this);
        break;
      }
    }
  }

  cognitoCallback(message: string) {
    this.spinner.hide();
    if (message != null) { //error
      this._flashMessagesService.show(message, {cssClass: 'alert-danger', timeout: 5000});
    } else { //success
      this._flashMessagesService.show('Your password has been reset successfully', {cssClass: 'alert-success', timeout: 5000});
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 5000);
    }
  }
}
