import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Schedule} from '../schedules/Schedule';
import {ShareClick} from '../schedules/ShareClick';
import {environment} from '../../environments/environment';
import {FeedsEngagementStats} from "../schedules/FeedsEngagementStats";
import {BaseHttpService} from "./base.http.service";

export interface ChannelMetadataResponse {
  channelId: string;
  channelName: string;
}

export interface AttachedFileDto {
  type: string;
  objectKey: string;
  isDefaultThumbnail: string;
  isS3Object: string;
}

export interface SharingDetails {
  message: string;
  link: string;
  imageUrl: string;
  commentary: string;
}

export interface ScheduleNetworkResponse {
  networkType: string;
  sharingDetails: SharingDetails;
  attachedFiles: AttachedFileDto[];
}

export interface ScheduleDetailsResponse {
  id: number;
  subject: string;
  scheduleType: string;
  scheduleSource: string;
  status: string;
  notifiesAllMembersOfAChannel: boolean;
  isScheduledForFuture: boolean;
  scheduleEpochSecond: number;
  channelMetadata: ChannelMetadataResponse[];
  scheduleNetwork: ScheduleNetworkResponse[];
}

@Injectable()
export class ScheduleService extends BaseHttpService {
  schedules: Schedule[];
  shareClick: ShareClick;
  feedsEngagementStats: FeedsEngagementStats;

  constructor(http: HttpClient) {
    super(http);
  }

  public getShareClickCount(dateFrom: string, dateTo: string) {
    const url = `${environment.apiRoot}/reports?dateFrom=` + dateFrom + `&dateTo=` + dateTo;
    const myThis = this;

    return new Promise((resolve, reject) => {
      myThis.getObservable(url)
        .subscribe(
          (data: any) => {
            myThis.shareClick = data.data as ShareClick;
            resolve([myThis.shareClick]);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getFeedsEngagementStatistics(dateFrom: string, dateTo: string) {
    const url = `${environment.apiRoot}/engagement/stats?dateFrom=` + dateFrom + `&dateTo=` + dateTo;
    const myThis = this;

    return new Promise((resolve, reject) => {
      myThis.getObservable(url)
        .subscribe(
          (data: any) => {
            myThis.feedsEngagementStats = data.data as FeedsEngagementStats;
            resolve([myThis.feedsEngagementStats]);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getSchedules(scheduleType: string, limit: number, offset: number) {
    return this.getObservable(`${environment.apiRoot}/schedule?scheduleType=${scheduleType}&limit=${limit}&offset=${offset}`);
  }

  public getScheduleDownloadAsCsv(accountId: string = null, selectedMonthsForDownload: string = null) {
    if (accountId != null && accountId.trim().length > 0) {
      return this.getObservable(`${environment.apiRoot}/schedule/download-as-csv?accountId=${encodeURIComponent(accountId)}&selectedMonths=${selectedMonthsForDownload || ''}`);
    } else {
      return this.getObservable(`${environment.apiRoot}/schedule/download-as-csv?selectedMonths=${selectedMonthsForDownload || ''}`);
    }
  }

  public getScheduleDetails(scheduleId: number) {
    if (scheduleId == null || scheduleId <= 0) {
      throw new Error('Invalid Schedule Id.');
    }

    return this.getObservable(`${environment.apiRoot}/schedule/${scheduleId}`);
  }

  public deleteSchedule(scheduleId: number, scheduleType: string) {
    return this.deleteObservable(`${environment.apiRoot}/schedule/${scheduleId}?scheduleType=${scheduleType}`);
  }
}
