<div class="wrapper">
  <div *ngIf="errorMessage" class="d-flex justify-content-center align-self-center">
    <div [innerHTML]="errorMessage" class="alert-danger alert alert-dismissible d-flex justify-content-center"
         style="width: 100%"></div>
  </div>
  <div class="col">
    <div class="row">
      <div class="col">
        <div>
          <div class="col">
            <!-- Start Form -->
            <form class="form-signin">
              <h2 class="form-signin-heading d-flex justify-content-center">Reinstall Please Share?</h2>
              <hr>
              <div class="form-group">
                <label style="font-family: roboto,sans-serif">
                  A member of your team recently {{psAppUnInstalled ? 'uninstalled' : 'installed'}} Please Share from your company’s Slack workplace.
                  Do you want to reinstall the Please Share app now?
                </label>
              </div>

              <div class="col-md-12" *ngIf="!psAppUnInstalled">
                <div class="form-group row">
                  <label class="control-label col-md-4 text-muted font-13 font-weight-bold">Installed By:</label>
                  <div class="col-md-8">
                    <p class="form-control-static">{{installedBy}}</p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-md-4 text-muted font-13 font-weight-bold">Installed on:</label>
                  <div class="col-md-8">
                    <p class="form-control-static">{{installedDate}}</p>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="psAppUnInstalled">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="control-label col-md-4 text-muted font-13 font-weight-bold">Uninstalled on:</label>
                    <div class="col-md-8">
                      <p class="form-control-static">{{unInstalledDate}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-secondary reg-button" (click)="backToHome()">No, Cancel
                </button>
                <button type="button" class="btn btn-post reg-button"
                        (click)="reInstall()">Yes, Reinstall
                </button>
              </div>

            </form>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</div>
