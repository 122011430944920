<div class="wrapper">
  <form class="form-signin">
    <h2 class="form-signin-heading d-flex justify-content-center">Sign in to Your Account</h2>
    <hr>
    <div class="form-group">
      <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        {{ errorMessage }}
      </div>
    </div>
    <div class="form-group">
      <div *ngIf="emailConfirmed!=null" class="alert alert-success">
        Thank you for confirming your email address. You can now sign in to your account.
      </div>
    </div>
    <div class="form-group">
      <label for="email" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
      <input type="email" [(ngModel)]="email" name="email" required class="form-control" id="email">
    </div>
    <div class="form-group">
      <label for="pwd" style="font-family: roboto,sans-serif">PASSWORD</label>
      <input type="password" autocomplete="false" [(ngModel)]="password" name="password" required class="form-control"
             id="pwd">
    </div>
    <button id="loginbtn" class="btn btn-post cta-button" type="submit" (click)="onLogin()">Login</button>
  </form>

  <div class="login-help d-flex justify-content-center">
    <p style="margin-top: 10px">
      <small>
        <a [routerLink]="[]" data-toggle="modal" data-target="#getCognitoSamlProviderName" >Login to Okta</a> |
        <a href='{{dontHaveAccountLink}}'>Don't have an account?</a> |
        <a routerLink="/forgot-pw">Problems signing in?</a>
      </small>
    </p>
  </div>

  <div class="modal fade" id="getCognitoSamlProviderName" tabindex="-1" role="dialog"
       aria-labelledby="getCognitoSamlProviderName">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <form>
            <div class="form-group">
              <flash-messages></flash-messages>
            </div>
            <div class="form-group mt-4">
              <label for="cognitoSamlProviderName" class="control-label">Slack workspace name:</label>
              <input type="text" class="form-control"
                     id="cognitoSamlProviderName"
                     placeholder="Slack Workspace Name"
                     name="cognitoSamlProviderName">
            </div>
            <div class="form-group mt-3"></div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button"
                  (click)="clearCognitoSamlProviderName()"
                  class="btn btn-default"
                  data-dismiss="modal">
            Cancel
          </button>
          <button type="button"
                  (click)="onOktaLogin()"
                  class="btn btn-success">
            Login with Okta
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
