<div class="wrapper">
  <div class="col">
    <div class="row">
      <div class="col">
        <div>
          <div class="col">
            <!-- Start Form -->
            <form class="form-signin">
              <h2 class="form-signin-heading d-flex justify-content-center">Verify Your Login Credentials</h2>
              <hr>
              <div class="form-group">
                <label style="font-family: roboto,sans-serif">
                  To reinstall the app in your company’s Slack workspace, please verify your Please Share login credentials.
                </label>
              </div>
              <div class="form-group">
                <flash-messages></flash-messages>
              </div>
              <div class="form-group">
                <label for="email" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
                <input type="email" [(ngModel)]="email" name="email" required class="form-control" id="email">
              </div>
              <div class="form-group">
                <label for="pwd" style="font-family: roboto,sans-serif">PASSWORD</label>
                <input type="password" autocomplete="false" [(ngModel)]="password" name="password" required
                       class="form-control"
                       id="pwd">
              </div>
              <br/>
              <div class="text-center">
                <button type="button" class="btn btn-secondary reg-button" (click)="backToHome()">Cancel</button>
                <button type="button" class="btn btn-post reg-button"
                        (click)="reInstallWithVerifyingLoginCredentials()">Verify & Reinstall
                </button>
              </div>
            </form>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="reInstallSuccessDialog" tabindex="-1" role="dialog"
       aria-labelledby="reInstallSuccessDialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
            You have successfully reinstalled the Please Share app. To access your account, close this window and sign in on the next screen.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary cancel-subscription-plan-btn-no" data-dismiss="modal" (click)="logout()">OK</button>
        </div>
      </div>
    </div>

  </div>
</div>
