import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {PasswordValidator} from "../register/PasswordValidator";
import {UserLoginService} from "../service/user-login.service";
import {FlashMessagesService} from "angular2-flash-messages";

declare var $: any;

@Component({
    selector: 'app-reactivate-invited-admin',
    templateUrl: './reactivate-invited-admin.component.html',
    styleUrls: ['./reactivate-invited-admin.component.scss']
})
export class ReactivateInvitedAdminComponent implements OnInit {

    public email: string;
    public name: string;
    public password: string;
    public cpassword: string;
    public token: string;
    private sub: any;
    private activatedRoute: ActivatedRoute;


    constructor(activatedRoute: ActivatedRoute,
                private http: HttpClient,
                private router: Router,
                private auth: UserLoginService,
                private _flashMessagesService: FlashMessagesService) {
        this.activatedRoute = activatedRoute;

    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.token = params['token'];
        });

        this.getCustomerDetailsByToken(this.token);
    }

    private getCustomerDetailsByToken(token: string): any {
        const myThis = this;
        const signupUserDetailsUrl = `${environment.apiRoot}/user/get-signup-user-details`;
        myThis.http.post(signupUserDetailsUrl, {token: token, isInvitedByAnotherAdmin: true})
            .subscribe(
                (response: any) => {
                    response.errors = response.errors || null;
                    if (response.success === true && response.errors === null) {
                        const data = response.data;
                        myThis.email = data.email;
                        myThis.name = data.name;
                    } else {
                        myThis.flashMessage('Invalid token provided.', true);
                    }
                },
                (error) => {
                    myThis.flashMessage(error.errorMessage || error.error || 'Something weird happened, please try again.', true);
                }
            );
    }

    public doResetPassword(): any {
        const myThis = this;
        const url = `${environment.apiRoot}/admins/invited-admin-reset-password`;
        if (this.cpassword !== this.password && !PasswordValidator.isValid(this.password)) {
            this.flashMessage('Password must contains at least one small letter, one capital letter, one special character, one number and at least 8 characters long', true);
            return;
        }
        myThis.http.post(url, {token: this.token, password: this.password, confirmPassword: this.cpassword})
            .subscribe(
                (data: any) => {
                    data.errors = data.errors || null;
                    if (data.success === true && data.errors === null) {
                        myThis.flashMessage("Your Please Share account reactivated successfully.", false);
                        $("#reactivateAdminSubmitButton").attr("disabled", 'disabled');
                        setTimeout(function () {
                            myThis.auth.logout();
                            myThis.router.navigate(['/login']);
                        }, 5000);
                    } else {
                        let errorMessage = '';
                        data.errors?.forEach(function (e, index) {
                            errorMessage += e.message;
                            if (e[index + 1]) {
                                errorMessage += '<br />';
                            }
                            myThis.flashMessage(errorMessage, true);
                        });
                    }
                },
                (error) => {
                    let errorMessage = '';
                    error.errors?.error.errors.forEach(function (e, index) {
                        errorMessage += e.message;
                        if (e[index + 1]) {
                            errorMessage += '<br />';
                        }
                    });
                    errorMessage = errorMessage || 'Something weird happened, please try again.';
                    myThis.flashMessage(errorMessage, true);
                }
            );

    }

    public reload(): any {
        window.location.reload();
    }

    flashMessage(msg: string, error: boolean) {
        if (error) {
            this._flashMessagesService
                .show(msg, {
                    cssClass: 'alert-danger',
                    timeout: 5000
                });
        } else {
            this._flashMessagesService
                .show(msg, {
                    cssClass: 'alert-success',
                    timeout: 5000
                });
        }
    }
}
