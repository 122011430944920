import {Component, Inject, OnInit} from '@angular/core';
import {Spinkit} from 'ng-http-loader';
import {UserLoginService} from './service/user-login.service';
import {CognitoUtil, LoggedInCallback} from './service/cognito.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';


import {NavbarService} from './service/navbar.service';
import {UserIdleService} from 'angular-user-idle';
import {DOCUMENT} from '@angular/common';
import * as jwt_decode from 'jwt-decode';
import {GoogleAnalyticsService} from './service/google.analytics.service';
import {Intercom} from 'ng-intercom';
import {filter, map, mergeMap} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, LoggedInCallback {

  constructor(public nav: NavbarService,
              public auth: UserLoginService,
              public cognitoUtil: CognitoUtil,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private router: Router,
              private userIdle: UserIdleService,
              @Inject(DOCUMENT) private doc: any,
              private googleAnalyticService: GoogleAnalyticsService,
              private intercom: Intercom) {

  }

  public spinkit = Spinkit;
  public isAuthenticated = false;
  public isUserSetupPage = false;
  public loggedInUserEmail: string;
  public loggedInUserName: string;
  public loggedInUserRegistrationDate: string;
  public intercomAppId: string;
  public isBetaPeriodRunning = false;

  title = 'Please Share';

  static isUrlPubliclyAccessible(publicUrlPath: string[], href: string) {
    for (const i in publicUrlPath) {
      if (href.indexOf(publicUrlPath[i]) >= 0) {
        return true;
      }
    }
    return false;
  }

  private static getDecodedJwtToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  ngOnInit() {
    this.googleAnalyticService.init();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .pipe(
        map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })).pipe(
      filter((route) => route.outlet === 'primary'))
      .pipe(
        mergeMap((route) => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event['title']);
        this.isBetaPeriodRunning = event['betaPeriodRunning'];
      });


    const locationHref = window.location.href;
    this.isUserSetupPage = locationHref.indexOf('user/setup/') >= 0;

    // If the url is not publicly accessible then authenticate
    if (!AppComponent.isUrlPubliclyAccessible(environment.publicUrlPath, window.location.href)) {
      this.auth.isAuthenticated(this);
    }

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    const myThis = this;
    if (!isLoggedIn) {
      this.isAuthenticated = false;
      this.auth.logout();
    } else {
      this.isAuthenticated = true;
      this.cognitoUtil.getIdAndAccessTokenToken({
        callback() {},
        callbackWithParam(idToken: any) {},
        callbackWithParams(idToken: any, accessToken: any) {
          // Include the passed-in callback here as well so that it's executed downstream
          myThis.startWatchingLoggedInUserInactivity();

          if (environment.INTERCOM_ENABLED) {
            myThis.initializeIntercomSetting(idToken);
          }
        }
      });
    }
  }

  private initializeIntercomSetting(idToken: string) {
    const decodedPayload = AppComponent.getDecodedJwtToken(idToken);
    this.loggedInUserEmail = decodedPayload['email'];
    this.loggedInUserName = decodedPayload['given_name'] + ' ' + decodedPayload['family_name'];
    this.loggedInUserRegistrationDate = new Date().getTime().toString();
    this.intercomAppId = environment.INTERCOM_APP_ID;
    this.setIntercomManager();
  }

  private setIntercomManager() {
    this.intercom.boot({
      app_id: environment.INTERCOM_APP_ID,
      name: this.loggedInUserName,
      email: this.loggedInUserEmail,
      created_at: this.loggedInUserRegistrationDate
    });
  }

  private startWatchingLoggedInUserInactivity() {
    const myThis = this;
    // Start watching for user inactivity.
    myThis.userIdle.startWatching();

    // Start watching when user idle is starting.
    myThis.userIdle.onTimerStart().subscribe(count => {
      console.log('onTimerStart()');
    });

    // Start watch when time is up.
    myThis.userIdle.onTimeout().subscribe(function () {
      console.log('onTimeout()');
      myThis.userIdle.stopWatching();
      myThis.auth.logout();
      // Hide all other existing modals if there is any
      // $('.modal').modal('hide');
      // Show session timeout modal
      $('#session-logout-modal').modal({
        backdrop: 'static',
        show: true,
        keyboard: false
      });
    });
  }
}
