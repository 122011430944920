import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserLoginService} from '../service/user-login.service';
import {UserRegistrationService} from '../service/user-registration.service';
import {CognitoCallback, CognitoUtil} from '../service/cognito.service';
import {NewPasswordUser} from '../public/auth/newpassword/newpassword.component';

@Component({
  templateUrl: './invite-user-change-password.component.html',
  styleUrls: ['./invite-user-change-password.component.scss']
})
export class InviteUserChangePasswordComponent implements OnInit, CognitoCallback {

  public oldPassword: string;
  public newPassword: string;
  public confirmPassword: string;
  public email: string;
  public errorMessage: string;
  private sub: any;
  private newPasswordUser: NewPasswordUser;

  constructor(public regService: UserRegistrationService, public userLogInService: UserLoginService, public router: Router, public activatedRoute: ActivatedRoute, public cognitoUtil: CognitoUtil) {
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe((params: Params) => {
      this.email = params['username'];
      this.oldPassword = params['oldpassword'];

      this.newPasswordUser = new NewPasswordUser();
      this.newPasswordUser.existingPassword = this.oldPassword;
      this.newPasswordUser.username = this.email;

    });

    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onChangePassword() {
    this.newPasswordUser.password = this.confirmPassword;
    this.regService.newPassword(this.newPasswordUser, this)
  }

  cognitoCallback(errorMessage: string, result: any) {
    if (errorMessage === null) {
      window.location.href = '/';
      // this.router.navigate(['/home'], {skipLocationChange: false});
    } else {
      alert('Something weird happened: ' + errorMessage)
    }

  }
}
