import {Component, OnInit} from '@angular/core';
import {UserProfileService} from '../service/user-profile.service';
import {UserProfile} from '../users/UserProfile';
import {UserLoginService} from '../service/user-login.service';

import {Router} from '@angular/router';
import {NavbarService} from '../service/navbar.service';
import {environment} from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public currentUserProfile: UserProfile;
  public intercomProductTourId;

  constructor(private userProfileService: UserProfileService, private userLoginService: UserLoginService, private router: Router,
              public nav: NavbarService) {
    this.intercomProductTourId = environment.intercomProductTourId;
  }

  ngOnInit() {
    const myThis = this;
    this.userProfileService.loggedInUserProfile.subscribe((profile: any) => {
      myThis.currentUserProfile = profile as UserProfile;
    });
  }

  logout() {
    this.nav.hide();
    $('#session-logout-modal').modal('hide');
    this.currentUserProfile = undefined;
    this.userLoginService.logout();
    this.router.navigate(['/login']);
  }
}
