import {Inject, Injectable} from '@angular/core';
import {CognitoCallback, CognitoUtil} from './cognito.service';
import {InviteUser} from '../invite-user/invite-user.component';

declare var AWS: any;

@Injectable()
export class InviteUserService {

  constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

  }

  invite(user: InviteUser, callback: CognitoCallback): void {

    if (!user.email || !user.firstName || !user.lastName) {
      alert('Please fill in all required fields');
      return;
    }
    if (callback == null) {
      throw('CognitoUtil: callback in admincreateUser is null...returning');
    }

    let myCredentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID});
    AWS.config.update({credentials: myCredentials, region: CognitoUtil._REGION});

    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    let params = {
      UserPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
      Username: user.lastName, /* required . Must be unique*/
      DesiredDeliveryMediums: [
        'EMAIL',
        /* more items */
      ],
      ForceAliasCreation: false,
      MessageAction: 'RESEND',
      TemporaryPassword: '123456',
      UserAttributes: [
        {
          Name: 'companyName', /* required */
          Value: user.companyName
        },
        /* more items */
      ],
      ValidationData: []
    };
    cognitoidentityserviceprovider.adminCreateUser(params, function (err, data) {
      if (err) {
        callback.cognitoCallback('Error while creating user', err);
      } // an error occurred
      else {
        callback.cognitoCallback(null, data);
      }           // successful response
    });

  }
}
