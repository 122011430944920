<div class="wrapper">
  <form class="form-signin">
    <h2 *ngIf="messageType === 'private_channel_install_issue'"
        class="form-signin-heading d-flex justify-content-center">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Private Channel Installation Issue
    </h2>

    <h2 *ngIf="messageType === 'invalid_slack_verification'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Please Share Installation Unsuccessful
    </h2>
    <h2 *ngIf="messageType === 'unknown_install_exception'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 25px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Please Share Installation Not Successful
    </h2>
    <h2 *ngIf="messageType === 'install_persistence_exception'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 25px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Please Share Installation Not Successful
    </h2>

    <h2 *ngIf="messageType === 'unknown_slack_userinfo_install'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Please Share Installation Issue
    </h2>
    <h2 *ngIf="messageType === 'li_user_authenticated_issue'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      LinkedIn Connection Issue
      There is an issue authenticating your LinkedIn account.
    </h2>
    <h2 *ngIf="messageType === 'li_feed_engagement_authorize_issue'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      There is an issue connecting your LinkedIn feed.
    </h2>
    <h2 *ngIf="messageType === 'tw_user_authenticated_issue'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      There is an issue connecting your Twitter account.
    </h2>
    <h2 *ngIf="messageType === 'tw_feed_engagement_authorize_issue'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      There is an issue connecting your Twitter feed.
    </h2>
    <h2 *ngIf="messageType === 'tw_feed_engagement_authorize_issue'"
        class="form-signin-heading d-flex justify-content-center" style="font-size: 27px">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Issue Connecting Your Twitter Feed
    </h2>

    <h2 *ngIf="isUnknownMessageType(messageType)" class="form-signin-heading d-flex justify-content-center">
      <i class="fa fa-exclamation-circle fa-fw" style="color:#8B0000;"></i>
      Please Share Interaction Issue</h2>

    <hr>
    <div *ngIf="messageType === 'private_channel_install_issue'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        It appears you attempted to install Please Share in a "private" channel in your company's Slack workspace.
        Our service enables this functionality, but given that additional permissions are required, it is not the
        default way to install the Please Share app.
        Read more <a
        href="https://faq.pleaseshare.co/getting-started/how-to-add-please-share-to-a-private-channel-in-slack">here</a>.
        <br><br>
        If you would like the alternative installation link or just need some assistance getting started, email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a>(code PSE00011) and we will be glad to help.
      </p>
    </div>
    <div *ngIf="messageType === 'invalid_slack_verification'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        We did not receive the proper verification code from Slack when attempting to install Please Share in your
        company’s workspace.
        Typically, this is a result of a time-out issue or a temporary interruption in Slack’s service.
        <br><br>
        If you email <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00012, we will help you
        resolve this issue.
      </p>
    </div>
    <div *ngIf="messageType === 'unknown_install_exception'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        We were not able to install Please Share in your company's Slack workspace.
        This happens occasionally and could be the result of a number of issues.

        <br><br>
        If you email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00013, we will be happy to
        help troubleshoot this issue.
      </p>
    </div>
    <div *ngIf="messageType === 'install_persistence_exception'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        We were not able to install Please Share in your company's Slack workspace.
        This happens occasionally and could be the result of a number of issues.
        <br><br>
        If you email us at <a href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code
        PSE00014,
        we will be happy to help troubleshoot this issue.
      </p>
    </div>
    <div *ngIf="messageType === 'unknown_slack_userinfo_install'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        We were not able to install Please Share in your company's Slack workspace. This happens occasionally and could
        be the result of a number of issues.
        <br><br>
        If you email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00015, we will be happy to
        help troubleshoot this issue.
      </p>
    </div>
    <div *ngIf="messageType === 'li_user_authenticated_issue'" class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        An issue occurred when attempting to connect your LinkedIn account with Please Share. We recommend trying again
        in a few minutes.
        <br><br>
        If this issue persists, however, please email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00016 so we can help
        resolve
        this for you.
      </p>
    </div>
    <div *ngIf="messageType === 'li_feed_engagement_authorize_issue'"
         class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        An issue occurred when attempting to add a LinkedIn feed to your Please Share account. We recommend trying again
        in a few minutes.
        <br><br>
        If this issue persists, however, please email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00017 so we can help
        resolve
        this for you.
      </p>
    </div>
    <div *ngIf="messageType === 'tw_user_authenticated_issue'"
         class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        An issue occurred when attempting to connect your Twitter account with Please Share. We recommend trying again
        in
        a few minutes.
        <br><br>
        If this issue persists, however, please email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00018 so we can help
        resolve
        this for you.
      </p>
    </div>
    <div *ngIf="messageType === 'tw_feed_engagement_authorize_issue'"
         class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        An issue occurred when attempting to add a Twitter feed to your Please Share account. We recommend trying again
        in a few minutes.
        <br><br>
        If this issue persists, however, please email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00019 so we can help
        resolve
        this for you.
      </p>
    </div>
    <div *ngIf="messageType === 'tw_feed_engagement_authorize_issue'"
         class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        An issue occurred when attempting to add a Twitter feed to your Please Share account. We recommend trying again in a few minutes.
        <br><br>
        If this issue persists, however, please email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a> and reference code PSE00019 so we can help  resolve this for you.
      </p>
    </div>
    <div *ngIf="isUnknownMessageType(messageType)"
         class="login-help d-flex justify-content-center">
      <p style="margin-top: 20px">
        We are sorry, but an unrecognized issue occurred with the Please Share service. This can happen on occasion –
        hey, we’re only human.
        <br><br>
        If you email us at <a
        href="mailto:support@pleaseshare.co">support@pleaseshare.co</a>, reference code PSE001001 and provide a few
        details
        about what action(s) you recently tried to complete, we will be happy to assist you.
      </p>
    </div>
  </form>
</div>
