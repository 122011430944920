import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class AccountAdminsSettingsService {

    constructor(private http: HttpClient) {
    }

    doGetAllAccountAdminsOfLoggedInUser() {
        let myThis = this;

        const url = `${environment.apiRoot}/admins`;
        return new Promise((resolve, reject) => {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders
                .set('Content-Type', 'application/json');
            myThis.http.get(url, {headers: httpHeaders})
                .subscribe(
                    (data: any) => resolve(data.data),
                    (error) => reject(error)
                );
        });
    }

    doInviteNewAdminsWithEmails(emails: string[]) {
        let myThis = this;
        emails.map(s => s.trim());
        const url = `${environment.apiRoot}/admins/invite`;
        return new Promise((resolve, reject) => {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders
                .set('Content-Type', 'application/json');
            myThis.http.post(url, {'emails': emails}, {headers: httpHeaders})
                .subscribe(
                    (data: any) => resolve(data.data || []),
                    (error) => reject(error)
                );
        });
    }

  doAdminStateChange(adminId: string, state: string) {
    let myThis = this;
    // state=reactivate or deactivate
    const url = `${environment.apiRoot}/admins/${state}?adminCognitoUserId=${adminId}`;
    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders
        .set('Content-Type', 'application/json');
      myThis.http.put(url, null, {headers: httpHeaders})
        .subscribe(
          (data: any) => resolve(data),
          (error) => reject(error)
        );
    });
  }

    doToggleNotificationReceiveStatus(adminCognitoUserId: string, status: boolean) {
        let myThis = this;

        const url = `${environment.apiRoot}/admins/toggle-notification-receive-status?adminCognitoUserId=${adminCognitoUserId}&status=${status}`;
        return new Promise((resolve, reject) => {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders
                .set('Content-Type', 'application/json');
            myThis.http.put(url, null, {headers: httpHeaders})
                .subscribe(
                    (data: any) => resolve(data.success || false),
                    (error) => reject(error)
                );
        });
    }
}
