<div *ngIf="(!userBelongsToGroup || userAccountStatus !== 'ENABLED')" class="alert add_app">
  <div class="row">
    <div class="col-md-9" [innerHTML]="slackAppNotificationMsg | keepHtml"></div>
    <div class="col-md-3">
      <a target="_blank" href="{{slackAppDownloadLink}}">
        <img src="../../assets/images/btn-add-to-slack.svg" style="height: 35px;">
      </a>
    </div>
  </div>
</div>

<!-- Begin Main Content Area -->

<div *ngIf="userBelongsToGroup == true && userAccountStatus === 'ENABLED'" class="container-fluid"
     style="padding: 0 40px 0 40px;">
  <div class="row page-titles d-flex justify-content-end" style="padding: 20px 0 20px 0;">
    <!-- Begin Page Title -->
    <div class="col-md-8 col-8 align-self-center">
      <h2>STATISTICS DASHBOARD</h2>
    </div>
    <div class="col-md-4 col-8 float-sm-right text-right">
      <button class="btn btn-secondary dropdown-toggle" data-toggle="collapse"
              href="#collapseExample"
              role="button" aria-expanded="false" aria-controls="collapseExample" type="submit">Download Statistics
      </button>
      <button class="btn btn-post val-button" (click)="onRefreshStatistics()" type="submit">Refresh</button>
    </div>
    <div class="row collapse col-md-3" id="collapseExample">
      <div class="d-flex align-items-end col-md-11">
        <div class="col-md-4 dropdown pull-right m-r-10 hidden-sm-down">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="downloadAsCsvFromDate"
                  data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
            {{downloadAsCsvSelectedFromYear}}
          </button>
          <div class="dropdown-menu" aria-labelledby="downloadAsCsvFromDate">
            <a *ngFor="let _year of yearsList"
               (click)="changeDownloadAsCsvSelectedFromDateValue(_year)" class="dropdown-item"
               href="javascript:void(0)">{{_year}}</a>
          </div>
        </div>
        <div class="col-md-4 dropdown pull-right pl-3 hidden-sm-down">
          <button class="btn btn-post" type="button" (click)="onDownloadStatsAsCSVButtonClick()">Download</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Start Page Content -->

  <!-- Begin Summary Row -->
  <div class="row" *ngFor="let _shareClick of shareClick$| async">
    <div class="col-lg-6">
      <div class="card card_stats_yr">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large media-stats-yr">{{updatePostedThisYear$ | async}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-white text-small">"SHARE" REQUESTS THIS YEAR</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/post_green.png" width="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card card_stats_yr">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span
              class="text-large media-stats-yr">{{(earnedMediaThisYear$ | async) | number:'1.2-2'}} <i
              class="text-small ti-angle-down font-10 text-white">{{(earnedMediaCurrency$ | async) | replaceCurrency}}</i></span>
              <div class="text-white text-small">EARNED MEDIA THIS YEAR</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/results_green.png" width="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Summary Row -->
  <!-- MONTHLY STATS HEADER -->
  <div class="row page-titles" style="padding: 20px 0 20px 0;">
    <!-- Begin Page Title -->
    <div class="col-md-6 col-8-push align-self-center">
      <h4>MONTHLY SHARE STATISTICS</h4>
    </div>
    <!-- End Page Title -->
    <!-- Begin History Dropdown -->
    <div class="col-md-6 col-4-pull align-self-center">
      <div class="dropdown pull-right m-r-10 hidden-sm-down">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownShareMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
          {{selectedMonth| date:'MMM yyyy'}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownShareMenuButton">
          <a *ngFor="let _date of last1YearMonthsList" class="dropdown-item" href="javascript:void(0)"
             (click)="loadHistoriesWithCustomDate(_date.toISOString())">{{_date | date:'MMM yyyy'}}</a>
        </div>
      </div>
    </div>
    <!-- End History Dropdown -->
  </div>
  <!-- MONTHLY STATS HEADER END -->

  <!-- Begin Summary Row -->
  <div class="row" *ngFor="let _shareClick of shareClick$ | async">
    <div class="col-lg-6">
      <div class="card card_stats_mo">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span
              class="text-large media-stats-mo">{{(updatePostedThisMonth$ | async)}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-white text-small">SHARE REQUESTS POSTED THIS MONTH</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/post_blue.png" width="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card card_stats_mo">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span
              class="text-large media-stats-mo">{{((monthlyclicks$| async) * (earnedMediaClickValue$ | async)) | number:'1.2-2'}}
              <i
                class="text-small ti-angle-down font-10 text-white">{{(earnedMediaCurrency$ | async)| replaceCurrency}}</i></span>
              <div class="text-white text-small">EARNED MEDIA THIS MONTH</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/results_blue.png" width="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Summary Row -->
  <!-- Begin Summary Row -->
  <div class="row" *ngFor="let _shareClick of shareClick$ | async">
    <!-- Column -->
    <div class="col-lg-6">
      <div class="card stats-box">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large click-stats">{{monthlyShares$ | async}}<i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-muted text-small">MONTHLY SHARES</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/share_lblue.png" width="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-6">
      <div class="card stats-box">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large click-stats">{{monthlyclicks$ | async}}<i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-muted text-small">MONTHLY CLICKS</div>
            </div>
            <div class="col-3 align-self-center text-right p-l-0">
              <div><img class="stats_icon" src="../../assets/images/click_lblue.png" width="75" height="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let _shareClick of shareClick$ | async">
    <!-- Column -->
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="tw-stats-header border-0 pb-0 top-rounded text-md-center"><i
          class="fa fa-twitter fa-2x white"></i></div>
        <div class="card-body text-center text-success text-xlarge py-3">
          <div class="row social-stats-row">
            <div class="col-6 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.shareButtonClickedInTW}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-share-alt icon-padding lt-gray"></i>SHARES
              </h5>
            </div>
            <div class="col-6 stats-pd">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.sharedUrlClickedInTW}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-mouse-pointer lt-gray icon-padding"></i>CLICKS
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="fb-stats-header border-0 pb-0 top-rounded text-md-center"><i
          class="fa fa-facebook fa-2x white"></i></div>
        <div class="card-body text-center text-success text-xlarge py-3">
          <div class="row social-stats-row">
            <div class="col-6 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.shareButtonClickedInFB}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-share-alt icon-padding lt-gray"></i>SHARES
              </h5>
            </div>
            <div class="col-6 stats-pd">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.sharedUrlClickedInFB}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-mouse-pointer lt-gray icon-padding"></i>CLICKS
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="li-stats-header border-0 pb-0 top-rounded text-md-center"><i
          class="fa fa-linkedin fa-2x white"></i></div>
        <div class="card-body text-center text-success text-xlarge py-3">
          <div class="row social-stats-row">
            <div class="col-6 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.shareButtonClickedInLI}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-share-alt icon-padding lt-gray"></i>SHARES
              </h5>
            </div>
            <div class="col-6 stats-pd">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_shareClick.sharedUrlClickedInLI}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-mouse-pointer lt-gray icon-padding"></i>CLICKS
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Social Summary Row -->

  <!-- MONTHLY STATS HEADER -->
  <div class="row page-titles" style="padding: 20px 0 20px 0;">
    <!-- Begin Page Title -->
    <div class="col-md-6 col-8-push align-self-center">
      <h4>MONTHLY ENGAGEMENT STATISTICS</h4>
    </div>
  </div>
  <!-- MONTHLY STATS HEADER END -->

  <!-- Begin Summary Row -->
  <div class="row" *ngFor="let _feedsEngagementStats of feedsEngagementStats$ | async">
    <div class="col-lg-6">
      <div class="card card_stats_mo">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span
              class="text-large media-stats-mo">{{_feedsEngagementStats.numberOfFetchedLiPosts}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-white text-small">LINKEDIN POSTS FETCHED THIS MONTH</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/li_dblue.svg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card card_stats_mo">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span
              class="text-large media-stats-mo">{{_feedsEngagementStats.numberOfFetchedTwPosts}}</span>
              <div class="text-white text-small">TWEETS FETCHED THIS MONTH</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/tw_dblue.svg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Summary Row -->
  <!-- Begin Summary Row -->
  <div class="row" *ngFor="let _feedsEngagementStats of feedsEngagementStats$ | async">
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large click-stats">{{_feedsEngagementStats.numberOfMonthlyLikes}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-muted text-small">MONTHLY LIKES</div>
            </div>
            <div class="col-3 align-self-center text-right  p-l-0">
              <div><img class="stats_icon" src="../../assets/images/like_lblue.png"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large click-stats">{{_feedsEngagementStats.numberOfMonthlyReShares}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-muted text-small">MONTHLY RESHARES</div>
            </div>
            <div class="col-3 align-self-center text-right p-l-0">
              <div><img class="stats_icon" src="../../assets/images/share_lblue.png" width="25" height="25"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-4">
      <div class="card stats-box">
        <div class="card-body">
          <!-- Row -->
          <div class="row">
            <div class="col-9"><span class="text-large click-stats">{{_feedsEngagementStats.numberOfMonthlyComments}} <i
              class="ti-angle-down font-14 text-danger"></i></span>
              <div class="text-muted text-small">MONTHLY COMMENTS</div>
            </div>
            <div class="col-3 align-self-center text-right p-l-0">
              <div><img class="stats_icon" src="../../assets/images/comment_lblue.png" width="75" height="75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let _feedsEngagementStats of feedsEngagementStats$ | async">
    <!-- Column -->
    <div class="col-lg-6">
      <div class="card stats-box">
        <div class="li-stats-header border-0 pb-0 top-rounded text-md-center"><i class="fa fa-linkedin fa-2x white"></i>
        </div>
        <div class="card-body text-center text-success text-xlarge py-3">
          <div class="row social-stats-row">
            <div class="col-4 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfLiLikes}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-thumbs-up icon-padding lt-gray"></i>LIKES
              </h5>
            </div>
            <div class="col-4 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfLiShares}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-share-alt lt-gray icon-padding"></i>SHARES
              </h5>
            </div>
            <div class="col-4 stats-pd">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfLiComments}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-comment lt-gray icon-padding"></i>COMMENTS
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-lg-6">
      <div class="card stats-box">
        <div class="tw-stats-header border-0 pb-0 top-rounded text-md-center"><i class="fa fa-twitter fa-2x white"></i>
        </div>
        <div class="card-body text-center text-success text-xlarge py-3">
          <div class="row social-stats-row">
            <div class="col-4 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfTwLikes}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-thumbs-up icon-padding lt-gray"></i>LIKES
              </h5>
            </div>
            <div class="col-4 vl2">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfTwReTweets}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-share-alt lt-gray icon-padding"></i>RETWEETS
              </h5>
            </div>
            <div class="col-4 stats-pd">
              <h3 class="font-medium text-center txt-lg-social-stats">{{_feedsEngagementStats.numberOfTwReplies}}</h3>
              <h5 class="text-muted text-center text-small"><i class="fa fa-comment lt-gray icon-padding"></i>REPLIES
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Social Summary Row -->
    <!-- End Page Content -->
  </div>
  <!-- End Container fluid  -->
