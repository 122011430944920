import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavbarService} from "../service/navbar.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {FeedSource} from "./FeedSource";
import {Observable, of} from "rxjs";
import {WorkspaceChannel} from "./WorkspaceChannel";
import {FlashMessagesService} from "angular2-flash-messages";
import {UserProfileService} from "../service/user-profile.service";
import {UserProfile} from "../users/UserProfile";
import {ActivatedRoute, Params} from "@angular/router";
import {UserLoginService} from "../service/user-login.service";
import {CognitoUtil, LoggedInCallback} from "../service/cognito.service";
import {TzInfo} from "../service/dtos/tz.info.dto";
import {TimezoneService} from "../service/timezone.service";

declare var $: any;

@Component({
  selector: '[id=content-wrapper]',
  templateUrl: './feeds.component.html',
  styleUrls: ['./feeds.component.scss', './switch.scss', '../../scss/ng-autocomplete-custom.scss', '../../scss/tooltip-custom.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class FeedsComponent implements OnInit, LoggedInCallback {
  public readonly INVALID_LOADING_TYPE: number = -1;
  public readonly POST_TO_CHANNEL_LOADING_TYPE: number = 1;
  public readonly TW_POST_TO_CHANNEL_LOADING_TYPE: number = 2;
  public readonly LI_POST_TO_CHANNEL_LOADING_TYPE: number = 3;

  public tzInfo$: Observable<TzInfo>;
  public linkedInAuthorizedForFeedSource: boolean;
  public twitterAuthorizedForFeedSource: boolean;
  public feedSourceAccountName: string;
  public feedPostToChannelId: string;
  public feedPostToChannelName: string;
  public deletingFeedSourceType: string;
  public deletingFeedSourceAccountName: string;
  public errorMessage: string;
  public feedSources$: Observable<FeedSource[]>;
  public collaborationPlatformName$: string;
  public workspaceChannels$: Observable<WorkspaceChannel[]>;
  public currentUserProfile: UserProfile;
  public userBelongsToGroup: boolean;
  public userAccountStatus: string;
  private sub: any;
  private querySub: any;
  public feedAuthorizationSuccess: string;
  private tokenRefresh: any;
  public automaticallyDeleteAfterDays = [-1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  public searchKeyword: string = "name";
  public workspaceChannelSearchPlaceholder: string = "Search for a channel...";
  public autoCompleteSearchIsLoadingType: number;

  public constructor(private nav: NavbarService,
                     private http: HttpClient,
                     private _flashMessagesService: FlashMessagesService,
                     private userProfileService: UserProfileService,
                     private activatedRoute: ActivatedRoute,
                     private timezoneService: TimezoneService,
                     private auth: UserLoginService,
                     private cognitoUtil: CognitoUtil) {
    this.errorMessage = "";
    this.autoCompleteSearchIsLoadingType = this.INVALID_LOADING_TYPE;
    this.auth.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.auth.logout();
      window.location.href = '/#/login';
    } else {
      let userName = this.cognitoUtil.getCurrentUser().getUsername();
      let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
      this.tzInfo$ = this.timezoneService.getTzInfo(profile.tzName);
      this.userProfileService.changeProfile(profile);
    }
  }

  ngOnInit() {
    // If navbar component is hidden, show it
    setTimeout(() => {
      this.nav.show();
    }, 0);

    const myThis = this;
    this.querySub = this.activatedRoute.queryParams.subscribe((params: Params) => {
      myThis.tokenRefresh = params['tokenRefresh'];
    });

    myThis.sub = this.activatedRoute.params.subscribe(params => {
      myThis.feedAuthorizationSuccess = params['feed-authorization-success'];
    });

    this.userProfileService.loggedInUserProfile.subscribe(profile => {
      this.currentUserProfile = profile;

      if (!profile.hasGroup) {
        this.userBelongsToGroup = false;
      } else {
        this.userBelongsToGroup = this.currentUserProfile.hasGroup;
      }
      if (this.userBelongsToGroup) {
        this.userAccountStatus = profile.accountStatus;
      }
    });

    if (this.userBelongsToGroup && this.userAccountStatus === 'ENABLED') {
      this.populateRequiredFeedSourcesByLoggedInAccountId();
    }
  }

  ngAfterViewInit() {
    if (this.tokenRefresh == null) { // For token refresh case ignore modal opening
      if ('twitter-feed-authorization-success' == this.feedAuthorizationSuccess) {
        this.openSocialSourceDetailedModal('addTwitterFeedDetail');
      }

      if ('linkedin-feed-authorization-success' == this.feedAuthorizationSuccess) {
        this.openSocialSourceDetailedModal('addLinkedInFeedDetail');
      }
    } else {
      this.modalHideAndRedirect();
    }
  }

  private populateRequiredFeedSourcesByLoggedInAccountId() {
    const myThis = this;
    const url = `${environment.apiRoot}/is-feed-source-authorized`;
    myThis.http.get(url)
      .subscribe(
        (response: any) => {
          if (!response.success) {
            myThis.errorMessage = "";

            if (response.errors) {
              response.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }

            return;
          }

          const data = response.data;
          myThis.linkedInAuthorizedForFeedSource = data.isLinkedInAuthorized;
          myThis.twitterAuthorizedForFeedSource = data.isTwitterAuthorized;
          myThis.fetchFeedSourcesByLoggedInAccountId();
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  private fetchFeedSourcesByLoggedInAccountId() {
    const myThis = this;
    const url = `${environment.apiRoot}/get-feed-sources`;
    const httpHeaders = new HttpHeaders()
      .set('Content-type', 'application/json');
    myThis.http.get(url, {headers: httpHeaders})
      .subscribe(
        (response: any) => {
          const data = response.data;
          myThis.feedSources$ = of(data.feedSources || []);
          myThis.clearWorkspaceChannels();
          myThis.collaborationPlatformName$ = data.collaborationPlatformName;
        }
      );
  }

  onChangeSearch(prefix: string, loadingType: number) {
    let myThis = this;
    myThis.autoCompleteSearchIsLoadingType = loadingType;
    const url = `${environment.apiRoot}/c-platform/list-channels?prefix=${prefix}`;
    const httpHeaders = new HttpHeaders()
      .set('Content-type', 'application/json');
    myThis.http.get(url, {headers: httpHeaders})
      .subscribe(
        (data: any) => {
          myThis.workspaceChannels$ = of(data.data);
          myThis.autoCompleteSearchIsLoadingType = myThis.INVALID_LOADING_TYPE;
        }
      );
  }

  doChangeWorkspaceChannel(event: WorkspaceChannel, curFeedSource: FeedSource) {
    if (curFeedSource.feedPostToChannelName === event.name) return;

    $(`<div class="modal fade" id="updateCollaborationPlatformChannelName" tabindex="-1" role="dialog" aria-labelledby="updateCollaborationPlatformChannelName" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-body">
      Are you sure you want to change feed engagement channel?
      </div>
      <div class="modal-footer" style="border-top: 0">
      <button type="button" class="btn btn-secondary update-slack-channel-btn-no" data-dismiss="modal">No</button>
      <button type="button" class="btn btn-success update-slack-channel-btn-yes">Yes</button>
      </div>
      </div>
      </div>
      </div>`).appendTo('body');

    //Trigger the modal
    let updateCollaborationPlatformChannelNameSelector = "#updateCollaborationPlatformChannelName";
    $(updateCollaborationPlatformChannelNameSelector).modal({
      backdrop: 'static',
      keyboard: false
    });

    //Remove the modal once it is closed.
    $(updateCollaborationPlatformChannelNameSelector).on('hidden.bs.modal', function () {
      $(updateCollaborationPlatformChannelNameSelector).remove();
    });

    $('.update-slack-channel-btn-no').click(function () {
      $('#updateCollaborationPlatformChannelName').modal('hide');
    });

    let myThis = this;
    $('.update-slack-channel-btn-yes').click(function () {
      const url = `${environment.apiRoot}/post-to-channel-settings`;
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders
        .set('Content-Type', 'application/json');

      myThis.http.post(url, {
        feedPostToChannelId: event.id,
        feedPostToChannelName: event.name,
        feedSourceType: curFeedSource.feedAccountType,
        feedSourceAccountName: curFeedSource.feedAccountName
      }, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            if (!data.success) {
              if (data.errors) {
                data.errors.forEach(function (e) {
                  myThis.errorMessage += e.message
                });
                myThis._flashMessagesService
                  .show(myThis.errorMessage, {
                    cssClass: 'alert-danger',
                    timeout: 5000
                  });
              }
              return;
            }
            if (data.success) {
              let socialPlatform = "Twitter";
              if (curFeedSource.feedAccountType === "2") {
                socialPlatform = "LinkedIn";
              }
              myThis.clearWorkspaceChannels();
              myThis._flashMessagesService
                .show('You have successfully updated the channel where ' + socialPlatform + ' posts will appear in Slack.', {
                  cssClass: 'alert-success',
                  timeout: 5000
                });
              myThis.feedSources$.subscribe(value => {
                value.map((feedSource) => {
                  if (feedSource.id === curFeedSource.id) {
                    feedSource.feedPostToChannelName = event.name;
                  }
                })
              })
            }
          },
          () => {
          }
        );

      $('#updateCollaborationPlatformChannelName').modal('hide');
    });
  }

  public addFeedSource(socialType: string) {
    const myThis = this;
    myThis.errorMessage = "";
    const url = `${environment.apiRoot}/add-feed-source`;
    const httpHeaders = new HttpHeaders()
      .set('Content-type', 'application/json');
    myThis.http.post(url, {
      "feedSourceType": $(':hidden#' + socialType).val(),
      "feedSourceHandle": myThis.feedSourceAccountName,
      "feedPostToChannelId": myThis.feedPostToChannelId,
      "feedPostToChannelName": myThis.feedPostToChannelName,
      "automaticallyDeleteAfterXDays": -1
    }, {headers: httpHeaders})
      .subscribe(
        (data: any) => {
          if (!data.success) {
            myThis.errorMessage = ""
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            return;
          }

          myThis.modalHideAndRedirect();
          myThis.populateRequiredFeedSourcesByLoggedInAccountId();
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  onDismissalDialogMessageClose() {
    this.errorMessage = null;
  }

  public showDeleteFeedSourceModal(feedSourceAccountType, feedAccountName) {
    this.errorMessage = "";
    this.deletingFeedSourceType = feedSourceAccountType;
    this.deletingFeedSourceAccountName = feedAccountName;
    $('#deleteFeedSource').modal({
      backdrop: 'static', // remove ability to close modal with click
      keyboard: true,
      show: true // Display loader!
    });
  }

  public doDeleteFeedSource() {
    const myThis = this;
    myThis.errorMessage = "";

    const url = `${environment.apiRoot}/delete-feed-source`;
    myThis.http.post(url, {
      "feedSourceType": myThis.deletingFeedSourceType,
      "feedSourceAccountName": myThis.deletingFeedSourceAccountName
    })
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            return;
          }

          $('.modal').modal('hide');
          myThis.populateRequiredFeedSourcesByLoggedInAccountId();
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  public toggleSendToCollaborationPlatformOption(feedAccountType: string, feedAccountName: string, sendToCollaborationPlatform: boolean) {
    const myThis = this;
    myThis.errorMessage = "";

    const url = `${environment.apiRoot}/toggle-send-to-collaboration-platform-feed`;
    myThis.http.post(url, {
      "feedSourceType": feedAccountType,
      "feedSourceAccountName": feedAccountName,
      "sendToCollaborationPlatform": sendToCollaborationPlatform
    })
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            return;
          }
          //Successfully updated "Send to ' + ' "option.'
          this._flashMessagesService
            .show('Successfully updated "Send to ' +
              myThis.collaborationPlatformName$.charAt(0).toUpperCase() +
              myThis.collaborationPlatformName$.substr(1) +
              '" option value.', {
              cssClass: 'alert-success',
              timeout: 5000
            });
          this.feedSources$.subscribe(value => {
            value.map((feedSource) => {
              if (feedSource.feedAccountName === feedAccountName && feedSource.feedAccountType === feedAccountType) {
                feedSource.sendToCollaborationPlatform = !feedSource.sendToCollaborationPlatform;
              }
            })
          })
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  doToggleLnFeedCarousel(id: string, value: boolean) {
    const url = `${environment.apiRoot}/toggle-ln-feed-carousel?id=${id}&state=${value}`;
    let httpHeaders = new HttpHeaders();
    let myThis = this;
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');

    myThis.http.post(url, {}, {headers: httpHeaders})
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            return;
          }

          this._flashMessagesService
            .show('Successfully updated Carousels for LinkedIn Feed Source option value.', {
              cssClass: 'alert-success',
              timeout: 5000
            });
          this.feedSources$.subscribe(feedSources => {
            feedSources.map((feedSource) => {
              if (feedSource.id === id) {
                feedSource.carouselEnabled = value;
              }
            })
          });
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  public changeChannelNotificationSettingOption(feedAccountType: string, feedAccountName: string, notificationType: number) {
    const myThis = this;
    myThis.errorMessage = "";

    const url = `${environment.apiRoot}/channel-notification-settings`;
    myThis.http.post(url, {
      "feedSourceType": feedAccountType,
      "feedSourceAccountName": feedAccountName,
      "notificationType": notificationType
    })
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            this._flashMessagesService
              .show(myThis.errorMessage, {
                cssClass: 'alert-danger',
                timeout: 5000
              });
            return;
          }
          this._flashMessagesService
            .show('You have successfully updated the feed notification type.', {
              cssClass: 'alert-success',
              timeout: 5000
            });
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  public onModalFeedSourcePostToChannelChange(event: WorkspaceChannel) {
    this.feedPostToChannelId = event.id;
    this.feedPostToChannelName = event.name;
    this.clearWorkspaceChannels();
  }

  public clearWorkspaceChannels() {
    this.workspaceChannels$ = of([]);
  }

  openSocialSourceDetailedModal(socialModalId) {
    $('.modal').modal('hide');
    this.errorMessage = undefined;
    this.feedSourceAccountName = undefined;
    $('#' + socialModalId).modal({
      backdrop: 'static', // remove ability to close modal with click
      keyboard: true,
      show: true // Display loader!
    });
  }

  modalHideAndRedirect() {
    $('.modal').modal('hide');
    if (window.location.href.indexOf("twitter-feed-authorization-success") >= 0) {
      window.location.href = '/#/feeds';
    } else if (window.location.href.indexOf("linkedin-feed-authorization-success") >= 0) {
      window.location.href = '/#/feeds';
    }
  }

  authorizeFeedSource(type: string) {
    const myThis = this;
    myThis.errorMessage = "";
    $("#addFeed").hide();
    const url = `${environment.apiRoot}/authorize-feed-source?tokenType=${type}`;
    myThis.http.post(url, {})
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
            }
            return;
          }

          window.location.href = data.data;
        },
        (error) => {
          console.log('Error occurred: ', error);
        }
      );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  gotoHomePage() {
    window.location.href = "/";
  }

  public onAutomaticallyDeleteAfterXDaysSettingsChange(autoDeleteAfterXDays: number, feedSourceType: string, feedSourceAccountName: string) {
    const url = `${environment.apiRoot}/auto-delete-old-posts-settings`;
    let httpHeaders = new HttpHeaders();

    let myThis = this;
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json');

    myThis.http.post(url, {
      autoDeleteOldPostsAfterXDays: autoDeleteAfterXDays,
      feedSourceType: feedSourceType,
      feedSourceAccountName: feedSourceAccountName
    }, {headers: httpHeaders})
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
              myThis._flashMessagesService
                .show(myThis.errorMessage, {
                  cssClass: 'alert-danger',
                  timeout: 5000
                });
            }
            return;
          }
          if (data.success) {
            let socialPlatform = "Twitter";
            if (feedSourceType === "2") {
              socialPlatform = "LinkedIn";
            }
            myThis._flashMessagesService
              .show('You have successfully updated the schedule for removing ' + socialPlatform + ' posts from Slack.', {
                cssClass: 'alert-success',
                timeout: 5000
              });
          }
        }

        ,
        () => {

        }
      )
    ;
  }

  public onFeedPostHeaderTextTypeChange(feedSourceType: string, feedSourceAccountName: string, feedPostHeaderTextType: number) {
    const url = `${environment.apiRoot}/feed-post-header-text-settings`;
    let httpHeaders = new HttpHeaders();

    let myThis = this;
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json');

    myThis.http.post(url, {
      feedPostHeaderTextType: feedPostHeaderTextType,
      feedSourceType: feedSourceType,
      feedSourceAccountName: feedSourceAccountName
    }, {headers: httpHeaders})
      .subscribe(
        (data: any) => {
          if (!data.success) {
            if (data.errors) {
              data.errors.forEach(function (e) {
                myThis.errorMessage += e.message
              });
              myThis._flashMessagesService
                .show(myThis.errorMessage, {
                  cssClass: 'alert-danger',
                  timeout: 5000
                });
            }
            return;
          }
          if (data.success) {
            let socialPlatform = "Twitter";
            let postHeaderValue = "Default";
            if (feedSourceType === "2") {
              socialPlatform = "LinkedIn";
            }
            if (feedPostHeaderTextType === 2) {
              postHeaderValue = "Tweet URL"
            } else if (feedPostHeaderTextType === 3) {
              postHeaderValue = "LinkedIn Post URL"
            }
            myThis._flashMessagesService
              .show('You have successfully updated ' + socialPlatform + ' feed post header settings to "' + postHeaderValue + "\".", {
                cssClass: 'alert-success',
                timeout: 5000
              });
          }
        },
        () => {
        }
      )
    ;
  }
}
