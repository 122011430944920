import * as moment from "moment";
import {NgbDateStruct, NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap";
import {DateUtil} from "../utils/date.utils";
import {ScheduleDetailsResponse} from "../service/schedule.service";

export class ExtraMessageResult {
  message: string;
  link: string;
  imageUrl: string;
  commentary: string;
}

export class FileType {
  constructor(public type: string, public objectKey: string, public isDefaultThumbnail: string, public isS3Object: string) {

  }
}

export class NetworkDataMessage {
  networkType: string;
  extra: ExtraMessageResult;
  attachedFiles: FileType[]

  public static createDefault(networkType: string): NetworkDataMessage {
    const data = new NetworkDataMessage();
    data.networkType = networkType;
    data.extra = new ExtraMessageResult();
    data.attachedFiles = [];
    return data;
  }
}

export class Message {
  scheduleId: number;
  subject: string;
  includeAtChannel: boolean;
  networkData: NetworkDataMessage[];
  defaultContentToDisplay: string;

  public static createDefault(scheduleDetailsResponse: ScheduleDetailsResponse = null): Message {
    const _message = new Message();
    _message.networkData = [];
    _message.networkData.push(NetworkDataMessage.createDefault("TW")); // index 0, do not re-arrange
    _message.networkData.push(NetworkDataMessage.createDefault("FB")); // index 1, do not re-arrange
    _message.networkData.push(NetworkDataMessage.createDefault("LI")); // index 2, do not re-arrange

    if (scheduleDetailsResponse != null) {
      _message.scheduleId = scheduleDetailsResponse.id;
      _message.subject = scheduleDetailsResponse.subject;
      _message.includeAtChannel = scheduleDetailsResponse.notifiesAllMembersOfAChannel;

      const scheduleNetwork = scheduleDetailsResponse.scheduleNetwork;
      if (scheduleNetwork != null) {
        scheduleNetwork.map(value => {
          const sharingDetails = value.sharingDetails;

          if (sharingDetails != null) {
            switch (value.networkType) {
              case "TW": {
                _message.networkData[0].extra.message = sharingDetails.message;
                break;
              }

              case "LI": {
                _message.networkData[2].extra.commentary = sharingDetails.commentary;
                break;
              }
            }
          }
        });
      }
    }

    return _message;
  }
}

export class ScheduleTimeInfo {
  private _scheduleMinMoment: moment.Moment;
  private _scheduleMinDate: NgbDateStruct;
  scheduleDate: NgbDateStruct;
  scheduleTime: NgbTimeStruct;

  constructor(m: moment.Moment = null) {
    this.init(m);
  }

  public reset() {
    this.init(null);
  }

  public updateMinMoment(m: moment.Moment = null) {
    const now = moment();

    let mm = moment().startOf('hour');
    if (now.minutes() < 30) {
      mm = mm.add(30, 'minute');
    } else {
      mm = mm.add(1, 'hour');
    }

    this._scheduleMinMoment = (m == null || m.isBefore(mm)) ? mm : m;
    this._scheduleMinDate = DateUtil.moment2NgbDate(this._scheduleMinMoment);
  }

  public init(m: moment.Moment = null) {
    this.updateMinMoment(m);
    this.scheduleDate = DateUtil.moment2NgbDate(this._scheduleMinMoment);
    this.scheduleTime = DateUtil.moment2NgbTime(this._scheduleMinMoment);
  }

  public get scheduleMinDate(): NgbDateStruct {
    return this._scheduleMinDate;
  }

  public get scheduleEpochSecond(): number {
    const scheduleEpochMilliSecond = DateUtil.ngbDateTime2moment(this.scheduleDate, this.scheduleTime).valueOf();
    if (scheduleEpochMilliSecond < this._scheduleMinMoment.valueOf()) {
      throw new Error('Schedule time should be future time');
    }

    return scheduleEpochMilliSecond / 1000;
  }
}
