<!-- End Topbar header -->
<nav class="navbar navbar-expand navbar-dark static-top" style="background-color: #1D2838">

  <a *ngIf="currentUserProfile && currentUserProfile.email; else pleaseshare_co_redirect_template" class="navbar-brand mr-1" routerLink="home"><img src="../../assets/images/ps_logo_200.png"></a>
  <ng-template #pleaseshare_co_redirect_template>
    <a class="navbar-brand mr-1" href="https://pleaseshare.co"><img src="../../assets/images/ps_logo_200.png"></a>
  </ng-template>

  <!-- Navbar Search -->
  <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
  </form>

  <!-- Navbar -->
  <ul class="navbar-nav ml-auto ml-md-0" *ngIf="currentUserProfile && currentUserProfile.email">
    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false" id="greetingsDropdown">
        <span class="greetings">{{currentUserProfile?.email}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" style="background-color: #e8eef2"
           aria-labelledby="userDropdown">
        <div class="nav-menu-item-co"><strong>{{currentUserProfile?.customWorkspacename}}</strong></div>
        <div class="dropdown-divider"></div>
        <a class="nav-menu-item" routerLink="/settings/account-settings" id="settingsPageLink"><i class="fa fa-gear fa-fw"></i>Settings</a>
        <a class="nav-menu-item" routerLink="/" (click)="logout()" id="logoutLink"><i class="fa fa-power-off fa-fw"></i>Logout</a>
      </div>
    </li>

  </ul>
</nav>
<div class="modal fade" id="session-logout-modal" tabindex="-1" role="dialog" aria-labelledby="session-logout">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="updateCompanyName">Please sign in again.</h4>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            Your session has expired. Please log in again to access your account.
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="logout()" class="btn btn-post cta-button">
          Login
        </button>
      </div>
    </div>
  </div>
</div>
