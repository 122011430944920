import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FlashMessagesService} from "angular2-flash-messages";
import {UserLoginService} from "../../../service/user-login.service";

declare var $: any;

@Component({
  selector: 'app-verify-login-creds',
  templateUrl: './verify-login-creds.component.html',
  styleUrls: ['./verify-login-creds.component.scss']
})
export class VerifyLoginCredsComponent implements OnInit {

  public token: string;
  public errorMessage: string;
  private sub: any;
  private activatedRoute: ActivatedRoute;
  public email: string;
  public password: string;


  constructor(activatedRoute: ActivatedRoute,
              private http: HttpClient,
              public auth: UserLoginService,
              public router: Router,
              private _flashMessagesService: FlashMessagesService) {
    this.activatedRoute = activatedRoute;

  }

  ngOnInit(): void {
    const myThis = this;

    this.sub = this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });

    $('#reInstallSuccessDialog').on('hide.bs.modal', function (e) {
      const successCode = $('#reInstallSuccessDialog .modal-body p.text-center').attr('success-code');
      if (successCode === '200') {
        myThis.router.navigate(['/login']);
      }
    });
  }

  reInstallWithVerifyingLoginCredentials() {
    const myThis = this;
    const verifyAndReInstall = `${environment.apiRoot}/slackapp/verify-and-reinstall`;
    myThis.http.post(verifyAndReInstall, {
      token: this.token,
      email: this.email,
      password: this.password
    })
      .subscribe(
        (data: any) => {
          data.errors = data.errors || null;
          myThis.errorMessage = '';
          if (data.success === true && data.errors === null) {
            $('#reInstallSuccessDialog .modal-body p.text-center').attr('success-code', 200);
            $('#reInstallSuccessDialog').modal({
              backdrop: 'static', // remove ability to close modal with click
              keyboard: true,
              show: true // Display loader!
            });
          } else {
            data.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
            myThis._flashMessagesService.show(myThis.errorMessage,
              {
                cssClass: 'alert-danger',
                timeout: 5000
              });
          }
        },
        (error) => {
          myThis.errorMessage = error.errorMessage || error.error || 'Something weird happened, please try again.';
        }
      );
  }

  logout() {
    this.auth.logout();
    window.location.href = '/#/login';
  }

  backToHome() {
    this.router.navigate(['/home']);
  }
}
