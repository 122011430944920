import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ExtraMessageResult, FileType, Message, NetworkDataMessage, ScheduleTimeInfo} from './Message';

import {ActivatedRoute, Router} from '@angular/router';
import {CognitoUtil, LoggedInCallback} from '../service/cognito.service';
import {UserLoginService} from '../service/user-login.service';
import {environment} from '../../environments/environment';
import {UserProfile} from '../users/UserProfile';
import {UserProfileService} from '../service/user-profile.service';
import {ScheduleDetailsResponse, ScheduleService} from '../service/schedule.service';
import {Observable, of, Subscription} from 'rxjs';
import * as URI from 'urijs';
import {isWebUri} from 'valid-url';
import {NavbarService} from '../service/navbar.service';
import {DataService} from '../service/data.service';
import {Intercom} from 'ng-intercom';
import {ContainerEvents, FileObject, FileObjectStatus, SocialNetworkType, UploadService, User} from './file-upload';
import TwitterMediaAssetsValidationError from "../service/error/TwitterMediaAssetsValidationError";
import {TwitterMediaAssetsValidationService} from "../service/twitter-media-assets-validation-service";
import LinkedInMediaAssetsValidationError from "../service/error/LinkedInMediaAssetsValidationError";
import {LinkedInMediaAssetsValidationService} from "../service/linkedin-media-assets-validation-service";
import {NgxSpinnerService} from "ngx-spinner";
import {CollaborationPlatformChannel} from "../settings/collaboration-platform-channel";
import {PreviewUrlDto} from "../service/dtos/preview.url.dto";
import {UrlPreviewService} from "../service/url-preview.service";
import {NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {DateParserFormatter} from "../service/date-parser-formatter.service";
import {TimezoneService} from "../service/timezone.service";
import {TzInfo} from "../service/dtos/tz.info.dto";
import {PsFlashMessageUtil} from "../service/ps.flash.message.util";
import * as moment from "moment";

declare var $: any;

//TODO: Refactor this God class
@Component({
  // tslint:disable-next-line:component-selector
  selector: '[id=content-wrapper]',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './slack.css', './teams.css', './drag_drops.css', '../../scss/tooltip-custom-home.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: DateParserFormatter}],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, LoggedInCallback, OnDestroy {

  public readonly DefaultContentToDisplayInSlack = DefaultContentToDisplayInSlack;

  public tzInfo$: Observable<TzInfo>;
  public paramScheduleId: number = -1;
  public observableMessage: Observable<Message>;
  public userBelongsToGroup: boolean;
  public userAccountStatus: string;
  public currentUserProfile: UserProfile;
  public postUpdateResponse: any = {status: false, error: false, message: ''};
  public slackAppDownloadLink;
  public slackAppNotificationMsg;
  public appInstalled: any;
  private sub: any;
  private requestMessage: Message;
  private intercomTourId;
  public collaborationPlatformName: string;

  @Input() public message: Message;
  @Output() messageChange = new EventEmitter();

  public scheduleTimeInfo: ScheduleTimeInfo = new ScheduleTimeInfo();
  public defaultImageOrVideoThumbnail: FileObject;
  public previewUrl: Observable<PreviewUrlDto[]>;
  public twitterFinalFiles: FileObject[] = [];
  public linkedinFinalFiles: FileObject[] = [];
  public twOptionalImageUrl: string;
  public liOptionalImageUrl: string;
  public optionalPreviewLinkImageUrl: string;
  progress = 0;
  speed = 0;
  containerEventSubscription: Subscription;
  public errorMessage;
  public resetNeeded: boolean;
  public urlToBeResolved: string;
  public urlResolvingInProgress: boolean;
  public urlPreviewFetched: boolean;
  public twitterContentExists: boolean;
  public linkedInContentExists: boolean;
  public isSchedulePostClicked: boolean;
  public isScheduleShareNow: boolean;
  public defaultContentToDisplay: DefaultContentToDisplayInSlack;

  private psLocalStorageChannelSearchHistoriesLimit = 5;
  public psLocalStorageChannelSearchHistories = [];
  public collaborationPlatformAutoChannels: CollaborationPlatformChannel[];
  public searchKeyword: string = "name";
  public autoCompleteSearchIsLoading = false;
  public autoCompleteSearchPlaceholderText = "Search for a different channel...";
  public psHistoryIdentifier: string;

  public makeSelectedChannelAsDefault: boolean = false;
  public selectedSendToShareRequestChannelId: string = null;
  public selectedSendToShareRequestChannelName: string = null;
  @ViewChild('autocompleteComponent') autocompleteComponent;

  constructor(
    public nav: NavbarService,
    public cognitoUtil: CognitoUtil,
    private http: HttpClient,
    private router: Router, public auth: UserLoginService,
    private activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService,
    private scheduleService: ScheduleService,
    private dataService: DataService,
    private intercom: Intercom,
    private uploadService: UploadService,
    private ngxSpinnerService: NgxSpinnerService,
    private urlPreviewService: UrlPreviewService,
    private timezoneService: TimezoneService,
    private twitterMediaAssetsValidationService: TwitterMediaAssetsValidationService,
    private linkedInMediaAssetsValidationService: LinkedInMediaAssetsValidationService,
    private psFlashMessageUtil: PsFlashMessageUtil) {
    uploadService.fileUploadEvent$.subscribe(
      fileObject => this.handleFileUploadDeleteEvent(fileObject)
    );
    this.containerEventSubscription = uploadService.uploadContainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
    this.sub = this.activatedRoute.queryParams.subscribe(params => {
      this.intercomTourId = params['tourId'];
    });
    this.slackAppNotificationMsg = environment.slackAppNotificationMsg;
    this.auth.isAuthenticated(this);
  }

  onRecentSendToChannelsClick(event) {
    if (event && event.id && event.name) {
      this.selectedSendToShareRequestChannelId = event.id;
      this.selectedSendToShareRequestChannelName = event.name;
      this.autocompleteComponent.query = event.name;
      this.makeSelectedChannelAsDefault = false;
    }
  }

  onChangeSearch(prefix) {
    if (prefix == null || prefix.length < 3) {
      return;
    }

    this.autoCompleteSearchIsLoading = true;
    let myThis = this;
    const url = `${environment.apiRoot}/c-platform/list-channels?prefix=` + prefix;
    let httpHeaders = new HttpHeaders().set('Content-type', 'application/json');
    myThis.http.get(url, {headers: httpHeaders}).subscribe((data: any) => {
        myThis.autoCompleteSearchIsLoading = false;
        const channels = data.data;
        if (!channels) return;
        channels.forEach(function (_channel: CollaborationPlatformChannel) {
          if (_channel.selected) {
            myThis.selectedSendToShareRequestChannelId = _channel.id;
            myThis.selectedSendToShareRequestChannelName = _channel.name;
            return;
          }
        });
        myThis.collaborationPlatformAutoChannels = channels;
      },
      (error) => {
        myThis.autoCompleteSearchIsLoading = false;
        console.log('Error occurred: ', error);
      }
    );
  }

  onSearchSelected(event) {
    this.onRecentSendToChannelsClick(event);
    this.updateHistories(event, false);
  }

  onFocusAutoSearch() {
    this.autocompleteComponent.open();
  }

  isDefaultChannel(channelName: string): boolean {
    return this.currentUserProfile.channelToBePosted === channelName;
  }

  updateDefaultChannel(channelName: string, postStr: string): string {
    return channelName + (this.isDefaultChannel(channelName) ? postStr : '');
  }

  private _updateProfile(data: any) {
    const myThis = this;
    if (!myThis.makeSelectedChannelAsDefault) {
      myThis.updateHistories(new CollaborationPlatformChannel(myThis.selectedSendToShareRequestChannelId, myThis.selectedSendToShareRequestChannelName, true), false);
      myThis.autocompleteComponent.query = null;
    } else {
      const userName = myThis.cognitoUtil.username;
      let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
      profile.channelToBePosted = data.channelName;
      profile.channelIdToBePosted = data.channelId;
      profile.psbotInstalledInPrivateChannel = data.isPrivateChannelSelected;

      myThis.userProfileService.changeProfile(profile);
      myThis.updateHistories(new CollaborationPlatformChannel(data.channelId, data.channelName, data.selected), true);
      myThis.autocompleteComponent.query = null;
    }
  }

  private updateHistories(event: CollaborationPlatformChannel, doUpdateSelected: boolean) {
    if (doUpdateSelected) {
      this.selectedSendToShareRequestChannelId = event.id;
      this.selectedSendToShareRequestChannelName = event.name;
      this.makeSelectedChannelAsDefault = false;
    }

    const histories = window.localStorage.getItem(`${this.psHistoryIdentifier}`);
    if (!histories) {
      this.psLocalStorageChannelSearchHistories = [event];
      window.localStorage.setItem(`${this.psHistoryIdentifier}`, JSON.stringify([event]));
      return;
    }
    let existingHistory = JSON.parse(histories);
    existingHistory.some((existingItem, index) => {
      if (existingItem[this.searchKeyword] === event[this.searchKeyword]) {
        existingHistory.splice(index, 1);
      }
    });
    if (existingHistory.length >= this.psLocalStorageChannelSearchHistoriesLimit) {
      // Remove last element
      existingHistory.splice(-1, 1);
    }
    existingHistory.map((v, _) => {
      v.selected = false;
    });
    this.psLocalStorageChannelSearchHistories = [event].concat(existingHistory);
    localStorage.setItem(`${this.psHistoryIdentifier}`, JSON.stringify(this.psLocalStorageChannelSearchHistories));
  }

  private handleFileUploadDeleteEvent(fileObject: FileObject) {
    if (SocialNetworkType.TW === fileObject.networkType && fileObject.status === FileObjectStatus.Deleted) {
      if (this.twitterFinalFiles) {
        for (let i = 0; i < this.twitterFinalFiles.length; i++) {
          if (this.twitterFinalFiles[i] === fileObject) {
            this.twitterFinalFiles.splice(i, 1);
            if (this.defaultImageOrVideoThumbnail && this.defaultImageOrVideoThumbnail.networkType === SocialNetworkType.TW && this.defaultImageOrVideoThumbnail.file.name === fileObject.file.name) {
              this.defaultImageOrVideoThumbnail = null;
              if (this.twitterFinalFiles && this.twitterFinalFiles.length > 0) {
                this.defaultImageOrVideoThumbnail = this.twitterFinalFiles[0];
              }
              let myThis = this;
              $("label.twitter-thumbnail").each(function (_, __) {
                let title = $(this).attr("title");
                if (typeof title !== 'undefined' && title !== false && myThis.defaultImageOrVideoThumbnail && title === myThis.defaultImageOrVideoThumbnail.file.name) {
                  $(this).addClass("checked")
                }
              });
            }
          }
        }
      }
    } else if (SocialNetworkType.LI === fileObject.networkType && fileObject.status === FileObjectStatus.Deleted) {
      if (this.linkedinFinalFiles.length) {
        for (let i = 0; i < this.linkedinFinalFiles.length; i++) {
          if (this.linkedinFinalFiles[i] === fileObject) {
            this.linkedinFinalFiles.splice(i, 1);
            if (this.defaultImageOrVideoThumbnail && this.defaultImageOrVideoThumbnail.networkType === SocialNetworkType.LI && this.defaultImageOrVideoThumbnail.file.name === fileObject.file.name) {
              this.defaultImageOrVideoThumbnail = null;
              if (this.linkedinFinalFiles && this.linkedinFinalFiles.length > 0) {
                this.defaultImageOrVideoThumbnail = this.linkedinFinalFiles[0];
                let myThis = this;
                $("label.linkedin-thumbnail").each(function (_, __) {
                  let title = $(this).attr("title");
                  if (typeof title !== 'undefined' && title !== false && myThis.defaultImageOrVideoThumbnail && title === myThis.defaultImageOrVideoThumbnail.file.name) {
                    $(this).addClass("checked")
                  }
                });
              }
            }
          }
        }
      }
    }
  }

  twitterFileChangeEvent(fileInput: any) {
    try {
      this.twitterMediaAssetsValidationService.validate(this.twitterFinalFiles, fileInput.target.files, null);
    } catch (error) {
      if (error instanceof TwitterMediaAssetsValidationError) {
        this.showErrorModal(error.getErrorMessage());
        return;
      } else {
        throw error;
      }
    }
    if (fileInput.target.files && fileInput.target.files.length) {
      for (let i = 0; i < fileInput.target.files.length; i++) {
        const file = fileInput.target.files[i];
        let imageSrc: string;
        const reader = new FileReader();
        reader.onload = () => {
          imageSrc = reader.result as string;
          let fileName: string = (file && file.name) || undefined;
          let fileAlreadyUploaded: boolean = false;
          if (fileName && this.twitterFinalFiles) {
            this.twitterFinalFiles.forEach(_fileObj => {
              if (_fileObj && _fileObj.file && _fileObj.file.name === fileName) {
                fileAlreadyUploaded = true;
              }
            })
          }
          if (!fileAlreadyUploaded) {
            const fileObject = new FileObject(fileInput.target.files[i], SocialNetworkType.TW, imageSrc, true);
            this.twitterFinalFiles.push(fileObject);
            if (!this.defaultImageOrVideoThumbnail && this.twitterFinalFiles && this.twitterFinalFiles.length === 1) {
              fileObject.isDefaultImage = true;
              this.defaultImageOrVideoThumbnail = fileObject;
            }
          }
        };
        if (file && file.type && file.type.indexOf("image") >= 0) {
          reader.readAsDataURL(file);
        } else if (file && file.type && file.type.indexOf("video") >= 0) {
          imageSrc = environment["pleaseshare.co.video.attached.image.url"];
          const fileObject = new FileObject(fileInput.target.files[i], SocialNetworkType.TW, imageSrc, true);
          this.twitterFinalFiles.push(fileObject);
          if (!this.defaultImageOrVideoThumbnail && this.twitterFinalFiles && this.twitterFinalFiles.length === 1) {
            fileObject.isDefaultImage = true;
            this.defaultImageOrVideoThumbnail = fileObject;
          }
        }
      }
      let myThis = this;
      setTimeout(function () {
        myThis.uploadTwitterAll();
        fileInput.target.value = null;
      }, 1000);
    }
  }

  public onPreviewImageOrVideoClick(event: any, fileObj: any, socialNetworkType: string) {
    $('.thumbnail').removeClass('checked');
    event.target.parentElement.classList.add("checked")
    if (fileObj) {
      this.onDefaultImageOrVideoThumbnailChange(fileObj);
      return;
    }
    let _fileObj: FileObject;
    if (SocialNetworkType[SocialNetworkType.TW] === socialNetworkType) {
      let _file: File = new File(null, this.twOptionalImageUrl)
      _fileObj = new FileObject(_file, SocialNetworkType.TW, this.twOptionalImageUrl, false);
    } else if (SocialNetworkType[SocialNetworkType.LI] === socialNetworkType) {
      let _file: File = new File(null, this.liOptionalImageUrl)
      _fileObj = new FileObject(_file, SocialNetworkType.LI, this.liOptionalImageUrl, false);
    }
    this.onDefaultImageOrVideoThumbnailChange(_fileObj);
  }

  linkedInFileChangeEvent(fileInput: any) {
    try {
      this.linkedInMediaAssetsValidationService.validate(this.linkedinFinalFiles, fileInput.target.files, null);
    } catch (error) {
      if (error instanceof LinkedInMediaAssetsValidationError) {
        this.showErrorModal(error.getErrorMessage());
        return;
      } else {
        throw error;
      }
    }
    if (fileInput.target.files && fileInput.target.files.length) {
      for (let i = 0; i < fileInput.target.files.length; i++) {
        const file = fileInput.target.files[i];
        let imageSrc: string;
        const reader = new FileReader();
        reader.onload = () => {
          imageSrc = reader.result as string;
          let fileName: string = (file && file.name) || undefined;
          let fileAlreadyUploaded: boolean = false;
          if (fileName && this.linkedinFinalFiles) {
            this.linkedinFinalFiles.forEach(_fileObj => {
              if (_fileObj && _fileObj.file && _fileObj.file.name === fileName) {
                fileAlreadyUploaded = true;
              }
            })
          }
          if (!fileAlreadyUploaded) {
            const fileObject = new FileObject(fileInput.target.files[i], SocialNetworkType.LI, imageSrc, true);
            this.linkedinFinalFiles.push(fileObject);
            if (!this.defaultImageOrVideoThumbnail && this.linkedinFinalFiles && this.linkedinFinalFiles.length == 1) {
              fileObject.isDefaultImage = true;
              this.defaultImageOrVideoThumbnail = fileObject;
            }
          }
        };
        if (file && file.type && file.type.indexOf("image") >= 0) {
          reader.readAsDataURL(file);
        } else if (file && file.type && file.type.indexOf("video") >= 0) {
          imageSrc = environment["pleaseshare.co.video.attached.image.url"];
          const fileObject = new FileObject(fileInput.target.files[i], SocialNetworkType.LI, imageSrc, true);
          this.linkedinFinalFiles.push(fileObject);
          if (!this.defaultImageOrVideoThumbnail && this.linkedinFinalFiles && this.linkedinFinalFiles.length == 1) {
            fileObject.isDefaultImage = true;
            this.defaultImageOrVideoThumbnail = fileObject;
          }
        }
      }
      let myThis = this;
      setTimeout(function () {
        myThis.uploadLinkedInAll();
        fileInput.target.value = null;
      }, 1000);
    }
  }

  handleContainerEvent(_: ContainerEvents) {

  }

  clearTwitterAndLinkedInAttachedFiles() {
    if (this.twitterFinalFiles && this.twitterFinalFiles.length) {
      this.twitterFinalFiles = [];
    }
    if (this.linkedinFinalFiles && this.linkedinFinalFiles.length) {
      this.linkedinFinalFiles = [];
    }
    $("#collapseTwitter").collapse("hide");
    $("#collapseLinkedIn").collapse("hide");
  }

  uploadTwitterAll() {
    this.uploadService.publishUploadContainerEvent(ContainerEvents.TwitterUpload);
  }

  uploadLinkedInAll() {
    this.uploadService.publishUploadContainerEvent(ContainerEvents.LinkedInUpload);
  }

  private static initializeMissingNetworkData(networkData: NetworkDataMessage[], _message: Message) {
    if (networkData.map(x => x.networkType).indexOf('TW') < 0) {
      const twNetData = new NetworkDataMessage();
      twNetData.networkType = 'TW';
      twNetData.extra = new ExtraMessageResult();
      _message.networkData.splice(0, 0, twNetData);
    }
    if (networkData.map(x => x.networkType).indexOf('FB') < 0) {
      const fbNetData = new NetworkDataMessage();
      fbNetData.networkType = 'FB';
      fbNetData.extra = new ExtraMessageResult();
      _message.networkData.splice(1, 0, fbNetData);
    }
    if (networkData.map(x => x.networkType).indexOf('LI') < 0) {
      const liNetData = new NetworkDataMessage();
      liNetData.networkType = 'LI';
      liNetData.extra = new ExtraMessageResult();
      _message.networkData.splice(2, 0, liNetData);
    }

  }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(appInstalled => this.appInstalled = appInstalled);
    this.userProfileService.loggedInUserProfile.subscribe(profile => {
      this.currentUserProfile = profile;
      if (profile === undefined || !profile.hasGroup) {
        this.userBelongsToGroup = false;
      } else {
        this.userBelongsToGroup = this.currentUserProfile.hasGroup;
        this.adjustFeedbackTextRemainingBlock();
      }
      if (this.userBelongsToGroup && profile !== undefined) {
        this.userAccountStatus = profile.accountStatus;
        this.collaborationPlatformName = profile.collaborationPlatformName;
        this.uploadService.setSignedInUser(new User(true, this.cognitoUtil.username))
        this.updateHistories(new CollaborationPlatformChannel(profile.channelIdToBePosted, profile.channelToBePosted, true), true);
      }
    });

    $(document).ready(function () {
      let socialUrlToggle = (data: any) => {
        let imageUrlLinkSocial = document.querySelector(data.imgUrlLinkQueryKey);
        if (imageUrlLinkSocial) {
          const addSocialUrl = document.querySelector(data.addUrlQueryKey);
          imageUrlLinkSocial.addEventListener("click", () => {
            if (addSocialUrl.classList.contains("show-link")) {
              addSocialUrl.classList.remove("show-link");
              addSocialUrl.classList.add("hide-link");
            } else {
              addSocialUrl.classList.remove("hide-link");
              addSocialUrl.classList.add("show-link");
            }
          })
        }

        //Social Upload Image Toggle Button State (Close - Add Image(s)/Video)
        $(data.collapseSocialKey).on("hidden.bs.collapse", () => {
          $(data.buttonAccordionSocialKey).text("Add Image(s)/Video");
        });

        $(data.collapseSocialKey).on("show.bs.collapse", () => {
          $(data.buttonAccordionSocialKey).text("");
        });
      };

      $('[data-toggle="tooltip"]').tooltip();
      socialUrlToggle({
        addUrlQueryKey: "#add_tw_image_url",
        imgUrlLinkQueryKey: "#image-url-link-twitter",
        collapseSocialKey: '#collapseTwitter',
        buttonAccordionSocialKey: "#btn-accordion-twitter",
      });

      socialUrlToggle({
        addUrlQueryKey: "#add_url_linkedIn",
        imgUrlLinkQueryKey: "#image-url-link-linkedIn",
        collapseSocialKey: '#collapseLinkedIn',
        buttonAccordionSocialKey: "#btn-accordion-linkedIn",
      });
    });
  }

  clearAppInstallationValue() {
    this.dataService.changeMessage('clearInstallationMessage');
  }

  private adjustFeedbackTextRemainingBlock() {
    $(document).ready(function () {
      const text_max = 280;
      $('#textarea_feedback').html(text_max + ' characters remaining');

      $('#twitter-message').keyup(function () {
        const text_length = $('#twitter-message').val().length;
        const text_remaining = text_max - text_length;

        $('#textarea_feedback').html(text_remaining + ' characters remaining');
      });
    });
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    let userProfile;
    try {
      userProfile = JSON.parse(this.activatedRoute.snapshot.data['userProfile']);
    } catch (e) {
      userProfile = this.activatedRoute.snapshot.data['userProfile'];
    }
    this.userProfileService.changeProfile(userProfile);
    const myThis = this;

    if (!isLoggedIn) {
      this.auth.logout();
      window.location.href = '/#/login';
    } else {
      const profileTzName = userProfile.tzName;
      if (profileTzName == null || profileTzName.trim().length == 0) {
        this.updateTzName(Intl.DateTimeFormat().resolvedOptions().timeZone);
      } else {
        this.tzInfo$ = this.timezoneService.getTzInfo(userProfile.tzName);
      }

      this.psHistoryIdentifier = this.cognitoUtil.psHistoryIdentifierForSendPostToShareRequest;
      this.slackAppDownloadLink = environment.slackAppDownloadLink.replace('_state_', userProfile.email);
      setTimeout(() => {
        myThis.nav.show();
      }, 0);
      this.doReset();
    }

    if (this.intercomTourId) {
      this.intercom.startTour(this.intercomTourId);
    }
  }

  private updateTzName(tzName: string) {
    if (tzName == null || tzName.trim().length == 0) {
      return;
    }

    let myThis = this;
    const updateTzName$ = this.timezoneService.updateTzName(tzName);
    this.psFlashMessageUtil.handleHttpObservable<TzInfo[]>(updateTzName$, isUpdated => {
      if (isUpdated) {
        const userProfile = myThis.updateProfile(myThis, (profile: UserProfile) => profile.tzName = tzName);
        myThis.tzInfo$ = myThis.timezoneService.getTzInfo(userProfile.tzName);
      }
    });
  }

  private updateProfile(myThis: HomeComponent, onUpdate: (profile: UserProfile) => void = null): UserProfile {
    const userName = myThis.cognitoUtil.username;
    const profile: UserProfile = JSON.parse(localStorage.getItem(userName));
    if (profile == null) {
      return null;
    }

    if (onUpdate != null) {
      onUpdate(profile);
      localStorage.removeItem(userName);
      localStorage.setItem(userName, JSON.stringify(profile));
      myThis.userProfileService.changeProfile(profile);
    }

    return profile;
  }

  isSignedIn() {
    return this.auth.isSignedIn();
  }

  doReset() {
    let _initialize_func = (myThis: HomeComponent, scheduleDetails: ScheduleDetailsResponse) => {
      const _message = Message.createDefault(scheduleDetails);
      myThis.observableMessage = of(_message);
      myThis.message = _message;

      myThis.defaultImageOrVideoThumbnail = null;
      myThis.twOptionalImageUrl = null;
      myThis.liOptionalImageUrl = null;
      myThis.optionalPreviewLinkImageUrl = null;
      myThis.defaultContentToDisplay = _message.subject != null ? DefaultContentToDisplayInSlack.URL_PREVIEW : null;
      myThis.twitterContentExists = _message.networkData[0].extra.message != null;
      myThis.linkedInContentExists = _message.networkData[2].extra.commentary != null;
      myThis.previewUrl = null;
      myThis.urlPreviewFetched = false;

      $('#textarea_feedback').html(280 + ' characters remaining');
      myThis.clearTwitterAndLinkedInAttachedFiles();
      myThis.onBlurOnNewUpdate(_message.subject);

      if (scheduleDetails != null && scheduleDetails.isScheduledForFuture) {
        myThis.isSchedulePostClicked = true;
        myThis.scheduleTimeInfo.init(moment.unix(scheduleDetails.scheduleEpochSecond));
      } else {
        myThis.isSchedulePostClicked = false;
        myThis.scheduleTimeInfo.reset();
      }
    };

    let myThis = this;
    this.activatedRoute.params.subscribe(params => {
      const paramScheduleId = Number(params['scheduleId'] || '-1');
      if (paramScheduleId == -1) {
        _initialize_func(myThis, null);
        return;
      }

      myThis.scheduleService.getScheduleDetails(paramScheduleId).subscribe((response: any) => {
        if (response != null && response.success) {
          const scheduleDetails = response.data;
          myThis.paramScheduleId = scheduleDetails.id;
          _initialize_func(myThis, scheduleDetails);
        } else {
          myThis.postUpdateResponse.status = true;
          myThis.postUpdateResponse.error = true;
          myThis.postUpdateResponse.message = PsFlashMessageUtil.parseErrors(response);
        }
      });
    });
  }

  private validate(): { isValid: boolean, message: string } {
    const message = this.message;
    if (!message.subject) {
      return {isValid: false, message: 'Description field is missing'};
    }

    const isTwitterMessageExist = this.isTwitterMessageExist();
    const isFBLinkExist = this.isFBUrlExist();
    const isLICommentaryExist = this.isLinkedInCommentaryExist();

    if (!isTwitterMessageExist && !isFBLinkExist && !isLICommentaryExist) {
      return {
        message: 'To post an update to Slack, you must include content in at least one of the social media templates fields – Twitter, LinkedIn, or Facebook.',
        isValid: false
      };
    }
    if (!isTwitterMessageExist && this.twitterFinalFiles && this.twitterFinalFiles.length > 0) {
      return {isValid: false, message: 'Please make sure you provided message on Twitter message field.'};
    }
    if (isTwitterMessageExist) {
      const result = URI.withinString(this.getTwitterMessage(), function (_url) {
        return '<a>' + _url + '</a>';
      });

      const url = result.match(/<a[^>]*>([^<]+)<\/a>/);
      if (url !== null && !this.isValidLinkExistInWithinString(this.getTwitterMessage())) {
        return {isValid: false, message: 'Please make sure you provided a valid url on Twitter message field.'};
      }
      if (!this.isAttachedFilesUploaded(SocialNetworkType.TW)) {
        return {isValid: false, message: 'Please make sure you have uploaded all of the attached files.'};
      }
    }
    if (!isLICommentaryExist && this.linkedinFinalFiles && this.linkedinFinalFiles.length > 0) {
      return {isValid: false, message: 'Please make sure you provided message on LinkedIn message field.'};
    }
    if (isLICommentaryExist) {
      const result = URI.withinString(this.getLiCommentary(), function (_url) {
        return '<a>' + _url + '</a>';
      });

      const url = result.match(/<a[^>]*>([^<]+)<\/a>/);
      if (url !== null && !this.isValidLinkExistInWithinString(this.getLiCommentary())) {
        return {isValid: false, message: 'Please make sure you provided a valid url on Linkedin commentary field.'};
      }

      if (!this.isAttachedFilesUploaded(SocialNetworkType.LI)) {
        return {isValid: false, message: 'Please make sure you have uploaded all of the attached files.'};
      }
    }
    if (isFBLinkExist && !isWebUri(this.getFBUrl())) {
      return {isValid: false, message: 'Please make sure you provided a valid url on Facebook url field'};
    }
    return {isValid: true, message: ''};
  }

  private isTwitterMessageExist() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'TW'
        && this.message.networkData[i].extra
        && this.message.networkData[i].extra.message) {
        return true;
      }
    }
    return false;
  }

  private getTwitterMessage() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'TW' && this.message.networkData[i].extra) {
        return this.message.networkData[i].extra.message;
      }
    }
    return '';
  }

  private isLinkedInCommentaryExist() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'LI'
        && this.message.networkData[i].extra
        && this.message.networkData[i].extra.commentary) {
        return true;
      }
    }
    return false;
  }

  private getLiCommentary() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'LI' && this.message.networkData[i].extra.commentary) {
        return this.message.networkData[i].extra.commentary;
      }
    }
    return '';
  }

  private isFBUrlExist() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'FB'
        && this.message.networkData[i].extra
        && this.message.networkData[i].extra.link) {
        return true;
      }
    }
    return false;
  }

  private getFBUrl() {
    for (const i in this.message.networkData) {
      if (this.message.networkData[i]['networkType'] === 'FB' && this.message.networkData[i].extra.link) {
        return this.message.networkData[i].extra.link;
      }
    }
    return '';
  }

  private isAttachedFilesUploaded(networkType: SocialNetworkType) {
    if (SocialNetworkType.TW === networkType) {
      let twFileUploadedCount = 0;
      if (this.twitterFinalFiles && this.twitterFinalFiles.length > 0) {
        this.twitterFinalFiles.forEach((twFileObject) => {
          if (twFileObject.status === FileObjectStatus.Uploaded || !twFileObject.isS3Object) {
            twFileUploadedCount++;
          }
        })
        return twFileUploadedCount === this.twitterFinalFiles.length;
      }
      return true;
    }
    if (SocialNetworkType.LI === networkType) {
      let liFileUploadedCount = 0;
      if (this.linkedinFinalFiles && this.linkedinFinalFiles.length > 0) {
        this.linkedinFinalFiles.forEach((liFileObject) => {
          if (liFileObject.status === FileObjectStatus.Uploaded || !liFileObject.isS3Object) {
            liFileUploadedCount++;
          }
        })
        return liFileUploadedCount === this.linkedinFinalFiles.length;
      }
      return true;
    }
  }

  private isValidLinkExistInWithinString(string: string) {
    const result = URI.withinString(string, function (_url) {
      return '<a>' + _url + '</a>';
    });

    const url = result.match(/<a[^>]*>([^<]+)<\/a>/);
    const isValidUrl = (_str) => {
      try {
        // tslint:disable-next-line:no-unused-expression
        new URL(_str);
        return true;
      } catch (_) {
        return false;
      }
    };
    return (url !== null && url[1]) && isValidUrl(url[1]);
  }

  doShare(shareNow: boolean) {
    const myThis = this;

    let scheduleEpochSecond = null;
    try {
      if (!shareNow) {
        scheduleEpochSecond = this.scheduleTimeInfo.scheduleEpochSecond;
      }
    } catch (e) {
      myThis.postUpdateResponse.status = true;
      myThis.postUpdateResponse.error = true;
      myThis.postUpdateResponse.message = e.message;
      return;
    }

    this.scheduleAdd((data: any) => {
      const shareNowUrl = `${environment.apiRoot}/post-to-collaboration-platform`;
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const body = {
        scheduleId: data.scheduleId,
        scheduleEpochSecond: scheduleEpochSecond,
        collaborationPlatformChannelId: myThis.selectedSendToShareRequestChannelId,
        collaborationPlatformChannelName: myThis.selectedSendToShareRequestChannelName,
        collaborationPlatformOldChannelName: myThis.currentUserProfile.channelToBePosted,
        makeSelectedChannelAsDefault: myThis.makeSelectedChannelAsDefault,
        scheduleType: 'SHARE_REQUEST'
      };

      myThis.http.post(shareNowUrl, body, {headers: httpHeaders}).subscribe((response: any) => {
          response.error = response.error || null;
          response.errorMessage = response.errorMessage || null;
          myThis.postUpdateResponse.message = '';
          if (response.success === true && response.errors === null && response.errorMessage === null) {
            const _data = response.data;
            myThis.postUpdateResponse.status = true;
            if (shareNow) {
              myThis.postUpdateResponse.message = 'Your update has been submitted and will appear in '
                + _data.collaborationPlatformName.charAt(0).toUpperCase() + _data.collaborationPlatformName.slice(1) + ' in a few moments.';
            } else {
              myThis.postUpdateResponse.message = 'Your slack post was successfully scheduled.';
            }
            myThis.postUpdateResponse.error = false;
            myThis._updateProfile(_data);
            if (myThis.paramScheduleId != -1) {
              window.location.href = `/#/history/share-history`;
            } else {
              const metadata = {
                email: myThis.currentUserProfile.email,
                workspace_name: myThis.currentUserProfile.customWorkspacename,
                company_name: myThis.currentUserProfile.customCompanyname,
                collaboration_platform_name: _data.collaborationPlatformName
              };
              myThis.intercom.trackEvent('update-posted-to-slack', metadata);
              myThis.doReset();
            }
          } else {
            myThis.postUpdateResponse.status = true;
            myThis.postUpdateResponse.error = true;
            if (response.errors) {
              response.errors.forEach(function (e) {
                myThis.postUpdateResponse.message += e.message + '<br />';
              });
            }
          }
        },
        () => {
          myThis.postUpdateResponse.status = true;
          myThis.postUpdateResponse.error = true;
          myThis.postUpdateResponse.message = 'Something weird happened!, could not post your update.';
        }
      );
    });
  }

  scheduleAdd(callback: any) {
    const myThis = this;
    const valid = this.validate();
    if (!valid.isValid) {
      this.showErrorModal(valid.message);
      return;
    }

    const scheduleAddUrl = `${environment.apiRoot}/schedule`;
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    myThis._prepareRequestData();

    myThis.http.post(scheduleAddUrl, myThis.requestMessage, {headers: httpHeaders}).subscribe((data: any) => {
        if (!data.success) {
          let errorMessage = '';
          if (data.errors) {
            data.errors.forEach(function (e) {
              errorMessage += e.message + '<br />';
            });
          }
          myThis.postUpdateResponse.status = true;
          myThis.postUpdateResponse.error = true;
          myThis.postUpdateResponse.message = errorMessage;
        } else if (callback !== null) {
          callback({scheduleId: data.data});
        }
      },
      () => {
        myThis.postUpdateResponse.status = true;
        myThis.postUpdateResponse.error = true;
        myThis.postUpdateResponse.message = 'Something weird happened!, could not post your update.';
      }
    );
  }

  private _prepareRequestData() {
    this.requestMessage = Object.assign(new Message(), this.message);
    this.requestMessage.defaultContentToDisplay = this.defaultContentToDisplay;
    if (this.optionalPreviewLinkImageUrl != null && this.optionalPreviewLinkImageUrl.length > 0) {
      this.twOptionalImageUrl = (this.twOptionalImageUrl == null) ? this.optionalPreviewLinkImageUrl : this.twOptionalImageUrl;
      this.liOptionalImageUrl = (this.liOptionalImageUrl == null) ? this.optionalPreviewLinkImageUrl : this.liOptionalImageUrl;
    }
    for (const i in this.requestMessage.networkData) {
      if (this.requestMessage.networkData[i].networkType === 'TW') {
        if (!this.requestMessage.networkData[i].extra.message) {
          this.requestMessage.networkData.splice(parseInt(i, 10), 1);
        } else {
          this.requestMessage.networkData[i].extra.imageUrl = this.twOptionalImageUrl;
        }
        if (this.twitterFinalFiles && this.twitterFinalFiles.length > 0) {
          let fileNames: FileType[] = [];
          this.twitterFinalFiles.forEach(twFileObj => {
            if (!twFileObj.isS3Object) {
              fileNames.push(new FileType(twFileObj.file.type, twFileObj.file.name, String(twFileObj.isDefaultImage), String(twFileObj.isS3Object)))
            } else {
              fileNames.push(new FileType(twFileObj.file.type, [twFileObj.uploadKeyPrefix, twFileObj.file.name].join("/"), String(twFileObj.isDefaultImage), String(twFileObj.isS3Object)))
            }
          })
          this.requestMessage.networkData[i].attachedFiles = fileNames;
        }
      }
      if (this.requestMessage.networkData[i].networkType === 'FB' && !this.requestMessage.networkData[i].extra.link) {
        this.requestMessage.networkData.splice(parseInt(i, 10), 1);
      }
      if (this.requestMessage.networkData[i].networkType === 'LI') {
        if (!this.requestMessage.networkData[i].extra.commentary) {
          this.requestMessage.networkData.splice(parseInt(i, 10), 1);
        } else {
          this.requestMessage.networkData[i].extra.imageUrl = this.liOptionalImageUrl;
        }
        if (this.linkedinFinalFiles && this.linkedinFinalFiles.length > 0) {
          let fileNames: FileType[] = [];
          this.linkedinFinalFiles.forEach(liFileObj => {
            if (!liFileObj.isS3Object) {
              fileNames.push(new FileType(liFileObj.file.type, liFileObj.file.name, String(liFileObj.isDefaultImage), String(liFileObj.isS3Object)))
            } else {
              fileNames.push(new FileType(liFileObj.file.type, [liFileObj.uploadKeyPrefix, liFileObj.file.name].join("/"), String(liFileObj.isDefaultImage), String(liFileObj.isS3Object)))
            }
          })
          this.requestMessage.networkData[i].attachedFiles = fileNames;
        }
      }
    }
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onBlurOnInputFields(event: any) {
    HomeComponent.initializeMissingNetworkData(this.message.networkData, this.message);
    // tslint:disable-next-line:forin
    for (const i in this.message.networkData) {
      if (event.target.id === 'twitter-message' && this.message.networkData[i]['networkType'] === 'TW') {
        this.message.networkData[i].extra.message = event.target.value;
        this.twitterContentExists = event.target.value !== '';
        if (!this.defaultContentToDisplay) {
          this.defaultContentToDisplay = DefaultContentToDisplayInSlack.TWEET_CONTENT
        }
      }
      if (event.target.id === 'tw-media-url' && this.message.networkData[i]['networkType'] === 'TW') {
        this.message.networkData[i].extra.imageUrl = event.target.value;
        if (!this.twOptionalImageUrl) return;
        try {
          // Check if it is already added in this.twitterFinalFiles
          if (this.twitterFinalFiles && this.twitterFinalFiles.find(obj => obj.objectUrl === this.twOptionalImageUrl)) {
            return;
          }
          // Check if there is already an optional image url exist in this.twitterFinalFiles. If it is then remove it
          if (this.twitterFinalFiles) {
            for (let i = 0; i < this.twitterFinalFiles.length; i++) {
              // There should be only one Non S3 image url in this.twitterFinalFiles array
              if (this.twitterFinalFiles[i].objectUrl && !this.twitterFinalFiles[i].isS3Object) {
                this.twitterFinalFiles.splice(i, 1);
              }
            }
          }
          this.twitterMediaAssetsValidationService.validate(this.twitterFinalFiles, null, this.twOptionalImageUrl);
          let _file: File = new File([], this.twOptionalImageUrl, {
            type: "image/jpeg",
          });
          let _fileObj: FileObject = new FileObject(_file, SocialNetworkType.TW, this.twOptionalImageUrl, false);
          if (!this.defaultImageOrVideoThumbnail) {
            _fileObj.isDefaultImage = true;
            this.defaultImageOrVideoThumbnail = _fileObj;
          }
          this.twitterFinalFiles.push(_fileObj)
        } catch (error) {
          this.twOptionalImageUrl = undefined;
          if (error instanceof TwitterMediaAssetsValidationError) {
            this.showErrorModal(error.getErrorMessage());
            return;
          } else {
            throw error;
          }
        }
      }
      if (event.target.id === 'ln-commentary' && this.message.networkData[i]['networkType'] === 'LI') {
        this.message.networkData[i].extra.commentary = event.target.value;
        this.linkedInContentExists = event.target.value !== '';
        if (!this.defaultContentToDisplay) {
          this.defaultContentToDisplay = DefaultContentToDisplayInSlack.LINKEDIN_POST_CONTENT
        }
      }
      if (event.target.id === 'ln-image-url' && this.message.networkData[i]['networkType'] === 'LI') {
        this.message.networkData[i].extra.imageUrl = event.target.value;
        if (!this.liOptionalImageUrl) return;
        try {
          // Check if it is already added in this.linkedinFinalFiles
          if (this.linkedinFinalFiles && this.linkedinFinalFiles.find(obj => obj.objectUrl === this.liOptionalImageUrl)) {
            return;
          }
          // Check if there is already an optional image url exist in this.twitterFinalFiles. If it is then remove it
          if (this.linkedinFinalFiles) {
            for (let i = 0; i < this.linkedinFinalFiles.length; i++) {
              // There should be only one Non S3 image url in this.linkedinFinalFiles array
              if (this.linkedinFinalFiles[i].objectUrl && !this.linkedinFinalFiles[i].isS3Object) {
                this.linkedinFinalFiles.splice(i, 1);
              }
            }
          }
          this.linkedInMediaAssetsValidationService.validate(this.linkedinFinalFiles, null, this.liOptionalImageUrl);
          let _file: File = new File([], this.liOptionalImageUrl, {
            type: "image/jpeg",
          });
          let _fileObj: FileObject = new FileObject(_file, SocialNetworkType.LI, this.liOptionalImageUrl, false);
          if (!this.defaultImageOrVideoThumbnail) {
            _fileObj.isDefaultImage = true;
            this.defaultImageOrVideoThumbnail = _fileObj;
          }
          this.linkedinFinalFiles.push(_fileObj)
        } catch (error) {
          this.liOptionalImageUrl = undefined;
          if (error instanceof LinkedInMediaAssetsValidationError) {
            this.showErrorModal(error.getErrorMessage());
            return;
          } else {
            throw error;
          }
        }
      }
      if (event.target.id === 'fb-url' && this.message.networkData[i]['networkType'] === 'FB') {
        this.message.networkData[i].extra.link = event.target.value;
      }
    }
  }

  onBlurOnNewUpdate(subject: string) {
    if (!subject) return;
    const myThis = this;
    const isValidUrl = (string) => {
      try {
        // tslint:disable-next-line:no-unused-expression
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };
    const result = URI.withinString(subject, function (_url) {
      return '<a>' + _url + '</a>';
    });

    const url = result.match(/<a[^>]*>([^<]+)<\/a>/);
    if (!url) {
      setTimeout(() => {
        myThis.ngxSpinnerService.hide("description-spinner").then();
      }, 500);
      return;
    }
    this.urlResolvingInProgress = true;
    this.ngxSpinnerService.show("description-spinner", {
      type: "ball-clip-rotate",
      size: "small",
      bdColor: "rgba(255, 255, 255, .2)",
      fullScreen: false,
      color: "#495057"
    }).then();
    if (url && url.length >= 1) {
      if (!isValidUrl(url[1])) {
        setTimeout(() => {
          myThis.ngxSpinnerService.hide("description-spinner").then();
          this.urlResolvingInProgress = false;
        }, 500);
        return;
      }
      this.urlPreviewFetched = false;
      this.urlPreviewService.getPreviewUrlInfo(url[1]).then(function (previewUrl: PreviewUrlDto) {
        $('#health-check-block').removeClass('d-none').addClass('d-block');
        myThis.previewUrl = of([previewUrl]);
        myThis.optionalPreviewLinkImageUrl = previewUrl.imageUrl;
        setTimeout(() => {
          myThis.ngxSpinnerService.hide("description-spinner").then();
          myThis.urlResolvingInProgress = false;
          myThis.urlPreviewFetched = true;
          if (!myThis.defaultContentToDisplay) {
            myThis.defaultContentToDisplay = DefaultContentToDisplayInSlack.URL_PREVIEW
          }
        }, 500);
      }, function (previewUrl: PreviewUrlDto) {
        $('#health-check-block').removeClass('d-none').addClass('d-block');
        myThis.previewUrl = of([previewUrl]);
        myThis.optionalPreviewLinkImageUrl = previewUrl.imageUrl;
        setTimeout(() => {
          myThis.ngxSpinnerService.hide("description-spinner").then();
          myThis.urlResolvingInProgress = false;
        }, 500);
      });
    }
  }

  public showErrorModal(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.resetNeeded = false;
    this.urlToBeResolved = undefined;
    $('#errorModal').modal({
      backdrop: 'static', // remove ability to close modal with click
      keyboard: true,
      show: true // Display loader!
    });
  }

  onDismissalDialogMessageClose() {
    this.postUpdateResponse.status = false;
  }

  onClickOnResetModalYesButton(url) {
    let myThis = this;
    this.ngxSpinnerService.show("description-spinner", {
      type: "ball-clip-rotate",
      size: "small",
      bdColor: "rgba(255, 255, 255, .2)",
      fullScreen: false,
      color: "#495057"
    }).then();
    this.urlPreviewService.getPreviewUrlInfo(url).then(function (previewUrl: PreviewUrlDto) {
      $('#health-check-block').removeClass('d-none').addClass('d-block');
      myThis.previewUrl = of([previewUrl]);
      myThis.optionalPreviewLinkImageUrl = previewUrl.imageUrl;
      if (myThis.defaultContentToDisplay) {
        myThis.defaultContentToDisplay = null;
      }
      setTimeout(() => {
        myThis.ngxSpinnerService.hide("description-spinner").then();
      }, 500);
    }, function (previewUrl: PreviewUrlDto) {
      $('#health-check-block').removeClass('d-none').addClass('d-block');
      myThis.previewUrl = of([previewUrl]);
      myThis.optionalPreviewLinkImageUrl = previewUrl.imageUrl;
      setTimeout(() => {
        myThis.ngxSpinnerService.hide("description-spinner").then();
      }, 500);
    });
  }

  onClickOnResetModalNoButton() {
    this.doReset();
  }

  public onDefaultImageOrVideoThumbnailChange(fileObject: FileObject) {
    if (!fileObject) return;
    if (this.linkedinFinalFiles && this.linkedinFinalFiles.length > 0) {
      this.linkedinFinalFiles.forEach(function (_fileObj) {
        _fileObj.isDefaultImage = SocialNetworkType.LI === _fileObj.networkType && _fileObj.file?.name === fileObject.file?.name;
      });
    }
    if (this.twitterFinalFiles && this.twitterFinalFiles.length > 0) {
      this.twitterFinalFiles.forEach(function (_fileObj) {
        _fileObj.isDefaultImage = SocialNetworkType.TW === _fileObj.networkType && _fileObj.file?.name === fileObject.file?.name;
      });
    }
    this.defaultImageOrVideoThumbnail = fileObject;
  }

  public onSchedulePostCardToggle() {
    this.isSchedulePostClicked = !this.isSchedulePostClicked;
  }

  public onSchedulePostCancel() {
    this.isSchedulePostClicked = !this.isSchedulePostClicked;
    if (this.paramScheduleId != -1) {
      window.location.href = `/#/history/share-history`;
    }
  }

  public onAreYouSureModal(inputName: string, value: boolean) {
    let myThis = this;
    $('#are-you-sure-input-type-name').val(inputName);

    switch (inputName) {
      case 'POST_SCHEDULE': {
        if (this.previewUrl == null) {
          // no preview url, so post normally
          myThis.doShare(value);
          return;
        }

        this.previewUrl.subscribe(data => {
          if (data == null) {
            // no preview url, so post normally
            myThis.doShare(value);
            return;
          }

          let _previewUrl = data[0];
          if (_previewUrl.validContentUrl &&
              _previewUrl.linkHasTitle &&
              _previewUrl.linkHasDescription &&
              _previewUrl.linkHasImage) {
            // has valid preview url, so post normally
            myThis.doShare(value);
            return;
          }

          myThis.isScheduleShareNow = value;
          const msg: string = (value === true)
            ? `Do you still want to post the message to Slack?`
            : `Do you still want to go ahead with scheduling the message to be posted on Slack?`;

          $('#areYouSureTitle').html(`URL Metadata Not Confirmed`);
          $('#areYouSureCancelBody').html(`
            <p>We were unable to confirm the metadata associated with the URL you entered and, as such, cannot guarantee it will render correctly in Slack.</p>
            <p>${msg}</p>
          `);

          $('#areYouSure').modal({
            backdrop: 'static', // remove ability to close modal with click
            keyboard: true,
            show: true // Display loader!
          });
        });

        break;
      }
    }
  }

  public onAreYouSureCancelButtonPress() {
    const inputName = $('#are-you-sure-input-type-name').val();

    switch (inputName) {
      case 'POST_SCHEDULE': {
        // nothing to-do
        break;
      }
    }
  }

  public onAreYouSureYesButtonPress() {
    const inputName = $('#are-you-sure-input-type-name').val();

    switch (inputName) {
      case 'POST_SCHEDULE': {
        this.doShare(this.isScheduleShareNow);
        break;
      }
    }
  }
}

export enum DefaultContentToDisplayInSlack {
  URL_PREVIEW= 'URL_PREVIEW',
  TWEET_CONTENT = 'TWEET_CONTENT',
  LINKEDIN_POST_CONTENT = 'LINKEDIN_POST_CONTENT'
}
