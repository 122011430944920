<div class="wrapper">
    <div class="col">
        <!-- Start Form -->
        <form class="form-signin">
            <h2 class="form-signin-heading d-flex justify-content-center" style="font-size:28px">Reactivate Your Please
                Share Account</h2>
            <hr>
            <flash-messages></flash-messages>
            <div class="form-row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="name" style="font-family: roboto,sans-serif">NAME</label>
                        <input type="text" class="form-control" name="name" id="name" value="{{name}}"
                               [disabled]="true">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="email-address" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
                <input type="email" name="email" required value="{{email}}" [disabled]="true" class="form-control"
                       id="email-address">
            </div>
            <div class="form-group">
                <label for="password" style="font-family: roboto,sans-serif">PASSWORD</label>
                <input type="password" required [(ngModel)]="password" name="password" class="form-control"
                       id="password"
                       data-toggle="popover" data-trigger="hover" data-placement="bottom"
                       data-content="My popover content."/>
            </div>
            <div class="form-group">
                <label for="cpassword" style="font-family: roboto,sans-serif">CONFIRM PASSWORD</label>
                <input type="password" name="confirmPassword" [(ngModel)]="cpassword"
                       required class="form-control"
                       id="cpassword">
            </div>

            <button class="btn btn-post cta-button" (click)="doResetPassword()" id="reactivateAdminSubmitButton" type="submit">SUBMIT</button>
        </form><!-- End Form -->
    </div><!-- End Col -->
</div>
