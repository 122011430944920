import {Component} from '@angular/core';
import {CognitoCallback} from '../service/cognito.service';
import {Router} from '@angular/router';
import {InviteUserService} from '../service/invite-user.service';

export class InviteUser {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements CognitoCallback {
  registrationUser: InviteUser;
  errorMessage: string;

  constructor(public userInvitationService: InviteUserService, public router: Router) {
    this.registrationUser = new InviteUser();
  }


  onInvite() {
    this.errorMessage = null;
    // this.userInvitationService.invite(this.registrationUser, this);
    this.router.navigate(['invite-user-success', this.registrationUser.email], { queryParamsHandling: "preserve" });
  }

  cognitoCallback(message: string, result: any) {
    if (message != null) { //error
      this.errorMessage = message;
    } else { //success
      //move to the next step
      this.router.navigate(['/home', result.user.username], { queryParamsHandling: "preserve" });
    }
  }

}
