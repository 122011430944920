<div *ngIf="userBelongsToGroup === true" style="padding: 0 40px 0 40px;" class="container-fluid">
  <!-- style="padding: 0 40px 0 40px;"-->
  <flash-messages></flash-messages>
  <div class="row page-titles" style="padding: 20px 0 20px 0;">
    <!-- Begin Page Title -->
    <div class="col-md-6 align-self-center">
      <h2>MANAGE FEEDS</h2>
    </div>
    <!-- End Page Title -->
    <!-- Begin New Source BTN -->
    <div class="col-md-6 col-4 align-self-center">
      <div class="dropdown float-xs-right float-sm-right m-r-10 hidden-sm-down">
        <button class="btn btn-post btn-sm" data-toggle="modal"
                data-target="#addFeed" data-whatever="@mdo" type="button" aria-expanded="false">
          Add New Source
        </button>
        <div id="addFeed" class="modal fade bd-example" tabindex="-1" role="dialog"
             aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal modal-dialog-centered ">
            <div class="modal-content modal-content-sources">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Select Channel Source</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body d-flex align-items-center justify-content-center">
                <button *ngIf="twitterAuthorizedForFeedSource"
                        (click)="openSocialSourceDetailedModal('addTwitterFeedDetail')"
                        type="button" class="btn source-btn source-btn-twitter mr-3" data-dismiss="modal">
                  <img alt="twitter feeds preview image"
                       src="../../assets/images/tw_feeds_preview.png"/>
                  <span>Twitter</span>
                </button>
                <button *ngIf="!twitterAuthorizedForFeedSource"
                        (click)="authorizeFeedSource('FEEDS_TW')"
                        type="button" class="btn source-btn source-btn-twitter mr-3">
                  <img alt="Twitter Feeds Authorize Image preview"
                       src="../../assets/images/tw_feeds_preview.png"/>
                  <span>Authorize Twitter</span></button>
                <button *ngIf="linkedInAuthorizedForFeedSource"
                        (click)="openSocialSourceDetailedModal('addLinkedInFeedDetail')"
                        type="button" class="btn source-btn source-btn-linkedIn mr-3" data-dismiss="modal">
                  <img alt="LinkedIn feeds Image preview"
                       src="../../assets/images/li_circle_32x32.png"/>
                  <span>LinkedIn</span>
                </button>
                <button *ngIf="!linkedInAuthorizedForFeedSource"
                        (click)="authorizeFeedSource('FEEDS_LI')"
                        type="button" class="btn source-btn source-btn-linkedIn">
                  <img alt="LinkedIn Feeds Authorize Image preview"
                       src="../../assets/images/li_circle_32x32.png"/>
                  <span>Authorize LinkedIn</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="addTwitterFeedDetail" class="modal fade" tabindex="-2" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel1">Add Twitter Feed</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="errorMessage != null && errorMessage.trim().length > 0" class="alert-danger alert alert-dismissible">
                <a href="#" class="close" data-dismiss="alert" (click)="onDismissalDialogMessageClose()"
                   aria-label="close">&times;</a>
                <div [innerHTML]="errorMessage | keepHtml"></div>
              </div>
              <form>
                <input type="hidden" placeholder="Twitter"
                       value="1" id="twitterFeedSourceType"/>
                <div class="form-group">
                  <label for="feed-source-handle" class="control-label text-muted font-13">Add
                    Handle:</label>
                  <input type="text" [(ngModel)]="feedSourceAccountName" name="feedSourceHandle"
                         class="form-control"
                         placeholder="@YourCompanyHandle"
                         id="feed-source-handle"/>
                </div>
                <div class="form-group">
                  <label class="control-label text-muted font-13"
                         for="feed-source-post-to-channel">
                    Send updates to:
                  </label>
                  <div class="ng-autocomplete w-100">
                    <ng-autocomplete id="feed-source-post-to-channel"
                                     [data]="workspaceChannels$ | async"
                                     [searchKeyword]="searchKeyword"
                                     (selected)='onModalFeedSourcePostToChannelChange($event)'
                                     [placeholder]="workspaceChannelSearchPlaceholder"
                                     (inputChanged)='onChangeSearch($event, TW_POST_TO_CHANNEL_LOADING_TYPE)'
                                     (inputCleared)="clearWorkspaceChannels()"
                                     [minQueryLength]="3"
                                     [isLoading]="autoCompleteSearchIsLoadingType === TW_POST_TO_CHANNEL_LOADING_TYPE"
                                     [focusFirst]="true"
                                     [itemTemplate]="itemTemplate"
                                     [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" (click)="clearWorkspaceChannels()">Close</button>
              <button type="button" (click)="addFeedSource('twitterFeedSourceType')"
                      class="btn btn-post pull-right">Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="addLinkedInFeedDetail" class="modal fade" tabindex="-3" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel2">Add LinkedIn Company Feed</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="errorMessage != null && errorMessage.trim().length > 0" class="alert-danger alert alert-dismissible">
                <a href="#" class="close" data-dismiss="alert" (click)="onDismissalDialogMessageClose()"
                   aria-label="close">&times;</a>
                <div [innerHTML]="errorMessage | keepHtml"></div>
              </div>
              <form>
                <input type="hidden" placeholder="LinkedIn"
                       value="2" id="linkedInFeedSourceType"/>
                <div class="form-group">
                  <label for="feed-source-handle1" class="control-label text-muted font-13">LinkedIn
                    Page
                    ID:</label>
                  <input type="text" [(ngModel)]="feedSourceAccountName" name="feedSourceHandle"
                         class="form-control"
                         id="feed-source-handle1"/>
                </div>
                <div class="form-group">
                  <label class="control-label text-muted font-13"
                         for="feed-source-post-to-channel1">
                    Send updates to:
                  </label>
                  <div class="ng-autocomplete w-100">
                    <ng-autocomplete id="feed-source-post-to-channel1"
                                     [data]="workspaceChannels$ | async"
                                     [searchKeyword]="searchKeyword"
                                     (selected)='onModalFeedSourcePostToChannelChange($event)'
                                     [placeholder]="workspaceChannelSearchPlaceholder"
                                     (inputChanged)='onChangeSearch($event, LI_POST_TO_CHANNEL_LOADING_TYPE)'
                                     (inputCleared)="clearWorkspaceChannels()"
                                     [minQueryLength]="2"
                                     [isLoading]="autoCompleteSearchIsLoadingType === LI_POST_TO_CHANNEL_LOADING_TYPE"
                                     [focusFirst]="true"
                                     [itemTemplate]="itemTemplate"
                                     [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" (click)="clearWorkspaceChannels()">Close</button>
              <button type="button" (click)="addFeedSource('linkedInFeedSourceType')"
                      class="btn btn-post pull-right">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Add New Source BTN -->

  </div>
  <!-- Start Page Content -->

  <div class="row">
    <div class="col-md-12" *ngFor="let _feedSource of feedSources$ | async">
      <div class="card card-body settings-top stats-box">
        <div class="row">
          <div *ngIf="_feedSource.feedAccountType === '1'"
               class="col col-lg-6 col-xs-12 ml-xs-0 col-sm-12 pb-1">
            <img alt="twitter" src="../../assets/images/tw_circle_32x32.png" style="height: 36px;">
            <span class="section-header">TWITTER</span>
          </div>
          <div *ngIf="_feedSource.feedAccountType === '2'"
               class="col col-lg-6 col-xs-12 ml-xs-0 col-sm-12 pb-1">
            <img alt="linkedin" src="../../assets/images/li_circle_32x32.png" style="height: 36px;">
            <span class="section-header">LINKEDIN</span>
          </div>
          <div class="col col-6 pb-1">
            <div class="dropdown float-lg-right text-center hidden-sm-down">
              <button *ngIf="_feedSource.feedAccountType === '1' && !twitterAuthorizedForFeedSource"
                      (click)="authorizeFeedSource('FEEDS_TW')"
                      class="btn btn-danger btn-sm mr-2" type="button"
                      aria-expanded="false">
                Refresh Token
              </button>
              <button *ngIf="_feedSource.feedAccountType === '2' && !linkedInAuthorizedForFeedSource"
                      (click)="authorizeFeedSource('FEEDS_LI')"
                      class="btn btn-danger btn-sm mr-2" type="button"
                      aria-expanded="false">
                Refresh Token
              </button>
              <button (click)="showDeleteFeedSourceModal(_feedSource.feedAccountType, _feedSource.feedAccountName)"
                      class="btn btn-delete btn-sm" type="button"
                      aria-expanded="false">
                Delete Feed
              </button>
            </div>
          </div>
        </div>
        <hr>
        <form class="form-horizontal row" role="form">
          <div class="form-body col-xl-7 ">
            <!--/row-->
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="_feedSource.feedAccountType==='1'" class="form-group row">
                  <label
                    class="control-label col-md-5 text-muted font-13 font-weight-bold">Twitter
                    handle:</label>
                  <div class="col-md-6">
                    <p class="form-control-static"> {{_feedSource.feedAccountName}} <span><i
                      class="fa fa-check-circle"
                      style="color:#58CE87;"></i></span></p>
                  </div>
                </div>
                <div *ngIf="_feedSource.feedAccountType==='2'" class="form-group row">
                  <label
                    class="control-label col-md-5 text-muted font-13 font-weight-bold">LinkedIn
                    account:</label>
                  <div class="col-md-6">
                    <p class="form-control-static"> {{_feedSource.linkedInCompanyName}} <span><i
                      class="fa fa-check-circle"
                      style="color:#58CE87;"></i></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--/span-->
            <!--/row-->
            <div class="row pt-2">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold">
                    Post to channel:
                  </label>
                  <div class="col-md-6">
                    <p class="form-control-static">#{{_feedSource.feedPostToChannelName}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold"
                         for="post-to-channel">
                    Select new channel:
                  </label>
                  <div class="ng-autocomplete col-md-4 pl-0 d-flex w-100">
                    <ng-autocomplete id="post-to-channel"
                                     [data]="workspaceChannels$ | async"
                                     [searchKeyword]="searchKeyword"
                                     (selected)='doChangeWorkspaceChannel($event, _feedSource)'
                                     [placeholder]="workspaceChannelSearchPlaceholder"
                                     (inputChanged)='onChangeSearch($event, POST_TO_CHANNEL_LOADING_TYPE)'
                                     (inputCleared)="clearWorkspaceChannels()"
                                     [minQueryLength]="3"
                                     [isLoading]="autoCompleteSearchIsLoadingType === POST_TO_CHANNEL_LOADING_TYPE"
                                     [focusFirst]="true"
                                     [itemTemplate]="itemTemplate"
                                     [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold">
                    Remove post after:
                  </label>
                  <div class="col-md-4 pl-0 d-flex w-100">
                    <select id="automatically-delete-after-{{_feedSource.id}}"
                            class="form-control-sm custom-select"
                            name="{{_feedSource.id}}"
                            (change)="onAutomaticallyDeleteAfterXDaysSettingsChange($event.target['value'], _feedSource.feedAccountType, _feedSource.feedAccountName)">
                      <option *ngFor="let day of automaticallyDeleteAfterDays"
                              [attr.selected]="day === _feedSource.automaticallyDeleteAfterXDays? '':null"
                              value="{{day}}">
                        {{day === -1 ? 'Do not remove' : day}} {{day !== -1 ? (day !== 1) ? 'days' : 'day' : ''}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-1 pt-2 pl-2">
                    <a href="javascript:void(0)">
                      <span
                        class="fa fa-question-circle fa-lg"
                        ngbTooltip='To keep employees focused on the latest "engagement" requests, set a schedule to automatically remove posts from Slack.'
                        container="body"
                        placement="right"
                        style="color: #ced4da">
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold">Last
                    updated:</label>
                  <div class="col-md-4  pl-0 d-flex w-100">
                    <div class=" pt-2" *ngIf="(tzInfo$ | async) as tzInfo"
                         style=" padding-left: 0; padding-right: 0; width: 260px ;">
                      <p class="form-control-static"> {{_feedSource.lastUpdated | date: "MMM d, y h:mm:ss a":tzInfo.shortName}} {{tzInfo.shortName}}</p>
                    </div>
                  </div>
                  <div class="col-md-1 pt-2 pl-2">
                    <a href="javascript:void(0)">
                      <span class="fa fa-question-circle fa-lg"
                            ngbTooltip='This is the last time Please Share successfully fetched a new update from {{_feedSource.feedAccountType === "1"? "this Twitter handle":"your LinkedIn account"}}.'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-3"
                 *ngIf="currentUserProfile.displayFeedEngagementPostHeaderSection && currentUserProfile.displayFeedEngagementPostHeaderSection === true">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold">Post Header:</label>
                  <div class="col-md-4  pl-0 d-flex w-100">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineDisplayFeedEngagementRadioOptions"
                             [checked]="_feedSource.feedPostHeaderTextType == 1"
                             (click)="onFeedPostHeaderTextTypeChange(_feedSource.feedAccountType, _feedSource.feedAccountName, 1)"
                             id="default_{{_feedSource.id}}" value="1">
                      <label class="form-check-label" for="default_{{_feedSource.id}}">Default</label>
                    </div>
                    <div class="form-check form-check-inline" *ngIf="_feedSource.feedAccountType === '1'">
                      <input class="form-check-input" type="radio" name="inlineDisplayFeedEngagementRadioOptions"
                             [checked]="_feedSource.feedPostHeaderTextType == 2"
                             (click)="onFeedPostHeaderTextTypeChange(_feedSource.feedAccountType, _feedSource.feedAccountName, 2)"
                             id="tweet_url_{{_feedSource.id}}" value="2">
                      <label class="form-check-label" for="tweet_url_{{_feedSource.id}}">Tweet URL</label>
                    </div>
                    <div class="form-check form-check-inline" *ngIf="_feedSource.feedAccountType === '2'">
                      <input class="form-check-input" type="radio" name="inlineDisplayFeedEngagementRadioOptions"
                             [checked]="_feedSource.feedPostHeaderTextType == 3"
                             (click)="onFeedPostHeaderTextTypeChange(_feedSource.feedAccountType, _feedSource.feedAccountName, 3)"
                             id="linkedin_url_{{_feedSource.id}}" value="2">
                      <label class="form-check-label" for="linkedin_url_{{_feedSource.id}}">LinkedIn Post URL</label>
                    </div>
                  </div>
                  <div class="col-sm-1 pt-2 pl-2">
                    <a href="javascript:void(0)">
                      <span *ngIf="_feedSource.feedAccountType === '1'" class="fa fa-question-circle fa-lg"
                            ngbTooltip='Tweet URL will display the URL of the tweet in the header section instead of the default "New tweet from Company Name."'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                      </span>
                      <span *ngIf="_feedSource.feedAccountType === '2'" class="fa fa-question-circle fa-lg"
                            ngbTooltip='LinkedIn URL will display the URL of the post in the header section instead of the default "New LinkedIn Post from Company Name."'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="form-group row">
                  <label
                    class="control-label col-md-5 text-muted font-13 font-weight-bold">{{collaborationPlatformName$ | titlecase}}
                    notification
                    type:</label>
                  <div class="col-md-4  pl-0 d-flex w-100">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions"
                             [checked]="_feedSource.notificationType == 1"
                             (click)="changeChannelNotificationSettingOption(_feedSource.feedAccountType, _feedSource.feedAccountName, 1)"
                             id="@channel_{{_feedSource.id}}" value="1">
                      <label class="form-check-label" for="@channel_{{_feedSource.id}}">@channel</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions"
                             [checked]="_feedSource.notificationType == 2"
                             (click)="changeChannelNotificationSettingOption(_feedSource.feedAccountType, _feedSource.feedAccountName, 2)"
                             id="@here_{{_feedSource.id}}" value="2">
                      <label class="form-check-label" for="@here_{{_feedSource.id}}">@here</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions"
                             [checked]="_feedSource.notificationType == -1"
                             (click)="changeChannelNotificationSettingOption(_feedSource.feedAccountType, _feedSource.feedAccountName, -1)"
                             id="none_{{_feedSource.id}}" value="-1">
                      <label class="form-check-label" for="none_{{_feedSource.id}}">None</label>
                    </div>
                  </div>
                  <div class="col-md-1 pl-lg-2 align-self-start">
                    <a href="javascript:void(0)">
                      <span class="fa fa-question-circle fa-lg"
                            ngbTooltip='@channel notifies everyone in a channel about a new update whereas @here only notifies those currently using Slack.'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="form-group row">
                  <label
                    class="control-label col-md-5 text-muted font-13 font-weight-bold">Send
                    to {{collaborationPlatformName$ | titlecase}}:</label>
                  <div class="col-md-4 pl-0">
                    <label class="label-switch switch-success">
                      <input
                        (click)="toggleSendToCollaborationPlatformOption(_feedSource.feedAccountType, _feedSource.feedAccountName, !_feedSource.sendToCollaborationPlatform)"
                        type="checkbox" class="switch switch-bootstrap status"
                        name="status" value="1"
                        [attr.checked]="_feedSource.sendToCollaborationPlatform ? 'checked' : null">
                      <span class="label"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="_feedSource.feedAccountType === '2'" class="row pt-3">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="control-label col-md-5 text-muted font-13 font-weight-bold">Include carousel posts:</label>
                  <div class="col-md-4 pl-0">
                    <label class="label-switch switch-success">
                      <input type="checkbox" class="switch switch-bootstrap status"
                             (click)="doToggleLnFeedCarousel(_feedSource.id, !_feedSource.carouselEnabled)"
                             [attr.checked]="_feedSource.carouselEnabled?'checked':null"
                             name="status" value="1">
                      <span class="label"></span>
                    </label>
                  </div>
                  <div class="col-md-1 pl-lg-2 align-self-start">
                    <a href="javascript:void(0)">
                      <span class="fa fa-question-circle fa-lg"
                            ngbTooltip='Since posts that include carousels can be used for ads, please confirm that you want to send these types of post to Slack.'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- End ROW -->
  </div>
</div>

<div class="modal fade" id="deleteFeedSource" tabindex="-1" role="dialog" aria-labelledby="deleteFeedSource"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="errorMessage != null && errorMessage.trim().length > 0" class="alert-danger alert alert-dismissible">
        <a href="#" class="close" data-dismiss="alert" (click)="onDismissalDialogMessageClose()"
           aria-label="close">&times;</a>
        <div [innerHTML]="errorMessage | keepHtml"></div>
      </div>
      <div class="modal-body">
        Are you sure you want to delete this feed source?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary delete-feed-source-btn-no" data-dismiss="modal">No
        </button>
        <button type="button" (click)="doDeleteFeedSource()"
                class="btn btn-success btn-primary delete-feed-source-btn-yes">Yes
        </button>
      </div>
    </div>
  </div>
</div>
