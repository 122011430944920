import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoUtil, LoggedInCallback} from '../service/cognito.service';
import {UserLoginService} from '../service/user-login.service';
import {SettingsService} from '../service/settings.service';
import {UserProfileService} from '../service/user-profile.service';
import {NavbarService} from '../service/navbar.service';
import {UserProfile} from '../users/UserProfile';
import {PsSubscriptionPlanService} from "../service/ps_subscription_plan.service";

declare var $: any;

@Component({
    selector: '[id=content-wrapper]',
    templateUrl: './cancel-subscription.component.html',
    styleUrls: ['./cancel-subscription.component.scss']
})
export class CancelSubscriptionComponent implements OnInit, LoggedInCallback {
    public currentUserProfile: UserProfile;
    public userBelongsToGroup: boolean;
    public successfulCancelMessage: string;
    public errorMessage: string;

    constructor(public nav: NavbarService,
                private http: HttpClient,
                private router: Router,
                public cognitoUtil: CognitoUtil,
                private activatedRoute: ActivatedRoute,
                private auth: UserLoginService,
                private settingsService: SettingsService,
                private userProfileService: UserProfileService,
                private subscriptionPlanService: PsSubscriptionPlanService) {
        this.auth.isAuthenticated(this);
    }

    ngOnInit() {
        // Hide the Navbar component
        setTimeout(() => {
            this.nav.hide();
        }, 0);

        let myThis = this;
        this.userProfileService.loggedInUserProfile.subscribe(profile => {
            myThis.currentUserProfile = profile;
            if (profile == undefined || !profile.hasGroup) {
                myThis.userBelongsToGroup = false;
                return;
            } else {
                myThis.userBelongsToGroup = this.currentUserProfile.hasGroup;
            }
        });

        if (!myThis.userBelongsToGroup) return;
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.auth.logout();
            window.location.href = '/#/login';
        } else {
            this.userProfileService.changeProfile(JSON.parse(this.activatedRoute.snapshot.data['userProfile']));
        }
    }

    doCancelSubscription() {
        if (this.currentUserProfile.enterprisePlanUser) {
            return;
        }
        let myThis = this;
        myThis.subscriptionPlanService
            .doCancelSubscription()
            .then(function (result: any) {
                    if (result.success) {
                        let currentUser = myThis.cognitoUtil.getCurrentUser();
                        if (!currentUser) return;
                        let userName = currentUser.getUsername();
                        let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
                        let groupName = profile.groupName;
                        myThis.userProfileService
                            .doGetUserProfileByGroupName(groupName)
                            .then(function (_profile: UserProfile) {
                                localStorage.removeItem(userName);
                                profile.paidUser = _profile.paidUser;
                                profile.nextPaymentDate = _profile.nextPaymentDate;
                                profile.currentPlanName = _profile.currentPlanName;
                                profile.currentPlanPricing = _profile.currentPlanPricing;
                                profile.currentPlanCurrency = _profile.currentPlanCurrency;
                                profile.paymentMethodEditable = _profile.paymentMethodEditable;
                                profile.subscriptionCancelable = _profile.subscriptionCancelable;
                                profile.nextPaymentDate = _profile.nextPaymentDate;
                                localStorage.setItem(userName, JSON.stringify(profile));
                                myThis.userProfileService.changeProfile(profile);
                                myThis.successfulCancelMessage = result.data;
                                myThis.router.navigate(['/settings/account-settings'], {
                                    queryParams: {queryMsg: result.data},
                                    queryParamsHandling: "merge"
                                });
                            })
                    } else {
                        let errorMessage = ""
                        if (result.errors) {
                            result.errors.forEach(function (e) {
                                errorMessage += e.message
                            });
                            myThis.errorMessage = errorMessage;
                        }
                    }
                }
            )
    }

    public closeAndResetErrorMessage() {
        this.errorMessage = null;
    }

    public closeAndResetCancelSuccessMessage() {
        this.successfulCancelMessage = null;
    }
}
