<div class="wrapper" style="width:100%; padding: 40px 20px 20px 60px;">
  <div class="d-flex justify-content-center align-self-center">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-12" style="padding-bottom: 40px;">
        <div class="form-payment table-box">
          <h2 class="form-payment-heading d-flex justify-content-center">Subscription Activated</h2>
          <hr>
          <!-- Column -->
          <div class="col-md-12">
            <div class="frb-group">
              <div class="frb frb-ps">
                <p>Congratulations! Your subscription payment was successfully processed.</p>

                <p>You can continue to use Please Share to encourage team members to promote noteworthy content on
                  Twitter, LinkedIn and Facebook.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div>
          <form style="max-width: 580px;margin: 0 auto;">
            <div class="frb frb-ps">
              <div class="float-left">
                <a routerLink="/settings/account-settings"> Back to settings page </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
