import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CognitoUtil} from '../service/cognito.service';
import {environment} from '../../environments/environment';
import {UserProfile} from "../users/UserProfile";
import {UserLoginService} from "../service/user-login.service";


@Injectable()

export class PleaseShareInterceptor implements HttpInterceptor {

  public cognitoUtil: CognitoUtil;


  constructor(cognitoUtil: CognitoUtil, public auth: UserLoginService) {
    this.cognitoUtil = cognitoUtil;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // If the url is not publicly accessible then authenticate
    let locationHref = window.location.href;

    if (locationHref.indexOf('user/setup/') >= 0 || !this.isUrlPubliclyAccessible(environment.publicUrlPath, locationHref)) {
      let myThis = this;
      this.cognitoUtil.getIdAndAccessTokenToken({
        callback() {},
        callbackWithParam(idToken: any) {
          return next.handle(request);
        },
        callbackWithParams(idToken: any, accessToken: any) {
          // modify request
          request = request.clone({
            setHeaders: {
              Authorization: idToken
            }
          });
          let currentUser = myThis.cognitoUtil.getCurrentUser();
          if (currentUser) {
            let userName = currentUser.getUsername();
            let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
            if (profile && !profile.currencyConvertedValue) {
              myThis.auth.logout();
              window.location.reload();
            }
          }
          let profile: any = JSON.parse(localStorage.getItem(currentUser.getUsername()));
          if (profile !== undefined && profile !== null && profile.accountId) {
            request = request.clone({
              setHeaders: {
                accountId: profile.accountId.toString(),
                collaborationPlatformName: profile.collaborationPlatformName
              }
            });
          }
          return next.handle(request)
            .pipe(
              tap(event => {
                if (event instanceof HttpResponse) {

                }
              }, error => {
                // http response status code
              })
            );
        }
      });
    }
    return next.handle(request);
  };

  isUrlPubliclyAccessible(publicUrlPath: string[], href: string) {
    for (let i in publicUrlPath) {
      if (href.indexOf(publicUrlPath[i]) >= 0) {
        return true;
      }
    }
    return false;
  }
}
