<header class="header">
  <div class="branding">
    <div class="container position-relative">
      <nav class="navbar navbar-expand-lg">
        <img class="logo-icon" style="height: 45px;" src="../../../../assets/images/ps_beta_logo.png" alt="logo">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
                aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav>
    </div>
  </div>
</header>
<div class="page-header text-center py-5 feature-item">
  <div class="container">
    <h2 class="page-title pt-2 mb-3 font-weight-bold">Twitter Authorization Successful</h2>
  </div>
</div>
<section class="pt-5">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-lg-10 pl-lg-5">
        <div class="mb-3" style="font-size: 20px">Congratulations! You have successfully connected your Twitter
          account
          with Please Share.<br><br>Should you have questions on how to use the service, you can access our support
          page
          by selecting "Help and tutorials" from the menu that appears in every Please Share request.<br><br>Thank you
          for participating in our beta program.<br><br>The Please Share Team
        </div>
      </div>
    </div>
  </div>
</section>
<div class="w-100 py-3"></div>
<section style="min-height: 600px"></section>
<section class="cta-section py-5 theme-bg-secondary text-center">
  <div class="container">
  </div>
</section>
<footer class="footer theme-bg-primary">
  <div class="container">
  </div>
  <div class="download-area pt-2 pb-4">
    <div class="container text-center">
      <div class="section-intro my-4 single-col-max-width mx-auto">Please Share &copy; Copyright {{currentYear - 1}} - {{currentYear}}
      </div>
    </div>
  </div>
</footer>

