import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ContainerEvents, FileObject, FileObjectStatus, SocialNetworkType,} from './types';
import {UploadService} from './upload-service';
import {Subscription} from 'rxjs';
import {CognitoUtil} from "../../service/cognito.service";
import {environment} from "../../../environments/environment";

/**
 * Single file upload component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-file-upload',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class FileUploadComponent implements OnDestroy {
  @Output() defaultImageOrVideoThumbnailChange: EventEmitter<FileObject> = new EventEmitter<FileObject>();
  @Input() twitterFileObject: FileObject;
  @Input() linkedInFileObject: FileObject;
  @Input() oddRow: boolean;
  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  uploadHandle: any;

  constructor(private uploadService: UploadService,
              private cognitoUtil: CognitoUtil
  ) {
    this.containerEventSubscription = uploadService.uploadContainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
  }


  private handleContainerEvent(containerEvent: ContainerEvents) {
    if (containerEvent === ContainerEvents.TwitterUpload && this.twitterFileObject) {
      return this.twitterFileObject.status === FileObjectStatus.NotStarted && this.twitterUpload();
    } else if (containerEvent === ContainerEvents.TwitterCancel && this.twitterFileObject) {
      return this.twitterFileObject.status === FileObjectStatus.Uploading && this.cancel();
    } else if (containerEvent === ContainerEvents.TwitterDelete || containerEvent === ContainerEvents.LinkedInDelete) {
      return this.clear();
    } else if (containerEvent === ContainerEvents.LinkedInUpload && this.linkedInFileObject) {
      return this.linkedInFileObject.status === FileObjectStatus.NotStarted && this.linkedInUpload();
    } else if (containerEvent === ContainerEvents.LinkedInCancel) {
      return this.linkedInFileObject && this.linkedInFileObject.status === FileObjectStatus.Uploading && this.cancel();
    }
  }

  upload() {
    if (this.twitterFileObject && this.twitterFileObject.networkType === SocialNetworkType.TW) {
      this.twitterUpload()
    } else if (this.linkedInFileObject && this.linkedInFileObject.networkType === SocialNetworkType.LI) {
      this.linkedInUpload()
    }
  }

  twitterUpload() {
    if (!this.twitterFileObject.isS3Object) return;
    this.twitterFileObject.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    const idTokenJwt = this.cognitoUtil.idToken;
    const uploadPrefix = [environment.uploadKeyPrefix, this.cognitoUtil.getCurrentUser().getUsername(), "tw"].join('/');
    this.twitterFileObject.uploadKeyPrefix = uploadPrefix;
    this.uploadHandle = this.uploadService.upload(
      this.twitterFileObject.file, idTokenJwt, uploadPrefix, this.cognitoUtil.getCurrentUser().getUsername(),
      environment.region, environment.userPoolId, environment.identityPoolId, this.handleTwitterS3UploadProgress());
  }

  linkedInUpload() {
    if (!this.linkedInFileObject.isS3Object) return;
    this.linkedInFileObject.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    const idTokenJwt = this.cognitoUtil.idToken;
    const uploadPrefix = [environment.uploadKeyPrefix, this.cognitoUtil.getCurrentUser().getUsername(), "li"].join('/');
    this.linkedInFileObject.uploadKeyPrefix = uploadPrefix;
    this.uploadHandle = this.uploadService.upload(
      this.linkedInFileObject.file, idTokenJwt, uploadPrefix, this.cognitoUtil.getCurrentUser().getUsername(),
      environment.region, environment.userPoolId, environment.identityPoolId, this.handleLinkedInS3UploadProgress());
  }

  public onDefaultImageOrVideThumbClick(fileObject: FileObject) {
    this.defaultImageOrVideoThumbnailChange.emit(fileObject);
  }

  private handleTwitterS3UploadProgress() {
    return (error: Error, progress: number, speed: number) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.twitterFileObject.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {
          this.twitterFileObject.status = FileObjectStatus.Uploaded;
        }
      }
    };
  }

  private handleLinkedInS3UploadProgress() {
    return (error: Error, progress: number, speed: number) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.linkedInFileObject.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {
          this.linkedInFileObject.status = FileObjectStatus.Uploaded;
        }
      }
    };
  }

  cancel() {
    if (this.twitterFileObject && this.twitterFileObject.status === FileObjectStatus.Uploading) {
      this.twitterFileObject.status = FileObjectStatus.Canceled;
      this.uploadService.cancel(this.uploadHandle);
    } else if (this.linkedInFileObject && this.linkedInFileObject.status === FileObjectStatus.Uploading) {
      this.linkedInFileObject.status = FileObjectStatus.Canceled;
      this.uploadService.cancel(this.uploadHandle);
    }
  }

  clear() {
    const idTokenJwt = this.cognitoUtil.idToken;
    if (this.twitterFileObject && this.twitterFileObject.status !== FileObjectStatus.Uploading) {
      this.twitterFileObject.status = FileObjectStatus.Deleted;
      this.uploadService.publishFileUploadEvent(this.twitterFileObject);
      let deleteCallback = function (error: any, _: any) {
        if (!error) {
          console.log("S3 Object Deleted successfully")
        } else {
          console.log(JSON.stringify(error))
        }
      }
      this.uploadService.delete(this.twitterFileObject.file, idTokenJwt, this.twitterFileObject.uploadKeyPrefix, this.cognitoUtil.getCurrentUser().getUsername(),
        environment.region, environment.userPoolId, environment.identityPoolId, deleteCallback)
    } else if (this.linkedInFileObject && this.linkedInFileObject.status !== FileObjectStatus.Uploading) {
      this.linkedInFileObject.status = FileObjectStatus.Deleted;
      this.uploadService.publishFileUploadEvent(this.linkedInFileObject);
      let deleteCallback = function (error: any, _: any) {
        if (!error) {
          console.log("S3 Object Deleted successfully")
        } else {
          console.log(JSON.stringify(error))
        }
      }
      this.uploadService.delete(this.linkedInFileObject.file, idTokenJwt, this.linkedInFileObject.uploadKeyPrefix, this.cognitoUtil.getCurrentUser().getUsername(),
        environment.region, environment.userPoolId, environment.identityPoolId, deleteCallback)
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.containerEventSubscription.unsubscribe();
  }
}
