<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
  <a id="lsb-dashboard" class="nav-link" routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
     aria-expanded="false">
    <i class="fa fa-telegram fa-4x"></i>
    <span class="hide-menu"> Add New Update</span>
  </a>
</li>
<li id="accordion" routerLinkActive="active">
  <div class="nav-item" id="headingOne">
    <a id="lsb-reporting" class="nav-link" data-toggle="collapse" routerLinkActive="active" data-target="#collapseOne"
       aria-expanded="false"
       aria-controls="collapseOne" style="cursor: pointer;">
      <i class="fa fa-bar-chart-o fa-4x"></i>
      <span>Reporting</span>
    </a>
  </div>

  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
    <ul class="sub-links">
      <li class="m-2"><a id="lsb-reporting-dashboard" class="text-white" routerLink="/reporting/dashboard">Overview</a></li>
      <li class="m-2"><a id="lsb-reporting-er" class="text-white" routerLink="/reporting/engagement-request">Engagement Requests</a></li>
      <li class="m-2"><a id="lsb-reporting-sr" class="text-white" routerLink="/reporting/share-request">Share Requests</a></li>
      <li class="m-2"><a id="lsb-reporting-leaderboard" class="text-white" routerLink="/reporting/leaderboard">Leaderboard</a></li>
      <li *ngIf="UserLoginService.isWorkspaceStartsWith(currentUserProfile, 'godaddy')" class="m-2">
        <a id="lsb-reporting-shr" class="text-white" routerLink="/reporting/share-history-report">Share History Report</a>
      </li>
      <li class="m-2"><a id="lsb-reporting-conf" class="text-white" routerLink="/reporting/configuration">Configuration</a></li>
    </ul>
  </div>
</li>
<li id="historyAccordion" routerLinkActive="active">
  <div class="nav-item" id="historyHeadingOne">
    <a id="lsb-history" class="nav-link" data-toggle="collapse" routerLinkActive="active" data-target="#historyCollapseOne"
       aria-expanded="false"
       aria-controls="collapseOne" style="cursor: pointer;">
      <i class="fa fa-history fa-4x"></i>
      <span>History</span>
    </a>
  </div>

  <div id="historyCollapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#historyAccordion">
    <ul class="sub-links">
      <li class="m-2"><a id="lsb-history-eh" class="text-white" routerLink="/history/engagement-history">Engagement History</a></li>
      <li class="m-2"><a id="lsb-history-sh" class="text-white" routerLink="/history/share-history">Share History</a></li>
    </ul>
  </div>
</li>
<li id="feeds" class="nav-item">
  <a id="lsb-feeds" class="nav-link" routerLink="feeds" routerLinkActive="no-active" aria-expanded="false">
    <i class="fa fa-feed fa-4x"></i>
    <span>Feeds</span>
  </a>
</li>
<li id="campaign-accordion" routerLinkActive="active">
  <div class="nav-item" id="campaign-headingOne">
    <a id="lsb-campaign" class="nav-link" data-toggle="collapse" routerLinkActive="active" data-target="#campaign-collapseOne"
       aria-expanded="false"
       aria-controls="collapseOne" style="cursor: pointer;">
      <i class="fa fa-trophy fa-4x" aria-hidden="true"></i>
      <span>Campaigns <sup><span class="superscript">beta</span></sup></span>
    </a>
  </div>

  <div id="campaign-collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
    <ul class="sub-links">
      <li class="m-2"><a id="lsb-campaign-history" class="text-white" routerLink="/campaign/history">Campaign History</a></li>
      <li class="m-2"><a id="lsb-campaign-new" class="text-white" routerLink="/campaign/new">New Campaign</a></li>
    </ul>
  </div>
</li>

<li id="settings" routerLinkActive="active">
  <div class="nav-item" id="settings-heading-one">
    <a id="lsb-settings" class="nav-link" data-toggle="collapse" routerLinkActive="active" data-target="#settings-collapse-one"
       aria-expanded="false"
       aria-controls="collapseOne" style="cursor: pointer;">
      <i class="fa fa-bar-chart-o fa-4x"></i>
      <span>Settings</span>
    </a>
  </div>

  <div id="settings-collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#settings">
    <ul class="sub-links">
      <li class="m-2"><a id="lsb-settings-acc-settings" class="text-white" routerLink="/settings/account-settings">Account Settings</a></li>
      <li class="m-2"><a id="lsb-settings-acc-admins" class="text-white" routerLink="/settings/account-admins">Account Admins</a></li>
      <li class="m-2"><a id="lsb-settings-saml-settings" class="text-white" routerLink="/settings/saml-settings">SAML Settings</a></li>
    </ul>
  </div>
</li>
<li *ngIf="UserLoginService.isRoleSuperAdmin(currentUserProfile)" id="super-admin-settings" routerLinkActive="active">
  <div class="nav-item" id="super-admin-settings-heading-one">
    <a id="lsb-super-admin-settings" class="nav-link" data-toggle="collapse" routerLinkActive="active" data-target="#super-admin-settings-collapse-one"
       aria-expanded="false"
       aria-controls="collapseOne" style="cursor: pointer;">
      <i class="fa fa-bar-chart-o fa-4x"></i>
      <span>Super Admin Settings</span>
    </a>
  </div>

  <div id="super-admin-settings-collapse-one" class="collapse" aria-labelledby="headingOne" data-parent="#super-admin-settings">
    <ul class="sub-links">
      <li class="m-2"><a id="lsb-super-admin-settings-account-settings" class="text-white" routerLink="/super-admin-settings/account-settings">Account Settings</a></li>
      <li class="m-2"><a id="lsb-super-admin-settings-campaign-settings" class="text-white" routerLink="/super-admin-settings/campaign-settings">Campaign Settings</a></li>
      <li class="m-2"><a id="lsb-super-admin-settings-share-history-report" class="text-white" routerLink="/super-admin-settings/share-history-report">Share History Report</a></li>
    </ul>
  </div>
</li>
<div class="hor-divider"></div>
