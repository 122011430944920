<div class="content-wrapper">
    <div *ngIf="errorMessage" class="d-flex justify-content-center align-self-center">
        <div [innerHTML]="errorMessage" class="alert-danger alert alert-dismissible d-flex justify-content-center"
             style="width: 100%"></div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col">
            <!-- Start Form -->
            <form class="form-signin">
                <h2 class="form-signin-heading d-flex justify-content-center">Register for Please Share</h2>
                <hr>
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name" style="font-family: roboto,sans-serif">NAME</label>
                            <input type="text" class="form-control" name="name" id="name" value="{{name}}"
                                   [disabled]="true">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email-address" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
                    <input type="email" name="email" required value="{{invitedEmail}}" [disabled]="true"
                           class="form-control"
                           id="email-address">
                </div>
                <div class="form-group">
                    <label for="password" style="font-family: roboto,sans-serif">PASSWORD</label>
                    <input type="password" required [(ngModel)]="password" (blur)="doValidatePassword($event)"
                           name="password"
                           class="form-control" id="password">
                </div>
                <div class="form-group">
                    <label for="cpassword" style="font-family: roboto,sans-serif">CONFIRM PASSWORD</label>
                    <input type="password" name="confirmPassword" [(ngModel)]="cpassword"
                           (blur)="doValidatePassword($event)"
                           required class="form-control"
                           id="cpassword">
                </div>

                <button class="btn btn-post cta-button" (click)="doSignupAsInvitedAdmin()" type="submit">Sign Up</button>
            </form><!-- End Form -->
        </div><!-- End Col -->
        <div class="col"></div>
    </div><!-- End Row -->
</div><!-- End Wrapper -->

<div class="modal fade" id="signupAsInvitedAdminSuccessDialog" tabindex="-1" role="dialog"
     aria-labelledby="signupAsInvitedAdminSuccessDialog"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-center">
                    Great job! Just one last step before getting started. Please check your inbox and confirm your email
                    address.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary cancel-subscription-plan-btn-no" data-dismiss="modal">OK
                </button>
            </div>
        </div>
    </div>
</div>
