import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {CognitoUtil} from './cognito.service';
import {environment} from '../../environments/environment';

@Injectable()
export class ReportingOverviewDashboardService {

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtil) {

  }

  public getMonthlyPerformanceStatistics(eventType: string, requestType: number, month: number, year: number) {
    const url = `${environment.apiRoot}/reporting/` + eventType + `/` + requestType + `/` + month + `/` + year;
    const myThis = this;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders
        .set('Content-Type', 'application/json');

      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data.data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
