<div class="wrapper">
  <div class="row">
    <div class="col">
      <div>
        <div class="col">
          <!-- Start Form -->
          <form class="form-signin">
            <h2 *ngIf="isValidBetaAccessKey" class="form-signin-heading d-flex justify-content-center">Create a New
              Account</h2>
            <div class="form-group">
              <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible">
                <button type="button" class="close" (click)="closeAndResetErrorMessage()" data-hide="alert">&times;
                </button>
                <div [innerHTML]="errorMessage | keepHtml"></div>
              </div>
            </div>
            <div class="form-group" *ngIf="!isValidBetaAccessKey">
              <h2 class="form-signin-heading d-flex justify-content-center">Registration Step 1</h2>
            </div>
            <div class="form-group" *ngIf="!isValidBetaAccessKey">
              <hr>
              <div>
                <small class="form-control-feedback" style="color: grey">
                  Registration is currently limited to companies that have received a beta access key.
                  If you are interested in joining our beta program, you can submit a request
                  <a href="https://pleaseshare.co/request_access.html">here</a>.
                </small>
              </div>
              <br/>
              <label for="betaRegistrationKey">Beta Access Key</label>
              <input [(ngModel)]="registrationUser.betaRegistrationKey" type="text" name="betaRegistrationKey"
                     class="form-control" id="betaRegistrationKey"/>
            </div>
            <div class="form-group" *ngIf="!isValidBetaAccessKey">
              <label for="slackworkspaceName">Slack Workspace Name</label>
              <div class="form-row" *ngIf="!isValidBetaAccessKey">
                <div class="col-md-8">
                  <input type="text" name="slackWorkspaceName" [(ngModel)]="registrationUser.slackWorkspaceName"
                         class="form-control mb-2 mr-sm-2"
                         id="slackworkspaceName" placeholder="example">
                </div>
                <div class="col-md-4">
                  <div class="input-group">
                    <div class="input-group-text form-control mb-2">.slack.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="isValidBetaAccessKey">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fname" style="font-family: roboto,sans-serif">FIRST NAME</label>
                  <input [disabled]="onBetaKeyChangeCheckLength()"
                         type="text" [(ngModel)]="registrationUser.firstName" name="firstName"
                         class="form-control" id="fname">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lname" style="font-family: roboto,sans-serif">LAST NAME</label>
                  <input [disabled]="onBetaKeyChangeCheckLength()"
                         [(ngModel)]="registrationUser.lastName" type="text" name="lastName"
                         class="form-control" id="lname">
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="isValidBetaAccessKey">
              <label for="email" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
              <input [disabled]="onBetaKeyChangeCheckLength()"
                     type="email" [(ngModel)]="registrationUser.email" name="email" required
                     class="form-control" id="email">
            </div>
            <div class="form-group position-relative" *ngIf="isValidBetaAccessKey">
              <label for="pwd" style="font-family: roboto,sans-serif">PASSWORD</label>
              <input [disabled]="onBetaKeyChangeCheckLength()"
                     type="password" required [(ngModel)]="registrationUser.password" name="password"
                     class="form-control" id="pwd" (blur)="doValidatePassword($event)"/>
              <!-- Password Requirements -->
              <div class="aro-pswd_info" *ngIf="!isStrongPassword">
                <div id="pswd_info">
                  <h4>Password must include</h4>
                  <ul>
                    <li id="letter" class="invalid">At least <strong>one small letter</strong></li>
                    <li id="capital" class="invalid">At least <strong>one capital letter</strong></li>
                    <li id="number" class="invalid">At least <strong>one number</strong></li>
                    <li id="length" class="invalid">At least <strong>8 characters</strong></li>
                    <li id="space" class="invalid">At least <strong>one special character</strong></li>
                  </ul>
                </div>
              </div>

              <!-- End Password Requirements  -->
            </div>
            <div class="form-group" *ngIf="isValidBetaAccessKey">
              <label for="pwd" style="font-family: roboto,sans-serif">CONFIRM PASSWORD</label>
              <input [disabled]="onBetaKeyChangeCheckLength()"
                     type="password" name="confirmPassword"
                     [(ngModel)]="registrationUser.confirmPassword" required
                     class="form-control" id="cpwd" (blur)="doValidatePassword($event)">
            </div>

            <button *ngIf="!isValidBetaAccessKey" class="btn btn-post cta-button" type="submit"
                    (click)="onValidateRegistrationFirstStep()">Continue
            </button>
            <button *ngIf="isValidBetaAccessKey" class="btn btn-post cta-button" type="submit" (click)="onRegister()">
              Sign Up
            </button>
          </form><!-- End Form -->
          <div class="login-help d-flex justify-content-center">
            <p style="margin-top: 10px">
              <small><a routerLink="/login">Already have an account?</a></small>
            </p>
          </div>
        </div><!-- End Col -->
        <div class="col"></div>
      </div><!-- End Row -->
    </div><!-- End Wrapper -->
  </div>
</div>
