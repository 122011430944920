import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateUtil} from "../utils/date.utils";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
  private readonly DATE_FORMAT = "MMM DD, YYYY";

  parse(value: string): NgbDateStruct | null {
    if (value == null) return null;
    return DateUtil.moment2NgbDate(moment(value, this.DATE_FORMAT));
  }

  format(date: NgbDateStruct | null): string {
    if (date == null) return '';
    return DateUtil.ngbDate2moment(date).format(this.DATE_FORMAT);
  }
}
