import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CognitoUtil} from './cognito.service';
import {environment} from '../../environments/environment';
import * as CryptoJS from "crypto-js";
import {PreviewUrlDto} from "./dtos/preview.url.dto";

@Injectable()
export class UrlPreviewService {

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtil) {
  }

  public getPreviewUrlInfo(queryUrl: string) {
    const myThis = this;

    return new Promise((resolve, reject) => {
      const urlHash = CryptoJS.SHA256(queryUrl).toString(CryptoJS.enc.Base64);
      const cacheKey = this.cognitoUtil.urlPreviewCacheKey;
      const urlPreviewCache = localStorage.getItem(cacheKey);
      if (urlPreviewCache == null) { // initialize cache if not initialized already
        localStorage.setItem(cacheKey, JSON.stringify({}));
      } else { // try to fetch from cache
        const cachedPreviewUrl: PreviewUrlDto = JSON.parse(urlPreviewCache)[urlHash];
        if (cachedPreviewUrl !== undefined) {
          resolve(cachedPreviewUrl);
          return;
        }
      }

      const url = `${environment.apiRoot}/previewurl?url=` + queryUrl;
      myThis.http.get(url).subscribe((response: any) => {
          const previewUrl: PreviewUrlDto = response.data;
          if (previewUrl && (previewUrl.error || previewUrl.requestTimedOut || previewUrl.unknownHost)) {
            reject(previewUrl);
            return;
          }

          // update preview cache
          const urlPreviewCache = localStorage.getItem(cacheKey);
          let urlPreviewCacheObj = JSON.parse(urlPreviewCache);
          urlPreviewCacheObj[urlHash] = previewUrl;
          localStorage.setItem(cacheKey, JSON.stringify(urlPreviewCacheObj));

          resolve(previewUrl);
          return;
        },
        (error) => {
          console.error('couldn\'t fetch url preview because ', error);
          reject(error);
        }
      );
    });
  }
}
