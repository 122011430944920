import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ContainerEvents, FileObject, User} from './types';
import {S3Factory} from './utils';
import {s3Config} from './config';
import {environment} from "../../../environments/environment";

declare var AWS: any;

@Injectable()
export class UploadService {

  // Observable string sources
  private uploadContainerEventSource = new Subject<ContainerEvents>();
  private fileUploadEventSource = new Subject<FileObject>();

  // Observable string streams
  uploadContainerEvent$ = this.uploadContainerEventSource.asObservable();
  fileUploadEvent$ = this.fileUploadEventSource.asObservable();
  private signedInUser: User;
  private region: string;

  constructor() {
    this.region = s3Config.defaultRegion || 'us-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates
  publishUploadContainerEvent(event: ContainerEvents) {
    this.uploadContainerEventSource.next(event);
  }

  publishFileUploadEvent(file: FileObject) {
    this.fileUploadEventSource.next(file);
  }

  setRegion(region: string) {
    this.region = region;
  }

  private static preparePutObjectRequest(file: File, uploadKeyPrefix: string, region: string): any {
    return {
      Key: [uploadKeyPrefix, file.name].join("/"),
      Bucket: s3Config.buckets[environment.environmentName + "-" + region],
      Body: file,
      ContentType: file.type
    };
  }

  private static prepareDeleteObjectRequest(file: File, uploadKeyPrefix: string, region: string): any {
    return {
      Key: [uploadKeyPrefix, file.name].join("/"),
      Bucket: s3Config.buckets[environment.environmentName + "-" + region]
    };
  }

  private static updateAWSCredentials(idTokenJWT: string, username: string, region: string, userPoolId: string, identityPoolId: string, callback: (err?: Error) => void) {
    const logins = {};
    const userPoolLoginKey = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;

    logins[userPoolLoginKey] = idTokenJWT;
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: logins,
        LoginId: username
      })
    });
    callback();
  }

  upload(file: File, idTokenJwt: string, uploadKeyPrefix: string, userName: string, region: string, userPoolId: string, identityPoolId: string, progressCallback: (error: Error, progress: number, speed: number) => void) {
    if (!this.signedInUser) {
      progressCallback(new Error('User not signed in'), undefined, undefined);
      return;
    }
    UploadService.updateAWSCredentials(idTokenJwt, userName, region, userPoolId, identityPoolId, (err2) => {
      if (err2) {
        console.log("Error : " + err2)
      }
    });
    region = region || this.region;
    const s3Upload = S3Factory.getS3(region).upload(UploadService.preparePutObjectRequest(file, uploadKeyPrefix, region));
    s3Upload.on('httpUploadProgress', this.handleS3UploadProgress(progressCallback));
    s3Upload.send(this.handleS3UploadComplete(progressCallback));
    return s3Upload;
  }

  delete(file: File, idTokenJwt: string, uploadKeyPrefix: string, userName: string, region: string, userPoolId: string, identityPoolId: string, deleteCallback: (error: Error, data: any) => void) {
    if (!this.signedInUser || !file) {
      return;
    }
    UploadService.updateAWSCredentials(idTokenJwt, userName, region, userPoolId, identityPoolId, (err2) => {
      if (err2) {
        console.log("Error: " + err2)
      }
    });
    region = region || this.region;
    const s3DeleteObject = S3Factory.getS3(region).deleteObject(
      UploadService.prepareDeleteObjectRequest(file, uploadKeyPrefix, region), deleteCallback);
    return s3DeleteObject;
  }

  private handleS3UploadProgress
  (progressCallback: (error: Error, progress: number, speed: number) => void) {
    let uploadStartTime = new Date().getTime();
    let uploadedBytes = 0;
    return (progressEvent: any) => {
      const currentTime = new Date().getTime();
      const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
      if (timeElapsedInSeconds > 0) {
        const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(undefined, progress, speed);
        uploadStartTime = currentTime;
        uploadedBytes = progressEvent.loaded;
      }
    };
  }

  private handleS3UploadComplete(
    progressCallback: (error: Error, progress: number, speed: number) => void) {
    return (error: Error, _: any) => {
      if (error) {
        progressCallback(error, undefined, undefined);
      } else {
        progressCallback(error, 100, undefined);
      }
    };
  }

  cancel(s3Upload: any) {
    s3Upload.abort();
  }
}
