import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {CognitoCallback, CognitoUtil, LoggedInCallback, SuperAdminLoggedInCallback} from './cognito.service';
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {UserProfile} from '../users/UserProfile';

declare var AWS: any;

@Injectable()
export class UserLoginService {

  private static _SUPER_ADMIN_ROLE = environment.superAdminRole;

  constructor(public cognitoUtil: CognitoUtil, private router: Router, private http: HttpClient) {
  }

  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {
    this.cognitoUtil.getCurrentUser().setSignInUserSession(session);
    // console.log("SignInSession: " + this.cognitoUtil.getCurrentUser().getSignInUserSession().getIdToken())
    // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
    // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
    // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
    // security credentials. The identity is then injected directly into the credentials object.
    // If the first SDK call we make wants to use our IdentityID, we have a
    // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
    // very innocuous API calls that forces this behavior.
    const clientParams: any = {};
    const sts = new AWS.STS(clientParams);
    sts.getCallerIdentity(function (err, data) {
      callback.cognitoCallback(null, session);
    });
  };

  private onLoginError = (callback: CognitoCallback, err: string) => {
    callback.cognitoCallback(err, null);
  };

  authenticate(username: string, password: string, callback: CognitoCallback) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(userAttributes, requiredAttributes),
      onSuccess: result => this.onLoginSuccess(callback, result),
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  adminCreateUserResetPassword(username: string, tmpPassword: string, newPassword: string, callback: CognitoCallback) {
    let myThis = this;
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: tmpPassword,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    });

    function onAdminCreateUserResetPasswordSuccess(callback: CognitoCallback, session: CognitoUserSession) {
      callback.cognitoCallback(null, session);
      myThis.logout();
    }

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => onAdminCreateUserResetPasswordSuccess(callback, session),
      onFailure: err => this.onLoginError(callback, err),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email;
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;

        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          // onSuccess we are ignoring returned session as we will redirect user to Login page
          onSuccess: (session: CognitoUserSession) => onAdminCreateUserResetPasswordSuccess(callback, session),
          onFailure: (err: any) => this.onLoginError(callback, err)
        });
      }
    });
  }

  forgotPassword(username: string, callback: CognitoCallback) {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: function () {
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }


  logout() {
    this.cognitoUtil.signOut();
  }

  isSignedIn() {
    return this.cognitoUtil.getCurrentUser() !== null;
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw new Error(('UserLoginService: Callback in isAuthenticated() cannot be null'));
    }

    const cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          callback.isLoggedIn(err, false);
        } else {
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      callback.isLoggedIn('Can\'t retrieve the CurrentUser', false);
    }
  }

  public static isRoleSuperAdmin(profile: UserProfile): boolean {
    if (profile == null) {
      return false;
    }

    const customRole = profile.customRole;
    if (customRole == null || customRole.trim().length == 0) {
      return false;
    }

    return customRole == UserLoginService._SUPER_ADMIN_ROLE;
  }

  public static isWorkspaceStartsWith(profile: UserProfile, workspacePrefix: string): boolean {
    if (profile == null) {
      return false;
    }

    const customWorkspacename = profile.customWorkspacename;
    if (customWorkspacename == null || customWorkspacename.trim().length == 0) {
      return false;
    }

    return customWorkspacename.toLowerCase().startsWith(workspacePrefix);
  }

  isSuperAdminAuthenticated(callback: SuperAdminLoggedInCallback): boolean {
    if (callback == null) {
      throw new Error(('UserLoginService: Callback in isSuperAdminAuthenticated() cannot be null'));
    }

    const notAuthorized = (err: Error): boolean => {
      callback.onLoggedInError(err);
      window.location.href = '/';
      return false;
    };

    const checkRole = (msg: string, isLoggedIn: boolean): boolean => {
      if (!isLoggedIn) {
        return notAuthorized(new Error(msg));
      }

      const profile: UserProfile = JSON.parse(localStorage.getItem(this.cognitoUtil.username));
      const customRole = profile.customRole;
      if (customRole == null || customRole.trim().length == 0) {
        return notAuthorized(new Error('Not a valid role'));
      }

      if (customRole != UserLoginService._SUPER_ADMIN_ROLE) {
        return notAuthorized(new Error('Role is not super admin'));
      } else {
        callback.onLoggedInSuccess(profile);
        return true;
      }
    };

    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          return checkRole(err, false);
        } else {
          return checkRole(err, session.isValid());
        }
      });
    } else {
      return checkRole('Can\'t retrieve the CurrentUser', false);
    }
  }

  getLoggedInUserProfile(userName: string, groupName: string): Observable<any> {
    const userProfile = localStorage.getItem(userName);
    if (userProfile !== undefined && userProfile != null) {
      return of({success: true, data: userProfile});
    }

    if (groupName === undefined || groupName === null) {
      return of({success: true, data: new UserProfile(null, false, null, null, null, null, null, null, null)});
    }

    const myThis = this;
    const url = `${environment.apiRoot}/user?groupName=` + groupName;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json');

    return myThis.http.get(url, {headers: httpHeaders});
  }
}
