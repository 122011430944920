export class PasswordValidator {

  public static isValid(password: string) {
    let specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '{', '}', '[',  ']', '|', '\\', ':', ';', '.',  '?', '<', '>'];
    let hasNumber = /\d/.test(password);
    let hasUpper = /[A-Z]/.test(password);
    let hasLower = /[a-z]/.test(password);
    let hasAtLeastEightChars = password.length >= 8;
    let isValid = hasNumber && hasUpper && hasLower && hasAtLeastEightChars;
    if (!isValid) return false;

    let chars: string[] = password.split('');
    let hasSpecialChars = chars.filter(function (char) {
      return specialChars.indexOf(char) >= 0;
    }).reduce(function (previousValue, currentValue) {
      return previousValue + 1;
    }, 0);

    return hasNumber && hasUpper && hasLower && hasAtLeastEightChars && hasSpecialChars >= 1;
  }
}
