import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PsFlashMessageUtil} from "./ps.flash.message.util";

@Injectable()
export class LocalStorageCacheService {
  constructor(private psFlashMessageUtil: PsFlashMessageUtil) {
  }

  public get<T>(cacheKey: string, observable: Observable<any>): Observable<T> {
    const dataJson = localStorage.getItem(cacheKey);
    if (dataJson != null) {
      return of(JSON.parse(dataJson) as T);
    }

    let myThis = this;
    return observable
      .pipe(
        map(response => {
          const tzInfos = PsFlashMessageUtil.validateResponse<T>(response);
          localStorage.setItem(cacheKey, JSON.stringify(tzInfos));
          return tzInfos;
        }),
        catchError(err => {
          myThis.psFlashMessageUtil.flashMessage(err);
          return throwError(err);
        })
      );
  }

  public clear(cacheKey: string) {
    localStorage.removeItem(cacheKey);
  }

}
