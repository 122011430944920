import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CognitoCallback, CognitoUtil} from '../../../service/cognito.service';
import {UserLoginService} from '../../../service/user-login.service';

@Component({
  templateUrl: './confirmRegistration.html'
})
export class RegistrationConfirmationComponent implements OnInit, OnDestroy, CognitoCallback {
  public temporaryPassword: string;
  public email: string;
  public errorMessage: string;
  private sub: any;

  constructor(public userLogInService: UserLoginService, public router: Router, public activatedRoute: ActivatedRoute, public cognitoUtil: CognitoUtil) {
  }

  ngOnInit() {
    let myThis = this;
    this.sub = this.activatedRoute.params.subscribe((params: Params) => {
      myThis.email = params['username'];
    });
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onConfirmRegistration() {
    this.errorMessage = null;
    this.userLogInService.authenticate(this.email, this.temporaryPassword, this);
  }

  cognitoCallback(message: string, result: any) {
    if (message != null) {
      // Force Password reset required
      this.errorMessage = message;
      this.router.navigate(['/invite-user-change-password', this.email, this.temporaryPassword], { queryParamsHandling: "preserve" });
    }
  }
}





