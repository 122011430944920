import {Inject, Injectable} from '@angular/core';
import {CognitoCallback, CognitoUtil} from './cognito.service';
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js';
import {RegistrationUser} from '../register/register.component';
import {NewPasswordUser} from '../public/auth/newpassword/newpassword.component';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

export interface RegistrationCallback {
  registrationCallback(message: string, result: any): void;
}

@Injectable()
export class UserRegistrationService {

  constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil,
              private http: HttpClient,
              private router: Router) {

  }

  register(user: RegistrationUser, callback: CognitoCallback): void {
    if (!user.email) {
      callback.cognitoCallback('Please provide a valid email address', null);
      return;
    }

    if (!user.password || !user.confirmPassword || user.password !== user.confirmPassword) {
      callback.cognitoCallback('Please make sure password and confirm password are same', null);
      return;
    }

    const attributeList = [];

    const betaRegistrationKey = {
      Name: 'custom:betaRegistrationKey',
      Value: user.betaRegistrationKey
    };
    const slackWorkspaceName = {
      Name: 'custom:slackWorkspaceName',
      Value: user.slackWorkspaceName
    };
    const dataEmail = {
      Name: 'email',
      Value: user.email
    };
    const dataNickname = {
      Name: 'nickname',
      Value: user.firstName
    };

    const dataGroup = {
      Name: 'custom:group',
      Value: user.group
    };
    const dataCompanyName = {
      Name: 'custom:companyName',
      Value: user.companyName
    };

    const dataUserRole = {
      Name: 'custom:role',
      Value: user.role || 'user'
    };
    const pleaseShareAppInstalled = {
      Name: 'custom:PSAppInstalled',
      Value: user.pleaseShareAppInstalled
    };

    if (user.group !== undefined) {
      attributeList.push(new CognitoUserAttribute(dataGroup));
    }

    attributeList.push(new CognitoUserAttribute(betaRegistrationKey));
    attributeList.push(new CognitoUserAttribute(slackWorkspaceName));
    attributeList.push(new CognitoUserAttribute(pleaseShareAppInstalled));
    attributeList.push(new CognitoUserAttribute(dataEmail));
    attributeList.push(new CognitoUserAttribute(dataCompanyName));
    attributeList.push(new CognitoUserAttribute(dataNickname));
    attributeList.push(new CognitoUserAttribute(dataUserRole));
    attributeList.push(new CognitoUserAttribute({
      Name: 'phone_number',
      Value: user.phone_number
    }));

    const dataFirstName = {
      Name: 'given_name',
      Value: user.firstName
    };
    attributeList.push(new CognitoUserAttribute(dataFirstName));
    const dataLastName = {
      Name: 'family_name',
      Value: user.lastName
    };
    attributeList.push(new CognitoUserAttribute(dataLastName));

    this.cognitoUtil.getUserPool().signUp(user.email, user.password, attributeList, null, function (err, result) {
      if (err) {
        // Cognito sends a prepended error message, replace it to show only exact error message
        let errMessage = err.message.replace('PreSignUp failed with error', '');
        errMessage = errMessage.replace('Error:', '');
        callback.cognitoCallback(errMessage, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });

  }

  confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  resendCode(username: string, callback: CognitoCallback): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
    // Get these details and call
    // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    const authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
          onSuccess: function (result) {
            callback.cognitoCallback(null, userAttributes);
          },
          onFailure: function (err) {
            callback.cognitoCallback(err, null);
          }
        });
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err, null);
      }
    });
  }

  doValidateRegistrationFirstStep(betaAccessKey: string, slackWorkspace: string, registrationCallback: RegistrationCallback): void {
    const url = `${environment.apiRoot}/pre-signup/validate-registration-first-step`;

    const myThis = this;
    const bodyContents = {
      'betaRegistrationKey': betaAccessKey,
      'slackWorkspaceName': slackWorkspace
    };

    myThis.http.post(url, bodyContents)
      .subscribe(
        (data: any) => {
          const message = data.message || '';
          let errorMessage = '';
          if (data.errors) {
            data.errors.forEach(function (e) {
              errorMessage += e.message + '<br />';
            });
          }
          const success = data.success;
          if (success) {
            registrationCallback.registrationCallback(null, message);
          } else {
            registrationCallback.registrationCallback(errorMessage, data);
          }
        },
        (error) => {
          console.log(error);
          registrationCallback.registrationCallback(error, null);
        }
      );
  }
}
