<div class="wrapper">
  <form class="form-signin">
    <div class="form-group">
      <div class="font-weight-bold d-flex justify-content-between w-100">
        Thank You for Registering
      </div>
    </div>

    <hr>
    <div class="form-group">
      <div class="col-xs-12">
        To activate your account, please go to your inbox and click the confirmation link in the email we just sent to {{email}}.
      </div>
    </div>
  </form>
</div>


<div class="login-help d-flex justify-content-center">
  <p style="margin-top: 10px">
    <small><a routerLink="/login">Back to sign in page</a></small>
  </p>
</div>
