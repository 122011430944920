import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {CognitoUserPool} from 'amazon-cognito-identity-js';
import {Router} from '@angular/router';
import {CognitoAuth, CognitoAuthSession} from 'amazon-cognito-auth-js';
import {UserProfile} from "../users/UserProfile";

/**
 * Created by Vladimir Budilov
 */

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface SuperAdminLoggedInCallback {
  onLoggedInSuccess(profile: UserProfile): void;
  onLoggedInError(err: Error): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;

  callbackWithParams(idToken: any, accessToken: any): void;
}


@Injectable()
export class CognitoUtil {

  public static _REGION = environment.region;
  public static _IDENTITY_POOL_ID = environment.identityPoolId;

  private static _FEDERATION_CONFIG = environment.federationConfig;
  private static _USER_POOL_ID = environment.userPoolId;
  private static _CLIENT_ID = environment.clientId;

  private oktaAuth: CognitoAuth;
  private cognitoProviderName: string = null;

  constructor(private router: Router) {}

  private getCognitoAuth() {
    if (this.oktaAuth != null) {
      return this.oktaAuth;
    }

    let cognitoAuth = new CognitoAuth({
      UserPoolId: CognitoUtil._USER_POOL_ID,
      ClientId: CognitoUtil._CLIENT_ID,
      AppWebDomain: CognitoUtil._FEDERATION_CONFIG.AppWebDomain,
      TokenScopesArray: CognitoUtil._FEDERATION_CONFIG.TokenScopesArray,
      IdentityProvider: this.cognitoProviderName,
      RedirectUriSignIn: CognitoUtil._FEDERATION_CONFIG.RedirectUriSignIn,
      RedirectUriSignOut: CognitoUtil._FEDERATION_CONFIG.RedirectUriSignOut,
      AdvancedSecurityDataCollectionFlag: CognitoUtil._FEDERATION_CONFIG.AdvancedSecurityDataCollectionFlag,
    });

    cognitoAuth.userhandler = this.oktaUserHandler(null);
    this.oktaAuth = cognitoAuth;

    return cognitoAuth;
  }

  private getCognitoIdentityServiceProviderValue(postfix: string) : string {
    return localStorage.getItem(`CognitoIdentityServiceProvider.${this.clientId}.${this.username}.${postfix}`);
  }

  get psHistoryIdentifierForSendPostToShareRequest() : string {
    return `psHistoryIdentifierForSendPostToShareRequest.${this.username}`;
  }

  get psHistoryIdentifierForSendPostToCollaborationPlatform() : string {
    return `psHistoryIdentifierForSendPostToCollaborationPlatform.${this.username}`;
  }

  get urlPreviewCacheKey(): string {
    return this.username + "_URL_PREVIEW_CACHE";
  }

  get username() : string {
    return this.getCurrentUser() && this.getCurrentUser().getUsername();
  }

  get clientId() : string {
    return this.getUserPool() && this.getUserPool().getClientId();
  }

  get accessToken() : string {
    return this.getCognitoIdentityServiceProviderValue('accessToken');
  }

  get idToken() : string {
    return this.getCognitoIdentityServiceProviderValue('idToken');
  }

  oktaProcessSignInCallbackUrl(callback: LoggedInCallback) {
    this.getCognitoAuth().userhandler = this.oktaUserHandler(callback);
    this.getCognitoAuth().parseCognitoWebResponse(this.router.url);
  }

  setCognitoProviderName(cognitoProviderName: string) {
    if (cognitoProviderName == null || cognitoProviderName == '') {
      throw new Error('identity provider is required');
    }

    this.cognitoProviderName = cognitoProviderName;
  }

  oktaSignIn() {
    this.getCognitoAuth().getSession();
  }

  oktaUserHandler(callback: LoggedInCallback) {
    return {
      onSuccess: (session: CognitoAuthSession) => {
        if (callback != null) {
          callback.isLoggedIn(null, true);
        }
      },
      onFailure: error => {
        if (callback != null) {
          callback.isLoggedIn(error, false);
        }
      }
    }
  }

  signOut() {
    const isOktaUserSignIn = this.oktaAuth != null && this.oktaAuth.isUserSignedIn();

    const username = this.username;
    if (username) {
      localStorage.clear();
    }

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.signOut();
    }

    if (isOktaUserSignIn) {
      this.oktaAuth.clearCachedTokensScopes();
      this.oktaAuth = null;
    }
  }

  getUserPool() {
    return new CognitoUserPool({
      UserPoolId: CognitoUtil._USER_POOL_ID,
      ClientId: CognitoUtil._CLIENT_ID
    });
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getAccessToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback in getAccessToken is null...returning');
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback in getIdToken is null...returning');
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getIdAndAccessTokenToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback in getIdAndAccessTokenToken is null...returning');
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log('CognitoUtil: Can\'t set the credentials:' + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParams(session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken());
          } else {
            console.log('CognitoUtil: Got the id token, but the session isn\'t valid');
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback in getRefreshToken is null...returning');
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  refresh(): void {
    this.getCurrentUser().getSession(function (err, session) {
      if (err) {
      } else {
        if (session.isValid()) {

        } else {

        }
      }
    });
  }
}
