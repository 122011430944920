class PleaseShareError extends Error {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PleaseShareError.prototype);
    }

    getErrorMessage() {
        return this.message;
    }
}

export default PleaseShareError;
