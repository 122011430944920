<section id="wrapper">
  <div class="login-register" style="background-color:#f2f7f8;">
    <div class="login-box card">
      <div class="card-body">
        <h3 class="box-title m-b-20 text-center">Invitation Sent</h3>
        <hr>
        <div class="form-group">
          <div class="col-xs-12">
            Invitation successfully sent to "{{email}}"
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
