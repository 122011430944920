import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChallengeParameters, CognitoCallback, CognitoUtil, LoggedInCallback} from '../service/cognito.service';

import {UserLoginService} from '../service/user-login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSetupService} from '../service/user-setup.service';
import {SpinnerVisibilityService} from 'ng-http-loader';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FlashMessagesService} from "angular2-flash-messages";

declare var $: any;

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[id=content-wrapper]',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit, OnDestroy {
    email: string;
    password: string;
    errorMessage: string;
    mfaStep = false;
    dontHaveAccountLink: string;
    mfaData = {
        destination: '',
        callback: null
    };
    emailConfirmed: string;
    groupName: string;
    private sub: any;
    private subParams: any;

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.subParams.unsubscribe();
    }

    constructor(private cognitoUtil: CognitoUtil, public router: Router, public route: ActivatedRoute, private http: HttpClient,
                public userService: UserLoginService, public userSetupService: UserSetupService, private spinner: SpinnerVisibilityService,
                private _flashMessagesService: FlashMessagesService) {
        let myThis = this;
        $(document).keyup(function (e) {
            if ($("#cognitoSamlProviderName:focus") && (e.key === 'Enter')) {
                myThis.onOktaLogin()
            }
        });

        const env = environment.environmentName;
        this.dontHaveAccountLink = (env === 'prod')
          ? "https://pleaseshare.co/request_access.html"
          : environment.slackAppDownloadLink + '&env=' + env;
    }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            this.emailConfirmed = params['emailConfirmed'];
        });
        this.subParams = this.route.params.subscribe(params => {
            this.groupName = params['groupName'];
        });
        this.errorMessage = null;
        this.userService.isAuthenticated(this);
    }

    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = 'Please provide valid login credentials';
            return;
        }
        this.spinner.show();
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }

    onOktaLogin() {
        let myThis = this;
        const companyName = $("#cognitoSamlProviderName").val();
        if (companyName == null || companyName == '') {
            myThis.flashMessage('Slack workspace name can\'t be empty', true);
            return;
        }

        const url = `${environment.apiRoot}/user/saml-provider-name?companyName=${companyName}`;
        let httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

        myThis.http.get(url, {headers: httpHeaders})
            .subscribe(
                (response: any) => {
                    myThis.clearCognitoSamlProviderName();
                    if (response.success) {
                        const cognitoProviderName = response.data;
                        if (cognitoProviderName != null && cognitoProviderName != '') {
                            myThis.cognitoUtil.setCognitoProviderName(cognitoProviderName);
                            myThis.cognitoUtil.oktaSignIn();
                            $("#getCognitoSamlProviderName").addClass("fade").modal("hide");
                        } else {
                            myThis.flashMessage('No SAML provider is set for Slack Workspace Name. Please set the provider first.', true);
                        }
                    } else {
                        myThis.flashMessage('No SAML provider is set for Slack Workspace Name. Please set the provider first.', true);
                    }
                },
                (error) => {
                    myThis.flashMessage('No SAML provider is set for Slack Workspace Name. Please set the provider first.', true);
                }
            );
    }

    clearCognitoSamlProviderName() {
      $("#cognitoSamlProviderName").val('');
    }

    cognitoCallback(err: any, result: any) {
        if (err) console.log(err);

        this.spinner.hide();
        if (err && (err.code === 'NotAuthorizedException' && err.message === 'User is disabled.')) {
            this.errorMessage = 'User account disabled. If you believe this is an error, please contact us at support@pleaseshare.co.';
        } else if (err && err.code === 'NotAuthorizedException') {
            this.errorMessage = 'User account not authorized yet. Please check your inbox to confirm your email address before logging in.';
        } else if (err && err.code === 'UserNotConfirmedException') {
            this.errorMessage = 'Please read the email we sent you to confirm your email address.';
        } else if (err && err.code === 'UserNotFoundException') {
            this.errorMessage = 'Please provide a valid email and password to login.';
        } else if (err) {
            //TODO: Add SSO specific error message
            this.errorMessage = 'We were not able to validate that your email address is currently active ' +
                'with your company’s Slack workspace. For troubleshooting assistance, email support@pleaseshare.co.';
        } else if (!err) { // success
            if (this.groupName !== undefined) {
                this.userSetupService.linkUserWithCognitoGroup(this.email, null, this.groupName).then(
                    (data: any) => {
                        if (data.errorMessage === undefined) {
                            window.location.href = '/';
                        } else {
                            alert('Something weird happened. Please reinstall our slack bot app.');
                        }
                    });
            } else {
                window.location.href = '/';
            }
        }
    }

    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
        this.mfaData.callback = (code: string) => {
            if (code == null || code.length === 0) {
                this.errorMessage = 'Code is required';
                return;
            }
            this.errorMessage = null;
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/home'], {queryParamsHandling: 'preserve'});
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false;   // necessary to prevent href navigation
    }

  flashMessage(msg: string, error: boolean) {
    if (error) {
      this._flashMessagesService
        .show(msg, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
    } else {
      this._flashMessagesService
        .show(msg, {
          cssClass: 'alert-success',
          timeout: 5000
        });
    }
  }

}
