<div [ngClass]="{'file-row': true, row: true, 'bg-light': oddRow, 'bg-white': !oddRow}"
     *ngIf="(twitterFileObject && twitterFileObject.isS3Object) || (linkedInFileObject && linkedInFileObject.isS3Object)">
  <div class="col-md-4 text-right">
    <p class="name" title="{{twitterFileObject.file?.name}}"
       *ngIf="twitterFileObject">{{twitterFileObject.file?.name | slice:0:50}}</p>
    <p class="name" title="{{linkedInFileObject.file?.name}}"
       *ngIf="linkedInFileObject">{{linkedInFileObject.file?.name | slice:0:50}}</p>
    <!--        <p *ngIf="twitterFileObject"-->
    <!--           [ngClass]="{'text-primary': twitterFileObject.status === FileObjectStatus.Uploading, 'text-success': twitterFileObject.status === FileObjectStatus.Uploaded, 'text-danger': twitterFileObject.status === FileObjectStatus.Failed}">-->
    <!--            <strong *ngIf="twitterFileObject.status !== FileObjectStatus.NotStarted"-->
    <!--                    [ngClass]="{'error': twitterFileObject.status === FileObjectStatus.Failed}">-->
    <!--                {{FileObjectStatus[twitterFileObject.status]}}-->
    <!--            </strong>-->
    <!--        </p>-->
    <!--        <p *ngIf="linkedInFileObject"-->
    <!--           [ngClass]="{'text-primary': linkedInFileObject.status === FileObjectStatus.Uploading, 'text-success': linkedInFileObject.status === FileObjectStatus.Uploaded, 'text-danger': linkedInFileObject.status === FileObjectStatus.Failed}">-->
    <!--            <strong *ngIf="linkedInFileObject.status !== FileObjectStatus.NotStarted"-->
    <!--                    [ngClass]="{'error': linkedInFileObject.status === FileObjectStatus.Failed}">-->
    <!--                {{FileObjectStatus[linkedInFileObject.status]}}-->
    <!--            </strong>-->
    <!--        </p>-->
    <!--        <strong class="error text-danger">{{uploadError}}</strong>-->
  </div>
  <div class="col-md-4 text-right" *ngIf="(twitterFileObject  || linkedInFileObject)">
    <div class="progress">
      <div style="height:13px;border-radius:5px;" *ngIf="twitterFileObject"
           [ngClass]="{'progress-bar': true, 'progress-bar-striped': true, 'progress-bar-success': twitterFileObject.status !== FileObjectStatus.Failed, 'progress-bar-danger': twitterFileObject.status === FileObjectStatus.Failed }"
           role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuenow="progress" aria-valuemin="0"
           aria-valuemax="100">
        <strong style="background-color: #58CE87;" *ngIf="progress > 0">{{progress}}%</strong>
      </div>
      <div style="height:13px;border-radius:5px;" *ngIf="linkedInFileObject"
           [ngClass]="{'progress-bar': true, 'progress-bar-striped': true, 'progress-bar-success': linkedInFileObject.status !== FileObjectStatus.Failed, 'progress-bar-danger': linkedInFileObject.status === FileObjectStatus.Failed }"
           role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuenow="progress" aria-valuemin="0"
           aria-valuemax="100">
        <strong style="background-color: #58CE87;" *ngIf="progress > 0">{{progress}}%</strong>
      </div>
    </div>
    <p *ngIf="twitterFileObject && twitterFileObject.file" class="text-left size">{{twitterFileObject.file.size | fileSize}}</p>
    <p *ngIf="linkedInFileObject && linkedInFileObject.file" class="text-left size">{{linkedInFileObject.file.size | fileSize}}</p>

    <p *ngIf="twitterFileObject && twitterFileObject.status === FileObjectStatus.Uploading"
       class="speed text-right" style="margin-top: -35px;">{{speed | fileSize}}/s</p>
    <p *ngIf="linkedInFileObject && linkedInFileObject.status === FileObjectStatus.Uploading"
       class="speed text-right" style="margin-top: -35px;">{{speed | fileSize}}/s</p>
  </div>
  <div class="col-md-2 text-right mt-3" *ngIf="(twitterFileObject || linkedInFileObject)">
    <i class="fa fa-remove" style="cursor: pointer; font-size: 15px; color: #6BC4E8;" (click)="clear()"
       title="remove"></i>
  </div>
</div>
