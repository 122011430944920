<form class="form-signin" method="POST" action="#" role="form">
    <div class="form-group">
        <h2>Set new password</h2>
    </div>
    <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div class="form-group">
        <label class="control-label" for="signupEmail">Username / Email</label>
        <input id="signupEmail" type="email" maxlength="50" class="form-control"
               [(ngModel)]="registrationUser.username" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="existingPassword">Temporary Password</label>
        <input id="existingPassword" type="password" maxlength="25" class="form-control"
               placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.existingPassword"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="signupPassword">New Password</label>
        <input id="signupPassword" type="password" maxlength="25" class="form-control"
               placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.password"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <button (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-info btn-block">
            Set your password
        </button>
    </div>
    <hr>
    <p>Already have an account? <a [routerLink]="['/home/login']"> <i class="fa fa-fw fa-lock"></i>Login</a></p>
</form>
