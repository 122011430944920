import PleaseShareError from "./PleaseShareError"
class ValidationError extends PleaseShareError {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ValidationError.prototype);
    }

    getErrorMessage() {
        return this.message;
    }
}
export default ValidationError;
