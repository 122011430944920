<div *ngIf="isSignedIn() && (!userBelongsToGroup || userAccountStatus !== 'ENABLED')" class="alert add_app">
  <div class="row">
    <div class="col-md-9" [innerHTML]="slackAppNotificationMsg | keepHtml"></div>
    <div class="col-md-3">
      <a target="_blank" href="{{slackAppDownloadLink}}">
        <img src="../../assets/images/btn-add-to-slack.svg" alt="btn-add-to-slack" style="height: 35px;">
      </a>
    </div>
  </div>
</div>

<div *ngIf="isSignedIn() && appInstalled === 'appInstalled/true'" class="alert">
  <div class="alert-success alert alert-dismissible">
    <a href="#" class="close" data-dismiss="alert" aria-label="close"
       (click)="clearAppInstallationValue()">&times;</a>
    Please Share app was successfully added to your Slack workspace.
  </div>
</div>

<div>
  <!-- Begin Main Content Area -->
  <div class="container-fluid" *ngIf="userBelongsToGroup == true && userAccountStatus === 'ENABLED'">
    <div *ngIf="postUpdateResponse.status"
         [ngClass]="(postUpdateResponse.error == false)?'alert-success':'alert-danger'"
         class="alert alert-dismissible">
      <a href="#" class="close" data-dismiss="alert" (click)="onDismissalDialogMessageClose()" aria-label="close">&times;</a>
      <div [innerHTML]="postUpdateResponse.message | keepHtml"></div>
    </div>
    <form *ngIf="observableMessage | async">
      <!-- Begin Main Content Area -->
      <div class="row" style="padding: 20px">
        <!-- Begin Left Col -->
        <div class="col-md-7">

          <!-- Add New Update Form -->
          <div class="card m-b-25">
            <div id="post-description" class="card-body disc-box">
              <form>
                <div class="form-group row">
                  <div class="col-md-12">
                    <img src="../../assets/images/ps_new_update.png" alt="PS New Update"><span class="section-head">DESCRIPTION</span>
                    <hr class="social-hr-wh">
                  </div>
                  <div class="col-sm-12">
                    <div style="position: relative">
                      <ngx-spinner
                        name="description-spinner"
                        bdColor="rgba(255, 255, 255, .2)"
                        size="small"
                        color="#495057"
                        type="ball-clip-rotate"
                      >
                        <p style="font-size: 15px; color: #495057;margin-top:50px;">Fetching link details ...</p>
                      </ngx-spinner>
                      <textarea (blur)="onBlurOnNewUpdate($event.target['value'])"
                                [ngClass]="((collaborationPlatformName|lowercase) === 'teams')?'social-form form-control preview':'form-control preview'"
                                data-copy="#preview" id="subject" name="subject"
                                [(ngModel)]="message.subject" rows="4" required
                                placeholder="Describe the request for your team and, if applicable, include the URL of the blog/article for your team to share...">
                      </textarea>
                    </div>
                    <div *ngIf="(collaborationPlatformName|lowercase) === 'slack'"
                         class="col-12 pt-1 text-sm-right">
                      <input type="checkbox"
                             [(ngModel)]="message.includeAtChannel"
                             name="include-at-channel"
                             class="mt-2 text-right"
                             id="include-channel-checkbox"/>
                      <label for="include-channel-checkbox" class="control-label text-white text-sm-right">Include @channel before the message.</label>
                      <a href="javascript:void(0)" class="text-sm-right">
                      <span class="fa fa-question-circle fa-lg text-white"
                            ngbTooltip='Include @channel to notify members of the Slack channel about the new share request.'
                            container="body"
                            placement="right"
                            style="color: #ced4da">
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card social-box">
            <div class="card-body ">
              <div class="col-md-12 mx-0 px-0 ">
                <div>
                  <img src="../../assets/images/slack_icon.svg" height="32px" alt="Channel name header">
                  <span class="social-head">
                    SEND SHARE REQUEST TO: {{updateDefaultChannel(selectedSendToShareRequestChannelName, '')}}
                  </span>
                </div>
                <hr class="social-hr">
              </div>
              <div class="row my-4 mt-sm-1">
                <div class="col-sm-9">
                  <div class="ng-autocomplete w-100">
                    <ng-autocomplete #autocompleteComponent
                                     id="searchChannelAutocompleteId"
                                     [data]="collaborationPlatformAutoChannels"
                                     [searchKeyword]="searchKeyword"
                                     (selected)='onSearchSelected($event)'
                                     [placeholder]="autoCompleteSearchPlaceholderText"
                                     (inputChanged)='onChangeSearch($event)'
                                     [minQueryLength]="3"
                                     (inputFocused)="onFocusAutoSearch()"
                                     [isLoading]="autoCompleteSearchIsLoading"
                                     [focusFirst]="true"
                                     [itemTemplate]="itemTemplate"
                                     [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-sm-3 mt-1 p-0">
                  <input *ngIf="!isDefaultChannel(selectedSendToShareRequestChannelName)"
                         [(ngModel)]="makeSelectedChannelAsDefault"
                         type="checkbox"
                         name="include-at-channel"
                         class="mt-2 text-right"
                         id="update-default-channel-checkbox"/>
                  <label *ngIf="!isDefaultChannel(selectedSendToShareRequestChannelName)"
                         for="update-default-channel-checkbox"
                         class="control-label text-sm-right">Set as default</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <form class="post_channel_names">
                    <div class="row">
                      <div *ngFor="let oneHistory of psLocalStorageChannelSearchHistories; let first = first;"
                           class="col-6">
                        <input id="{{oneHistory.id}}" name="post_channel"
                               value="{{oneHistory.id}}" type="radio"
                               [checked]="oneHistory.selected"
                               (click)="onRecentSendToChannelsClick(oneHistory)">
                        <label for="{{oneHistory.id}}">{{updateDefaultChannel(oneHistory.name, ' (default)')}}</label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Start Twitter Form -->
          <div class="card mb-25">
            <div id="tw-content" class="card-body social-box">
              <form>
                <div class="form-group row">
                  <div class="col-md-12"><img src="../../assets/images/tw_circle_32x32.png"
                                              alt="Twitter Icon"><span
                    class="social-head">TWITTER</span>
                    <hr class="social-hr">
                  </div>
                  <div class="col-sm-12">

                    <textarea class="form-control social-form mb-10" style="margin-bottom: 10px;"
                              rows="4" name="message" required
                              [(ngModel)]="message.networkData[0] && message.networkData[0].extra.message"
                              maxlength="280"
                              placeholder="Enter the message and URL for your team to share on Twitter..."
                              id="twitter-message"
                              (blur)="onBlurOnInputFields($event)"
                    ></textarea>
                  </div>
                  <small class="col-sm-12 m-t-sm" id="textarea_feedback" align="right"></small>
                  <!-- Fix Textarea -->
                </div>
              </form>
              <div class="row">
                <div class="col-12  mt-2 mb-2 cursor-pointer">
                  <p id="btn-accordion-twitter" class=" btn-accordion float-right text-secondary"
                     ngbTooltip='Due to a known issue with Twitter API v2, we are unable to process media attachments at this time. We will restore this functionality once the issue has been resolved.'
                     container="body" placement="right" style="color: #ced4da">
                    <!-- <i class="fas fa-upload"></i> -->
                    Add Image(s)/Video
                  </p>
                </div>
              </div>

              <div class="collapse" id="collapseTwitter">
                <div class="col-12 text-center">
                  <div class="card">
                    <!--Twitter Upload Photo Section-->
                    <form method="post" action="#" id="#">
                      <div class="form-group files">
                        <div class="image-uploader-label">
                          <img src="../../assets/images/upload.png"
                               style="width: 55px; height: 55px; object-fit: cover;">
                          <h3 class="text-secondary mt-2"
                              style="font-size:1.3rem; font-weight: 500;">Drag & drop or
                            <span style="color: #6BC4E8; font-weight: 600;">browse</span>
                            files to add them to your
                            Twitter post.</h3>
                          <div class="content-options mt-4">
                            <p class="font-weight-bold my-0">Twitter Media Options</p>
                            <p class="my-0">Up to 4 images (&le; 5 MB each) or</p>
                            <p class="my-0">1 animated gif (&le; 15 MB) or</p>
                            <p class="my-0">1 video (&le; 512 MB)</p>
                          </div>

                        </div>
                        <input type="file"
                               [accept]="['image/jpg', 'image/jpeg', 'image/png', 'image/gif','image/webp', 'video/mp4']"
                               id="twitter-upload" class="form-control"
                               (change)="twitterFileChangeEvent($event)" multiple="multiple"
                               style="opacity: 1;">
                      </div>
                      <div id="add_tw_image_url" class="hide-link row">
                        <div class="col-sm-11">
                          <input type="text"
                                 [(ngModel)]="twOptionalImageUrl"
                                 name="tw-media-url"
                                 class="form-control social-form form-margin mt-2"
                                 id="tw-media-url"
                                 placeholder="Add the URL of an image to display in your post (optional)"
                                 (blur)="onBlurOnInputFields($event)"
                          >
                        </div>
                        <div class="col-sm-1 mt-3">
                          <a href="javascript:void(0)">
                            <span class="fa fa-question-circle fa-lg"
                                  ngbTooltip='Use this optional field only if you want to display a custom image on Twitter (i.e., other than what is pulled by default from the shared content link).'
                                  container="body"
                                  placement="right"
                                  style="color: #ced4da">
                            </span>
                          </a>
                        </div>
                      </div>

                      <!--Twitter Uploaded/Added Images - Here-->
                      <div class='twitter-images'>
                        <app-file-upload
                          *ngFor="let file of twitterFinalFiles; let oddRow=odd;let evenRow=even;"
                          [oddRow]="oddRow"
                          (defaultImageOrVideoThumbnailChange)="onDefaultImageOrVideoThumbnailChange($event)"
                          [twitterFileObject]="file"></app-file-upload>
                      </div>
                      <div class="row m-2">
                        <div class="col-12 text-center">
                          <p>You can also provide a
                            <a id="image-url-link-twitter" style="color:#6BC4E8">
                              link from the web
                            </a>
                          </p>
                        </div>
                      </div>
                      <button type="button" id="btn-accordion-twitter-close"
                              class="btn btn-accordion mr-2 float-right text-secondary"
                              data-toggle="collapse" data-target="#collapseTwitter"
                              aria-expanded="false" aria-controls="collapseTwitter">
                        <!-- <i class="fas fa-upload"></i> -->
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Start LinkedIn Form -->
          <div class="card">
            <div id="ln-content" class="card-body social-box">
              <form>
                <div class="form-group row">
                  <div class="col-md-12">
                    <img src="../../assets/images/li_circle_32x32.png" alt="LinkedIn Icon"><span
                    class="social-head">LINKEDIN</span>
                    <hr class="social-hr">
                  </div>
                  <div class="col-sm-12">
                    <textarea
                      [(ngModel)]="message.networkData[2] && message.networkData[2].extra.commentary"
                      name="ln-commentary" class="form-control social-form mb-10"
                      style="margin-bottom: 10px;"
                      id="ln-commentary" rows="4"
                      placeholder="Enter the message and URL for your team to share on LinkedIn..."
                      (blur)="onBlurOnInputFields($event)"
                    ></textarea>
                  </div>
                </div>
              </form>

              <div class="row">
                <div *ngIf="paramScheduleId == -1" class="col-12  mt-2 mb-2 cursor-pointer">
                  <p id="btn-accordion-linkedIn" class=" btn-accordion text-secondary float-right"
                     data-toggle="collapse" data-target="#collapseLinkedIn" aria-expanded="false"
                     aria-controls="collapseLinkedIn">
                    Add Image/Video
                  </p>
                </div>
                <div *ngIf="paramScheduleId != -1" class="col-12  mt-2 mb-2 cursor-pointer">
                  <p class=" btn-accordion text-secondary float-right"
                     ngbTooltip='Attachments to scheduled updates for Slack cannot be edited. If you need to edit any attachments, you should delete this "share request" and create a new one.'
                     container="body" placement="right" style="color: #ced4da">
                    Add Image/Video
                  </p>
                </div>
              </div>

              <div class="collapse" id="collapseLinkedIn">
                <div class="col-12 text-center">
                  <div class="card">

                    <!--Linkdlen Upload Photo Section-->
                    <form>
                      <div class="form-group files">
                        <div class="image-uploader-label">
                          <img src="../../assets/images/upload.png"
                               style="width: 55px; height: 55px; object-fit: cover;">
                          <h3 class="text-secondary mt-2"
                              style="font-size:1.3rem; font-weight: 500;">Drag & drop or
                            <span style="color: #6BC4E8; font-weight: 600;">browse</span>
                            files to add them to your
                            linkedIn post.</h3>
                          <div class="content-options mt-4">
                            <p class="font-weight-bold my-0">LinkedIn Media Options</p>
                            <p class="my-0">Up to 9 images (< 5 MB each) or</p>
                            <p class="my-0">1 animated gif ( < 5 MB) or</p>
                            <p class="my-0">1 video (&le; 200 MB)</p>
                          </div>
                        </div>
                        <input type="file" id="linkedIn-upload" class="form-control"
                               multiple="multiple"
                               (change)="linkedInFileChangeEvent($event)"
                               [accept]="['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'video/mp4']"
                               style="opacity: 1;">
                      </div>

                      <div class="row hide-link" id="add_url_linkedIn">
                        <div class="col-sm-11">
                          <input type="text"
                                 [(ngModel)]="liOptionalImageUrl"
                                 name="ln-image-url"
                                 class="form-control social-form form-margin mt-2"
                                 id="ln-image-url"
                                 placeholder="Add the URL of an image to display in your post (optional)"
                                 (blur)="onBlurOnInputFields($event)"
                          />
                        </div>
                        <div class="col-sm-1 mt-3">
                          <a href="javascript:void(0)">
                            <span class="fa fa-question-circle fa-lg"
                                  ngbTooltip='Use this optional field only if you want to display a custom image on LinkedIn (i.e., other than what is pulled by default from the shared content link).'
                                  container="body"
                                  placement="right"
                                  style="color: #ced4da">
                            </span>
                          </a>
                        </div>
                      </div>

                      <!--linkedIn Uploaded/Added Images - Here-->
                      <div class='linkedIn-images'>
                        <app-file-upload
                          *ngFor="let file of linkedinFinalFiles; let oddRow=odd;"
                          [oddRow]="oddRow"
                          (defaultImageOrVideoThumbnailChange)="onDefaultImageOrVideoThumbnailChange($event)"
                          [linkedInFileObject]="file"></app-file-upload>
                      </div>
                      <div class="row m-2">
                        <div class="col-12 text-center">
                          <p>You can also provide a
                            <a id="image-url-link-linkedIn" style="color:#6BC4E8">
                              link from the web
                            </a>
                          </p>
                        </div>
                      </div>
                      <button type="button" id="btn-accordion-linkedIn-close"
                              class="btn mr-2 btn-accordion text-secondary float-right"
                              data-toggle="collapse" data-target="#collapseLinkedIn"
                              aria-expanded="false" aria-controls="collapseLinkedIn">
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Start Facebook Form -->
          <div class="card mb-25">
            <div id="facebook-content" class="card-body social-box">
              <form>
                <div class="form-group row">
                  <div class="col-md-12">
                    <img src="../../assets/images/fb_circle_32x32.png" alt="fb icon"><span
                    class="social-head">FACEBOOK</span>
                    <hr class="social-hr">
                  </div>
                  <div class="col-sm-12">
                    <input type="text" class="form-control social-form"
                           [(ngModel)]="message.networkData[1] && message.networkData[1].extra.link"
                           name="fb-url"
                           id="fb-url"
                           placeholder="Enter a URL to share on Facebook..."
                           (blur)="onBlurOnInputFields($event)"
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- End Forms -->
        </div>
        <!-- End Left Col -->

        <!-- Start Right Col -->
        <div class="col-md-5">
          <div id="preview-your-message" class="card card-outline-inverse slack-box">
            <div class="card-header top-rounded">
              <div [ngClass]="((collaborationPlatformName|lowercase) === 'teams')?'col-sm-12':'col-md-12'">
                <img *ngIf="(collaborationPlatformName|lowercase) === 'slack'"
                     src="../../assets/images/slack_icon.svg"
                     alt="slack icon" style="height: 35px">
                <img *ngIf="(collaborationPlatformName|lowercase) === 'teams'"
                     src="../../assets/images/ps_teams_logo_ltblue_35.png" alt="teams icon"
                     style="height: 35px">
                <span class="section-head">{{collaborationPlatformName | uppercase}}
                  MESSAGE PREVIEW</span>
              </div>
            </div>
            <!-- Start Updates -->
            <div class="card-body">
              <div class="row">
                <div *ngIf="(collaborationPlatformName|lowercase) === 'slack'"
                     class="col-md-12 slack_message_header">
                  <span class="slack_title">Please Share</span>
                  <span class="slack_title_badge">APP</span>
                </div>
                <div *ngIf="(collaborationPlatformName|lowercase) === 'teams'"
                     class="col-md-12 teams_message_header">
                  <span class="teams_title ml-2">Please Share</span>
                </div>
              </div>
              <div
                [ngClass]="((collaborationPlatformName|lowercase) === 'teams' && message.subject)?'teams_box':''">
                <div class="row">
                  <div
                    [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'col-md-11 slack-preview':'col-md-11 teams_message ml-2 pt-2'"
                    id="preview">
                    <mark style="background-color: rgba(242,199,68,.4)"
                          *ngIf="message.includeAtChannel"><strong>@channel</strong>
                    </mark>
                    <span style="white-space:pre-wrap">{{message.subject}}</span>
                  </div>
                </div>
                <div class="preview-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div *ngFor="let _previewLink of previewUrl | async">
                        <hr *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW"
                            [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'mb-1':'mb-2'"/>
                        <div *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW">
                          <p
                            [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'slack_message':'teams_message ml-2 pt-2'">
                            <a
                              *ngIf="_previewLink.title && defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW"
                              href="#"><span>{{_previewLink.title}}</span>
                            </a>
                            <br>
                            <span
                              *ngIf="_previewLink.imageUrl && defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW">
                              <img alt="preview image" style="float: right; width: 100px; padding: 5px"
                                   src="{{defaultImageOrVideoThumbnail && defaultImageOrVideoThumbnail.objectUrl? defaultImageOrVideoThumbnail.objectUrl: _previewLink.imageUrl}}"/>
                            </span>
                            <span *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW"
                                  [innerHTML]="_previewLink.description"></span>
                          </p>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div
                        *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.TWEET_CONTENT || defaultContentToDisplay === DefaultContentToDisplayInSlack.LINKEDIN_POST_CONTENT">
                        <hr [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'mb-1':'mb-2'"/>
                        <div></div>
                        <div>
                          <p
                            [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'slack_message':'teams_message ml-2 pt-2'">
                            <span *ngIf="defaultImageOrVideoThumbnail && defaultImageOrVideoThumbnail.objectUrl">
                              <img alt="preview image" style="float: right; width: 100px; height:100px; padding: 5px"
                                   src="{{defaultImageOrVideoThumbnail.objectUrl}}"/>
                            </span>
                            <span class='twitter'
                                  *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.TWEET_CONTENT"
                                  [innerHTML]="message.networkData.length > 0 ? message.networkData[0].extra.message:''"></span>
                            <span class='linkedin'
                                  *ngIf="defaultContentToDisplay === DefaultContentToDisplayInSlack.LINKEDIN_POST_CONTENT"
                                  [innerHTML]="message.networkData.length > 1 ? message.networkData[2].extra.commentary: ''"></span>
                          </p>
                        </div>
                        <div></div>
                      </div>
                      <div class="btn-group">
                        <div class="btn-group"
                             *ngIf="message.networkData[0] && message.networkData[0].extra.message">
                          <button type="button"
                                  [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'slack_button':'teams_button ml-2'">
                            Post to Twitter
                          </button>
                        </div>
                        <div *ngIf="message.networkData[2] && message.networkData[2].extra.commentary"
                             class="btn-group">
                          <button type="button"
                                  [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'slack_button':'teams_button ml-2'">
                            Post to LinkedIn
                          </button>
                        </div>
                        <div
                          *ngIf="(message.networkData[0] && message.networkData[0].extra.message) || (message.networkData[2] && message.networkData[2].extra.commentary)"
                          class="dropdown">
                          <button type="button"
                                  [ngClass]="((collaborationPlatformName|lowercase) === 'slack')?'slack_button btn btn-primary':'teams_button'"
                                  data-toggle="dropdown">
                            ...
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Web sharing options</a>
                            <a class="dropdown-item" href="#">Sign in to social accounts</a>
                            <a class="dropdown-item" href="#">Help and tutorials</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> <!--end team box-->
              </div>
            </div>
          </div>

          <div *ngIf="(urlPreviewFetched || linkedInContentExists || twitterContentExists) || (twitterFinalFiles && twitterFinalFiles.length > 0) || (linkedinFinalFiles && linkedinFinalFiles.length > 0)"
               class="card slack-box"
               aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div class="card-body">
              <!--Adjusting input date and time for scheduling-->
              <form>
                <div class="example">
                  <div class="container">
                    <div class="mb-4" id="content-selection"
                         *ngIf="urlPreviewFetched || linkedInContentExists || twitterContentExists">
                      <h5
                        class="box-title m-t-30 font-weight-bold schedule-header d-flex justify-content-between">
                        Customize the Share Request
                      </h5>
                      <hr class="social-hr">
                      <div class="container">
                        <div class="content-slack-form" style="font-size: 14px;">
                          <p class="font-italic font-weight-bold">
                            What content should we display in Slack?
                            <a href="javascript:void(0)">
                      <span class="fa fa-question-circle fa-lg"
                            ngbTooltip='This is only for display purposes in Slack and does not alter the content of the prepared LinkedIn or Twitter post(s).'
                            container="body"
                            placement="left"
                            style="color: #ced4da">
                      </span>
                            </a>
                          </p>

                          <div class="abc">
                            <div class="form-check ml-1" *ngIf="urlPreviewFetched">
                              <input class="form-check-input"
                                     (click)="defaultContentToDisplay = DefaultContentToDisplayInSlack.URL_PREVIEW"
                                     [checked]="defaultContentToDisplay === DefaultContentToDisplayInSlack.URL_PREVIEW"
                                     type="radio"
                                     name="flexRadioDefault" id="url_preview">
                              <label class="form-check-label" for="url_preview">
                                URL preview
                              </label>
                            </div>
                            <div class="form-check ml-1" *ngIf="twitterContentExists">
                              <input class="form-check-input"
                                     (click)="defaultContentToDisplay = DefaultContentToDisplayInSlack.TWEET_CONTENT"
                                     [checked]="defaultContentToDisplay === DefaultContentToDisplayInSlack.TWEET_CONTENT"
                                     type="radio" name="flexRadioDefault"
                                     id="tweet_content">
                              <label class="form-check-label" for="tweet_content">
                                Twitter Post Content
                              </label>
                            </div>
                            <div class="form-check ml-1" *ngIf="linkedInContentExists">
                              <input class="form-check-input" type="radio"
                                     (click)="defaultContentToDisplay = DefaultContentToDisplayInSlack.LINKEDIN_POST_CONTENT"
                                     [checked]="defaultContentToDisplay === DefaultContentToDisplayInSlack.LINKEDIN_POST_CONTENT"
                                     name="flexRadioDefault" id="linkedin_post_content">
                              <label class="form-check-label" for="linkedin_post_content">
                                LinkedIn Post Content
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <p
                        *ngIf="(twitterFinalFiles && twitterFinalFiles.length > 0) || (linkedinFinalFiles && linkedinFinalFiles.length > 0)"
                        class="font-italic font-weight-bold"
                        style="font-size: 14px;">
                        <span class="font-italic font-weight-bold">Which attachment should we display in Slack? (choose one)</span>
                        <a href="javascript:void(0)">
                      <span class="fa fa-question-circle fa-lg"
                            ngbTooltip='This is only for display purposes in Slack and does not alter the content of the prepared LinkedIn or Twitter post(s).'
                            container="body"
                            placement="left"
                            style="color: #ced4da">
                      </span>
                        </a>
                      </p>
                      <div *ngIf="twitterFinalFiles && twitterFinalFiles.length">
                        <label class="mt-1">
                          Twitter attachments
                        </label>
                      </div>
                      <div class="row pl-3 pr-3 pb-3" *ngIf="twitterFinalFiles && twitterFinalFiles.length">
                        <div class="col-4" *ngFor="let twFile of twitterFinalFiles;">
                          <label class="thumbnail twitter-thumbnail mt-3"
                                 [ngClass]="defaultImageOrVideoThumbnail && defaultImageOrVideoThumbnail.networkType === twFile.networkType && twFile.isDefaultImage?'checked':''"
                                 (click)="onPreviewImageOrVideoClick($event, twFile, 'TW')"
                                 title="{{twFile.file?.name}}">
                            <img class="portrait" [src]="twFile.objectUrl">
                          </label>
                        </div>
                      </div>

                      <div *ngIf="linkedinFinalFiles && linkedinFinalFiles.length">
                        <label class="mt-1">LinkedIn attachments</label>
                      </div>
                      <div class="row pl-3 pr-3 pb-3" *ngIf="linkedinFinalFiles && linkedinFinalFiles.length">
                        <div class="col-4" *ngFor="let liFile of linkedinFinalFiles;">
                          <label id="v" class="thumbnail linkedin-thumbnail mt-3"
                                 [ngClass]="defaultImageOrVideoThumbnail && defaultImageOrVideoThumbnail.networkType === liFile.networkType && liFile.isDefaultImage?'checked':''"
                                 (click)="onPreviewImageOrVideoClick($event, liFile, 'LI')"
                                 title="{{liFile.file?.name}}">
                            <img class="portrait" [src]="liFile.objectUrl"/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card bg-transparent">
            <div>
              <button (click)="onAreYouSureModal('POST_SCHEDULE', true)"
                      type="submit"
                      class="btn btn-post cta-button pull-right"
                      [disabled]="urlResolvingInProgress || isSchedulePostClicked">
                Post
              </button>
              <button (click)="doReset()"
                      type="submit"
                      class="btn btn-delete cta-button pull-right"
                      [disabled]="isSchedulePostClicked">
                Reset
              </button>
            </div>

            <div *ngIf="!urlResolvingInProgress">
              <a href="javascript:void(0)" class="pull-right" (click)="onSchedulePostCardToggle()">
                <span>Schedule Post</span>
              </a>
            </div>
          </div>

          <div id="collapseSchedulePost" [ngClass]="isSchedulePostClicked ? 'card collapse show' : 'card collapse'">
            <div class="card-body social-box">
              <div>
                <h5 class="box-title m-t-30 font-weight-bold schedule-header d-flex justify-content-between">
                  When should we send the update to Slack?
                </h5>
              </div>
              <div>
                <hr class="social-hr">
                <div class="row">
                  <div>
                    <div class="form-group p-3">
                      <label class="label">Date</label>
                      <div class="input-group date" id="scheduleDatepicker">
                        <input
                          placeholder="mm-dd-yyyy"
                          class="form-control"
                          name="scheduleDatepicker"
                          style="width: auto"
                          [readonly]="true"
                          [minDate]="scheduleTimeInfo.scheduleMinDate"
                          [(ngModel)]="scheduleTimeInfo.scheduleDate"
                          [ngModelOptions]="{standalone: true}"
                          ngbDatepicker
                          #scheduleDatepicker="ngbDatepicker"
                        />
                        <span class="input-group-append input-group-addon" (click)="scheduleDatepicker.toggle()">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="form-group p-3" *ngIf="(tzInfo$ | async) as tzInfo">
                      <label class="label">Time ({{tzInfo.shortName}})</label>
                      <div class="input-group time" id="scheduleTimepicker">
                        <ngb-timepicker
                          name="scheduleTimepicker"
                          readonlyInputs="true"
                          [seconds]="false"
                          [minuteStep]="30"
                          [meridian]="true"
                          [(ngModel)]="scheduleTimeInfo.scheduleTime">
                        </ngb-timepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit"
                          class="btn btn-schedule cta-button pull-right"
                          (click)="onAreYouSureModal('POST_SCHEDULE', false)">
                    Schedule
                  </button>
                  <button type="submit"
                          class="btn btn-delete cta-button pull-right"
                          (click)="onSchedulePostCancel()">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Start Validation Box -->
          <div id="health-check-block" class="card" *ngFor="let _previewLink of previewUrl | async">
            <div class="card-body social-box">
              <form>
                <div class="col-md-12">
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <img src="assets/images/health_check.png" style="height: 32px;" alt="url-health-check-image"/>
                      <span class="social-head">URL Health Check</span>
                    </div>
                    <div class="col-md-12 t-3">
                      <hr class="social-hr mt-3">
                      <div class="form-group row">
                        <div class="pl-4">
                          <em
                            [ngClass]="(_previewLink.validContentUrl)?'fa-check fa-check-green':'fa-times fa-check-error'"
                            class="fa fa-fw"
                            style="padding-right: 5px;">
                          </em>
                          <span>
                            Content link is valid <em>(required)</em>
                          </span><br/>
                          <em
                            [ngClass]="(_previewLink.linkHasTitle)?'fa-check fa-check-green':'fa-times fa-check-error'"
                            class="fa fa-fw" style="padding-right: 5px;">
                          </em>
                          <span>
                            Content link has title
                          </span><br/>
                          <em
                            [ngClass]="(_previewLink.linkHasDescription)?'fa-check fa-check-green':'fa-times fa-check-error'"
                            class="fa fa-fw"
                            style="padding-right: 5px;">
                          </em>
                          <span>
                            Content link has description
                          </span><br/>
                          <em
                            [ngClass]="(_previewLink.linkHasImage)?'fa-check fa-check-green':'fa-times fa-check-error'"
                            class="fa fa-fw" style="padding-right: 5px;">
                          </em>
                          <span>
                            Content link has image
                          </span><br/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group row" style="float: right;">
                        <div>
                          <span><a target="_blank"
                                   href="https://faq.pleaseshare.co/posting-updates/what-is-the-url-health-check"
                                   style="color: #1d1c1d;"><small>Learn More</small></a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
        <!-- End Right Col -->

      </div>
      <!-- End Main Content Area -->
    </form>
  </div>
  <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="errorMessage" class="alert-danger alert" [innerHTML]="errorMessage | keepHtml"></div>
        </div>
        <div class="modal-footer">
          <button *ngIf="!resetNeeded" type="button"
                  class="btn btn-success btn-primary" data-dismiss="modal">Ok
          </button>
          <button *ngIf="resetNeeded" type="button"
                  class="btn btn-delete" (click)="onClickOnResetModalNoButton()"
                  data-dismiss="modal">No, reset
          </button>
          <button *ngIf="resetNeeded" type="button"
                  class="btn btn-post" (click)="onClickOnResetModalYesButton(urlToBeResolved)"
                  data-dismiss="modal">Yes, continue
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="areYouSure" tabindex="-1" role="dialog" aria-labelledby="areYouSureLabelBy" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="areYouSureTitle"></h4>
        </div>
        <div class="modal-body" id="areYouSureCancelBody"></div>
        <div class="modal-footer">
          <input type="hidden" class="form-control" id="are-you-sure-input-type-name">
          <button type="button" (click)="onAreYouSureCancelButtonPress()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="onAreYouSureYesButtonPress()" class="btn btn-success btn-primary" data-dismiss="modal">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
