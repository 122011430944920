import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-workspace-exist',
  templateUrl: './workspace-exist.component.html',
  styleUrls: ['./workspace-exist.component.scss']
})
export class WorkspaceExistComponent implements OnInit {
  public token: string;
  public errorMessage: string;
  private sub: any;
  private activatedRoute: ActivatedRoute;
  public psAppUnInstalled: boolean;
  public installedBy: string;
  public installedDate: string;

  public unInstalledBy: string;
  public unInstalledDate: string;


  constructor(activatedRoute: ActivatedRoute,
              private http: HttpClient,
              public router: Router) {
    this.activatedRoute = activatedRoute;

  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    $('#signupAsSlackAdminSuccessDialog').on('hidden.bs.modal', function () {
      window.location.href = '/#/login';
    });
    this.getCustomerDetailsByToken(this.token);
  }

  backToHome() {
    window.location.href = '/#/home';
  }

  private getCustomerDetailsByToken(token: string): any {
    const myThis = this;
    const signupUserDetailsUrl = `${environment.apiRoot}/slackapp/get-reinstall-user-details`;
    myThis.http.post(signupUserDetailsUrl, {token: token})
      .subscribe(
        (response: any) => {
          response.errors = response.errors || null;
          myThis.errorMessage = '';
          if (response.success === true && response.errors === null) {
            const data = response.data;
            myThis.psAppUnInstalled = data.psAppUnInstalled;
            myThis.unInstalledBy = data.unInstalledBy;
            myThis.unInstalledDate = data.unInstalledDate;
            myThis.installedBy = data.installedBy;
            myThis.installedDate = data.installedDate;
          } else {
            response.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
          }
        },
        (error) => {
          myThis.errorMessage = error.errorMessage || error.error || 'Something weird happened, please try again.';
        }
      );
  }

  reInstall() {
    const myThis = this;
    const checkReInstallation = `${environment.apiRoot}/slackapp/check-reinstallation`;
    myThis.http.post(checkReInstallation, {token: this.token})
      .subscribe(
        (data: any) => {
          data.errors = data.errors || null;
          myThis.errorMessage = '';
          if (data.success === true && data.errors === null) {
            if (data.data.existingUserReInstalling) {
              myThis.router.navigate(['/user/verify-login-creds'], {queryParams: {token: myThis.token}});
            } else {
              myThis.router.navigate(['/user/sign-up-as-admin'], {queryParams: {token: myThis.token}});
            }
          } else {
            data.errors?.forEach(function (e, index) {
              myThis.errorMessage += e.message;
              if (e[index + 1]) {
                myThis.errorMessage += '<br />';
              }
            });
            myThis.errorMessage = myThis.errorMessage || 'Something weird happened, please try again.';
          }
        },
        (error) => {
          myThis.errorMessage = error.errorMessage || error.error || 'Something weird happened, please try again.';
        }
      );
  }
}
