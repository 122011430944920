import {Component, OnInit} from '@angular/core';
import {NavbarService} from '../service/navbar.service';
import {UserProfile} from "../users/UserProfile";
import {UserProfileService} from "../service/user-profile.service";
import {UserLoginService} from "../service/user-login.service";

@Component({
  selector: '[left-sidebar-component]',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  public readonly UserLoginService = UserLoginService;
  public currentUserProfile: UserProfile;

  constructor(
    private userProfileService: UserProfileService,
    public nav: NavbarService) {
  }

  ngOnInit() {
    const myThis = this;
    this.userProfileService.loggedInUserProfile.subscribe((profile: any) => {
      myThis.currentUserProfile = profile as UserProfile;
    });
  }
}
