<div class="wrapper">
  <form class="form-signin">
    <h2 class="form-signin-heading d-flex justify-content-center">Reset Your Password</h2>
    <hr>
    <div class="form-group" *ngIf="errorMessage !== null">
      <div class="alert alert-danger alert-dismissable">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        <strong>{{ errorMessage }}</strong>
      </div>
    </div>
    <flash-messages></flash-messages>
    <div class="form-group" *ngIf="successMessage !== null">
      <div class="alert alert-success alert-dismissable">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
        <strong>{{ successMessage }}</strong>
      </div>
    </div>
    <div class="form-group">
      <label for="pwd" style="font-family: roboto,sans-serif">EMAIL ADDRESS</label>
      <input type="email" [disabled]="true" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" name="email" class="form-control"
             id="email">
    </div>

    <div class="form-group">
      <label for="pwd" style="font-family: roboto,sans-serif">ENTER NEW PASSWORD</label>
      <input type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" name="password"
             class="form-control" id="pwd">
    </div>
    <div class="form-group">
      <label for="pwd" style="font-family: roboto,sans-serif">CONFIRM PASSWORD</label>
      <input type="password" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}" name="cpassword"
             class="form-control" id="cpwd">
    </div>
    <button class="btn btn-post val-button" (click)="onPasswordReset()" type="submit">Reset Password</button>
  </form>
</div>
<div class="login-help d-flex justify-content-center">
  <p style="margin-top: 10px">
    <small><a routerLink="/login">Back to sign in page</a></small>
  </p>
</div>

