<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
  <div class="login-register" style="background-color:#f2f7f8;">
    <div class="login-box card">
      <div class="card-body">
        <form class="form-horizontal form-material" id="loginform">
          <h3 class="box-title m-b-20 text-center">Change Password</h3>
          <div class="form-group">
            <label class="control-label" for="newPassword">New Password</label>
            <input id="newPassword" type="email" maxlength="50" class="form-control"
                   [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group">
            <label class="control-label" for="confirmPassword">Confirm Password</label>
            <input id="confirmPassword" type="email" maxlength="50" class="form-control"
                   [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}">
          </div>

          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-info btn-lg btn-block waves-effect waves-light" type="submit"
                      (click)="onChangePassword()">Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</section>
