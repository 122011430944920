import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {SpinnerVisibilityService} from "ng-http-loader";
import {FlashMessagesService} from 'angular2-flash-messages';
import {UserLoginService} from "../service/user-login.service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

export interface PasswordResetCallback {
  passwordResetCallback(message: string, result: any): void;
}

@Component({
  selector: 'app-reset-password-after-email-changed',
  templateUrl: './reset-password-after-email-changed.component.html',
  styleUrls: ['./reset-password-after-email-changed.component.scss']
})


export class ResetPasswordAfterEmailChangedComponent implements PasswordResetCallback, OnInit, OnDestroy {

  public email: string;
  public oldEmail: string;
  public userName: string;
  public givenName: string;
  public token: string;
  public confirmationCode: string;
  public password: string;
  public confirmPassword: string;
  public errorMessage: string;
  public successMessage: string;
  private sub: any;

  constructor(public router: Router, public route: ActivatedRoute,
              public userService: UserLoginService, private spinner: SpinnerVisibilityService,
              private _flashMessagesService: FlashMessagesService,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.oldEmail = params['oldEmail'];
      this.userName = params['userName'];
      this.givenName = params['givenName'];
      this.token = params['token'];
      this.confirmationCode = params['confirmation_code'];
    });
    this.errorMessage = null;
    this.successMessage = null;
    this._flashMessagesService
      .show('Email Address Confirmed. Please Reset Your Password.', {cssClass: 'alert-success', timeout: 5000});
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onPasswordReset() {
    this.errorMessage = null;
    if (this.password !== this.confirmPassword) {
      this.errorMessage = 'Password and confirm password must be same';
      return;
    }
    this.spinner.show();
    this._resetPasswordAfterEmailUpdate(this.password, this.confirmPassword, this);
  }

  _resetPasswordAfterEmailUpdate(password, confirmPassword, callback: PasswordResetCallback) {
    const url = `${environment.apiRoot}/user/update-email/reset-password`;

    const myThis = this;
    const bodyContents = {
      'token': myThis.token,
      'email': myThis.email,
      'oldEmail': myThis.oldEmail,
      'userName': myThis.userName,
      'confirmation_code': myThis.confirmationCode,
      'givenName': myThis.givenName,
      'password': password,
      'confirm_password': confirmPassword
    };

    myThis.http.post(url, bodyContents)
      .subscribe(
        (data: any) => {
          myThis.spinner.hide();
          const message = data.message || '';
          let errorMessage = '';
          if (data.errors) {
            data.errors.forEach(function (e) {
              errorMessage += e.message + '<br />';
            });
          }
          const success = data.success;
          if (success) {
            callback.passwordResetCallback(null, message);
          } else {
            callback.passwordResetCallback(errorMessage, data);
          }
        },
        (error) => {
          console.log(error);
          callback.passwordResetCallback(error, null);
        }
      );
  }

  passwordResetCallback(errorMessage: string, result: any): void {
    if (errorMessage) {
      this._flashMessagesService
        .show(errorMessage, {cssClass: 'alert-danger', timeout: 5000});
      return
    }
    this._flashMessagesService
      .show('Your password has been reset successfully', {cssClass: 'alert-success', timeout: 4000});
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 4000);
  }
}
