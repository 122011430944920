import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Schedule} from '../schedules/Schedule';

import {CognitoUtil} from './cognito.service';
import {environment} from '../../environments/environment';
import {FeedsEngagementDownloadAsCsvStatsResponse} from "../schedules/FeedsEngagementDownloadAsCsvStatsResponse";

@Injectable()
export class StatisticsService {
  schedules: Schedule[];
  feedsEngagementDownloadAsCsvStatsResponse: FeedsEngagementDownloadAsCsvStatsResponse;

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtil) {

  }


  public getDownloadableStatistics(year: number) {
    // const url = `${environment.apiRoot}/engagement/stats?dateFrom=` + dateFrom + `&dateTo=` + dateTo;
    const url = `${environment.apiRoot}/engagement/stats/download-as-csv/` + year;
    const myThis = this;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders
        .set('Content-Type', 'application/json');

      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            myThis.feedsEngagementDownloadAsCsvStatsResponse = data as FeedsEngagementDownloadAsCsvStatsResponse;
            resolve(myThis.feedsEngagementDownloadAsCsvStatsResponse);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
