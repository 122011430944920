import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {FeedsComponent} from './feeds/feeds.component';
import {LeftSidebarComponent} from './left-sidebar/left-sidebar.component';
import {IntegrationsComponent} from './integrations/integrations.component';
import {UsersComponent} from './users/users.component';
import {RegisterComponent} from './register/register.component';
import {CallbackComponent} from './callback/callback.component';
import {ScheduleService} from './service/schedule.service';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {NewPasswordComponent} from './public/auth/newpassword/newpassword.component';
import {RegistrationConfirmationComponent} from './public/auth/confirm/confirmRegistration.component';
import {ResendCodeComponent} from './public/auth/resend/resendCode.component';
import {ForgotPassword2Component, ForgotPasswordStep1Component} from './public/auth/forgot/forgotPassword.component';
import {MFAComponent} from './public/auth/mfa/mfa.component';
import {JwtComponent} from './secure/jwttokens/jwt.component';
import {CognitoUtil} from './service/cognito.service';
import {UserRegistrationService} from './service/user-registration.service';
import {UserLoginService} from './service/user-login.service';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {InviteUserComponent} from './invite-user/invite-user.component';
import {InviteUserService} from './service/invite-user.service';
import {InviteUserSuccessComponent} from './invite-user-success/invite-user-success.component';
import {InviteUserChangePasswordComponent} from './invite-user-change-password/invite-user-change-password.component';
import {SettingsService} from './service/settings.service';
import {PleaseShareInterceptor} from './interceptor/please-share.interceptor';
import {UserSetupComponent} from './user-setup/user-setup.component';
import {UserSetupService} from './service/user-setup.service';
import {RegisterSuccessComponent} from './register-success/register-success.component';
import {HeaderDetailsResolver} from './service/HeaderDetailsResolver';
import {UserProfileService} from './service/user-profile.service';
import {NgxPaginationModule} from 'ngx-pagination';
import {SubscriptionPlanComponent} from './subscription-plan/subscription-plan.component';
import {NavbarService} from './service/navbar.service';
import {CancelSubscriptionComponent} from './cancel-subscription/cancel-subscription.component';
import {UserIdleModule} from 'angular-user-idle';
import {environment} from '../environments/environment';
import {StatisticsComponent} from './statistics/statistics.component';
import {EscapeHtmlPipe} from './pipes/keep-html.pipe';
import {DataService} from './service/data.service';
import {
  TwitterAuthorizationConfirmationComponent
} from './twitter-authorization-confirmation/twitter-authorization-confirmation.component';
import {
  LinkedinAuthorizationConfirmationComponent
} from './linkedin-authorization-confirmation/linkedin-authorization-confirmation.component';
import {GoogleAnalyticsService} from './service/google.analytics.service';
import {IntercomModule} from 'ng-intercom';
import {UserSetupErrorComponent} from './user-setup-error/user-setup-error.component';
import {StripeCheckoutService} from './service/stripe_checkout.service';
import {CheckoutSuccessComponent} from './checkout-success/checkout-success.component';
import {PsSubscriptionPlanService} from './service/ps_subscription_plan.service';
import {SubscriptionPlanUpdateComponent} from './subscription-plan-update/subscription-plan-update.component';
import {ReplaceCurrencyPipe} from './settings/pipes/ReplaceCurrencyPipe';
import {FlashMessagesModule} from 'angular2-flash-messages';
import {SignUpAsAdminComponent} from './user/sign-up-as-admin/sign-up-as-admin.component';
import {SignupAsInvitedAdminComponent} from './user/signup-as-invited-admin/signup-as-invited-admin.component';
import {WorkspaceExistComponent} from './user/workspace-exist/workspace-exist.component';
import {VerifyLoginCredsComponent} from './user/workspace-exist/verify-login-creds/verify-login-creds.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  ResetPasswordAfterEmailChangedComponent
} from './reset-password-after-email-changed/reset-password-after-email-changed.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {FileSizePipe} from "./home/file-upload/utils";
import {FileUploadComponent, UploadService} from "./home/file-upload";
import {TwitterMediaAssetsValidationService} from "./service/twitter-media-assets-validation-service";
import {LinkedInMediaAssetsValidationService} from "./service/linkedin-media-assets-validation-service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StatisticsService} from "./service/statistics.service";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {CommonModule} from "@angular/common";
import {NgDatePipesModule} from "ngx-pipes";
import {ReportingOverviewDashboardService} from "./service/reporting.overview.dashboard.service";
import {ReportingLeaderboardService} from "./service/reporting.leaderboard.service";
import {ReportingShareRequestsService} from "./service/reporting.share.request.service";
import {ReportingEngagementRequestService} from "./service/reporting.engagement.request.service";
import {AccountAdminsSettingsService} from "./service/account.admins.settings.service";
import {ReactivateInvitedAdminComponent} from './reactivate-invited-admin/reactivate-invited-admin.component';
import {FederatedAuthCallbackComponent} from './federated-auth-callback/federated-auth-callback.component';
import {CampaignComponent} from './campaign/campaign.component';
import {PsFlashMessageUtil} from "./service/ps.flash.message.util";
import {UrlPreviewService} from "./service/url-preview.service";
import {LocalStorageCacheService} from "./service/localstorage.cache.service";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    FeedsComponent,
    LeftSidebarComponent,
    IntegrationsComponent,
    UsersComponent,
    CallbackComponent,
    NewPasswordComponent,
    LoginComponent,
    RegistrationConfirmationComponent,
    ResendCodeComponent,
    ForgotPasswordStep1Component,
    ForgotPassword2Component,
    RegisterComponent,
    MFAComponent,
    JwtComponent,
    AppComponent,
    InviteUserComponent,
    InviteUserSuccessComponent,
    InviteUserChangePasswordComponent,
    ReplaceCurrencyPipe,
    UserSetupComponent,
    RegisterSuccessComponent,
    SubscriptionPlanComponent,
    CancelSubscriptionComponent,
    StatisticsComponent,
    EscapeHtmlPipe,
    TwitterAuthorizationConfirmationComponent,
    LinkedinAuthorizationConfirmationComponent,
    UserSetupErrorComponent,
    CheckoutSuccessComponent,
    SubscriptionPlanUpdateComponent,
    SignUpAsAdminComponent,
    SignupAsInvitedAdminComponent,
    WorkspaceExistComponent,
    VerifyLoginCredsComponent,
    ResetPasswordAfterEmailChangedComponent,
    FileSizePipe,
    FileUploadComponent,
    ReactivateInvitedAdminComponent,
    FederatedAuthCallbackComponent,
    CampaignComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    FlashMessagesModule.forRoot(),
    UserIdleModule.forRoot({idle: environment.applicationIdleTimeout, timeout: 1, ping: 120}),
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    AutocompleteLibModule,
    NgDatePipesModule
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ScheduleService,
    SettingsService,
    AccountAdminsSettingsService,
    StripeCheckoutService,
    PsSubscriptionPlanService,
    CognitoUtil,
    PsFlashMessageUtil,
    LocalStorageCacheService,
    UserRegistrationService,
    UserLoginService,
    UserSetupService,
    NavbarService,
    InviteUserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PleaseShareInterceptor,
      multi: true
    },
    UserProfileService,
    HeaderDetailsResolver,
    DataService,
    GoogleAnalyticsService,
    UploadService,
    StatisticsService,
    TwitterMediaAssetsValidationService,
    LinkedInMediaAssetsValidationService,
    ReportingOverviewDashboardService,
    ReportingLeaderboardService,
    ReportingShareRequestsService,
    ReportingEngagementRequestService,
    UrlPreviewService
  ],
  exports: [
    FileUploadComponent,
    EscapeHtmlPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
