import {Component, OnInit} from '@angular/core';
import {CognitoUtil, LoggedInCallback} from "../service/cognito.service";
import {NavbarService} from "../service/navbar.service";
import {UserLoginService} from "../service/user-login.service";
import {ActivatedRoute} from "@angular/router";
import {UserProfileService} from "../service/user-profile.service";
import {UserProfile} from "../users/UserProfile";

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, LoggedInCallback {

  constructor(public nav: NavbarService,
              private auth: UserLoginService,
              private activatedRoute: ActivatedRoute,
              private userProfileService: UserProfileService,
              private cognitoUtil: CognitoUtil) {
    this.auth.isAuthenticated(this);
  }

  ngOnInit(): void {
    // If navbar component is hidden, show it
    setTimeout(() => {
      this.nav.show();
    }, 0);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.auth.logout();
      window.location.href = '/#/login';
    } else {
      let userName = this.cognitoUtil.getCurrentUser().getUsername();
      let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
      this.userProfileService.changeProfile(profile);
    }
  }
}
