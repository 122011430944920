import {FileObject} from "../home/file-upload";
import {isWebUri} from 'valid-url';

export abstract class MediaAssetsValidationService {
  abstract validate(currentUploadedFiles: FileObject[], files: FileList | null, optionalImageUrl?: string | null);

  protected isValidImageUrl(url: string): boolean {
    if (url == null) {
      return true;
    }

    if (url && !isWebUri(url)) {
      return false;
    }

    const pathname = new URL(url).pathname.toLowerCase();
    return pathname.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
}
