import {Component, OnInit} from '@angular/core';
import {CognitoUtil, LoggedInCallback} from "../service/cognito.service";
import {UserProfile} from "../users/UserProfile";
import {Observable} from "rxjs";
import {NavbarService} from "../service/navbar.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../service/user-login.service";
import {SettingsService} from "../service/settings.service";
import {UserProfileService} from "../service/user-profile.service";
import {StripeCheckoutService} from "../service/stripe_checkout.service";
import {PsSubscriptionPlanService} from "../service/ps_subscription_plan.service";
import {SubscriptionPlanUpdateSessionResponse} from "./subscription.plan.update.session.response";

declare var $: any;

@Component({
    selector: '[id=content-wrapper]',
    templateUrl: './subscription-plan-update.component.html',
    styleUrls: ['./subscription-plan-update.component.scss']
})
export class SubscriptionPlanUpdateComponent implements OnInit, LoggedInCallback {

    public currentUserProfile: UserProfile;
    public userBelongsToGroup: boolean;
    public subscriptionSessionResponse$: Observable<SubscriptionPlanUpdateSessionResponse[]>;
    public errorMessage;
    public successMessage;

    constructor(public nav: NavbarService,
                private http: HttpClient,
                private router: Router,
                public cognitoUtil: CognitoUtil,
                private activatedRoute: ActivatedRoute,
                private auth: UserLoginService,
                private settingsService: SettingsService,
                private userProfileService: UserProfileService,
                private stripeCheckoutService: StripeCheckoutService,
                private  subscriptionPlanService: PsSubscriptionPlanService) {
        this.auth.isAuthenticated(this);
    }

    ngOnInit() {
        // Hide the Navbar component
        setTimeout(() => {
            this.nav.hide();
        }, 0);

        let myThis = this;
        this.userProfileService.loggedInUserProfile.subscribe(profile => {
            myThis.currentUserProfile = profile;
            if (profile == undefined || !profile.hasGroup) {
                myThis.userBelongsToGroup = false;
                return;
            } else {
                myThis.userBelongsToGroup = this.currentUserProfile.hasGroup;
            }
        });

        if (!myThis.userBelongsToGroup) return;
        this.doUpdateSubscriptionPaymentInfo();
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.auth.logout();
            window.location.href = '/#/login';
        } else {
            this.userProfileService.changeProfile(JSON.parse(this.activatedRoute.snapshot.data['userProfile']));
        }
    }

    doUpdateSubscriptionPaymentInfo(): any {
        if (this.currentUserProfile.enterprisePlanUser) {
            return;
        }
        let myThis = this;

        myThis.subscriptionPlanService
            .doGetSubscriptionUpdateSession()
            .then(function (result: SubscriptionPlanUpdateSessionResponse) {
                return result;
            })
            .then(function (subscriptionPlanUpdateSessionResponse: SubscriptionPlanUpdateSessionResponse) {
                myThis
                    .stripeCheckoutService
                    .doUpdatePaymentMethodOption(subscriptionPlanUpdateSessionResponse)
                    .then(function (result: any) {
                        let currentUser = myThis.cognitoUtil.getCurrentUser();
                        if (!currentUser) return;
                        let userName = currentUser.getUsername();
                        let profile: UserProfile = JSON.parse(localStorage.getItem(userName));
                        let groupName = profile.groupName;
                        myThis.userProfileService
                            .doGetUserProfileByGroupName(groupName)
                            .then(function (_profile: UserProfile) {
                                localStorage.removeItem(userName);
                                profile.paidUser = _profile.paidUser;
                                profile.nextPaymentDate = _profile.nextPaymentDate;
                                profile.currentPlanName = _profile.currentPlanName;
                                profile.currentPlanPricing = _profile.currentPlanPricing;
                                profile.currentPlanCurrency = _profile.currentPlanCurrency;
                                profile.paymentMethodEditable = _profile.paymentMethodEditable;
                                profile.subscriptionCancelable = _profile.subscriptionCancelable;
                                profile.nextPaymentDate = _profile.nextPaymentDate;
                                localStorage.setItem(userName, JSON.stringify(profile));
                                myThis.userProfileService.changeProfile(profile);
                                myThis.successMessage = result.message;
                            })
                    })
                    .catch(function (rejectedResult) {
                        if (rejectedResult.message) {
                            myThis.errorMessage = rejectedResult.message;
                        }
                    })
            })
            .catch(function (rejectedResult) {
                if (rejectedResult.message) {
                    myThis.errorMessage = rejectedResult.message;
                } else if (rejectedResult.errors) {
                    let _errorMessage = ""
                    rejectedResult.errors.forEach(function (e) {
                        _errorMessage += e.message
                    });
                    myThis.errorMessage = _errorMessage;
                }
            })
    }

    closeAndResetErrorMessage() {
        this.errorMessage = null;
    }
}
