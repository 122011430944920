import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {FeedsComponent} from './feeds/feeds.component';
import {IntegrationsComponent} from './integrations/integrations.component';
import {UsersComponent} from './users/users.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {CallbackComponent} from './callback/callback.component';
import {RegistrationConfirmationComponent} from './public/auth/confirm/confirmRegistration.component';
import {InviteUserComponent} from './invite-user/invite-user.component';
import {InviteUserSuccessComponent} from './invite-user-success/invite-user-success.component';
import {InviteUserChangePasswordComponent} from './invite-user-change-password/invite-user-change-password.component';
import {UserSetupComponent} from './user-setup/user-setup.component';
import {RegisterSuccessComponent} from './register-success/register-success.component';
import {HeaderDetailsResolver} from './service/HeaderDetailsResolver';
import {ForgotPassword2Component, ForgotPasswordStep1Component} from './public/auth/forgot/forgotPassword.component';
import {SubscriptionPlanComponent} from './subscription-plan/subscription-plan.component';
import {CancelSubscriptionComponent} from './cancel-subscription/cancel-subscription.component';
import {TwitterAuthorizationConfirmationComponent} from './twitter-authorization-confirmation/twitter-authorization-confirmation.component';
import {LinkedinAuthorizationConfirmationComponent} from './linkedin-authorization-confirmation/linkedin-authorization-confirmation.component';
import {UserSetupErrorComponent} from './user-setup-error/user-setup-error.component';
import {CheckoutSuccessComponent} from './checkout-success/checkout-success.component';
import {SubscriptionPlanUpdateComponent} from './subscription-plan-update/subscription-plan-update.component';
import {SignUpAsAdminComponent} from './user/sign-up-as-admin/sign-up-as-admin.component';
import {SignupAsInvitedAdminComponent} from './user/signup-as-invited-admin/signup-as-invited-admin.component';
import {VerifyLoginCredsComponent} from './user/workspace-exist/verify-login-creds/verify-login-creds.component';
import {WorkspaceExistComponent} from './user/workspace-exist/workspace-exist.component';
import {ResetPasswordAfterEmailChangedComponent} from "./reset-password-after-email-changed/reset-password-after-email-changed.component";
import {ReactivateInvitedAdminComponent} from "./reactivate-invited-admin/reactivate-invited-admin.component";
import {FederatedAuthCallbackComponent} from "./federated-auth-callback/federated-auth-callback.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {title: 'Post a New Update to Slack – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {title: 'Post a New Update to Slack – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'home/:scheduleId',
    component: HomeComponent,
    data: {title: 'Post a New Update to Slack – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'product-tour',
    component: HomeComponent,
    data: {title: 'Please Share - Product tour'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'history',
    loadChildren: () => import(`./history/history.module`).then(m => m.HistoryModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import(`./reporting/reporting.module`).then(m => m.ReportingModule)
  },
  {
    path: 'campaign',
    loadChildren: () => import(`./campaign/campaign.module`).then(m => m.CampaignModule)
  },
  {
    path: 'feeds',
    component: FeedsComponent
  },
  {
    path: 'feeds/:feed-authorization-success',
    component: FeedsComponent
  },
  {
    path: 'settings',
    loadChildren: () => import(`./settings/settings.module`).then(m => m.SettingsModule)
  },
  {
    path: 'super-admin-settings',
    loadChildren: () => import(`./super-admin-settings/super-admin-settings.module`).then(m => m.SuperAdminSettingsModule)
  },
  {
    path: 'add-billing',
    component: SubscriptionPlanComponent,
    data: {title: 'Choose your Subscription Plan – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'update-billing',
    component: SubscriptionPlanUpdateComponent,
    data: {title: 'Update your Payment Info – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'cancel-subscription',
    component: CancelSubscriptionComponent,
    data: {title: 'Please Share – Cancel Subscription'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'checkout-success',
    component: CheckoutSuccessComponent,
    data: {title: 'Please Share – Checkout Success'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'integrations',
    component: IntegrationsComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Login – Please Share'}
  },
  {
    path: 'login/:groupName',
    component: LoginComponent,
    data: {title: 'Login – Please Share'}
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {title: 'Register – Please Share'},
    resolve: {userProfile: HeaderDetailsResolver}
  },
  {
    path: 'registration-success/:email',
    component: RegisterSuccessComponent,
    data: {title: 'Registration Success – Please Share'}
  },
  {
    path: 'confirm-register/:username',
    component: RegistrationConfirmationComponent,
    data: {title: 'Confirm Register – Please Share'}
  },
  {
    path: 'forgot-pw',
    component: ForgotPasswordStep1Component,
    data: {title: 'Recover Password – Please Share'}
  },
  {
    path: 'reactivate-invited-admin-pw',
    component: ReactivateInvitedAdminComponent,
    data: {title: 'Reactivate Invited Admin ResetPassword – Please Share'}
  },
  {
    path: 'reset-pw',
    component: ForgotPassword2Component,
    data: {title: 'Reset Password – Please Share'}
  },
  {
    path: 'reset-password',
    component: ResetPasswordAfterEmailChangedComponent,
    data: {title: 'Reset Password – Please Share'}
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'twitter-authorization-success',
    component: TwitterAuthorizationConfirmationComponent,
    data: {
      title: 'Please Share Beta – Twitter Authorization Successful',
      betaPeriodRunning: true
    }
  },
  {
    path: 'linkedin-authorization-success',
    component: LinkedinAuthorizationConfirmationComponent,
    data: {
      title: 'Please Share Beta – LinkedIn Authorization Successful',
      betaPeriodRunning: true
    }
  },
  {
    path: 'invite-user',
    component: InviteUserComponent
  },
  {
    path: 'invite-user-success/:email',
    component: InviteUserSuccessComponent
  },
  {
    path: 'invite-user-change-password/:username/:oldpassword',
    component: InviteUserChangePasswordComponent
  },
  {
    path: 'user/setup/:accountJWTToken',
    component: UserSetupComponent
  },
  {
    path: 'user/setup-error',
    component: UserSetupErrorComponent
  },
  {
    path: 'user/sign-up-as-admin',
    component: SignUpAsAdminComponent
  },
  {
    path: 'user/signup-as-invited-admin',
    component: SignupAsInvitedAdminComponent
  },
  {
    path: 'user/workspace-exist',
    component: WorkspaceExistComponent
  },
  {
    path: 'user/verify-login-creds',
    component: VerifyLoginCredsComponent
  },
  {
    path: 'federation-callback',
    component: FederatedAuthCallbackComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
