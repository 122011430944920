<div class="container-fluid" style="padding: 40px 20px 20px 60px;">
  <div class="row page-titles" style="padding: 20px 0 20px 0;">
  </div>

  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <form class="form-signin">
        <div class="form-group">
          <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible">
            <button type="button" class="close" (click)="closeAndResetErrorMessage()" data-hide="alert">&times;
            </button>
            <div [innerHTML]="errorMessage | keepHtml"></div>
          </div>
        </div>
        <div class="form-group">
          <div *ngIf="successfulCancelMessage!=null" class="alert alert-success alert-dismissible">
            <button type="button" class="close" (click)="closeAndResetCancelSuccessMessage()" data-hide="alert">&times;
            </button>
            <div [innerHTML]="successfulCancelMessage | keepHtml"></div>
          </div>
        </div>
        <h2 *ngIf="!currentUserProfile.enterprisePlanUser" class="form-signin-heading d-flex justify-content-center">Cancel Service</h2>
        <hr *ngIf="!currentUserProfile.enterprisePlanUser">
        <p *ngIf="!currentUserProfile.enterprisePlanUser">As a Please Share customer, you are free to cancel your service at any time.</p>
        <p *ngIf="!currentUserProfile.enterprisePlanUser"> We do not issue refunds, but once you click "Proceed to Cancel" below, your payment method will not be
          charged
          going forward. Should you decide to cancel though, you can continue to use the service until your next renewal
          date.
        </p>
        <p *ngIf="!currentUserProfile.enterprisePlanUser"> We are sorry to lose you as a customer. Please <a href="mailto:support@pleaseshare.co">let us know</a> if
          you feel
          there's anything we can do to improve our service.</p>
        <p *ngIf="!currentUserProfile.enterprisePlanUser">Thank you, <br/>The Please Share Team</p>

        <div *ngIf="currentUserProfile.enterprisePlanUser === true" class="col-md-12">
          <div class="frb-group">
            To cancel service, please email us at support@pleaseshare.co.
          </div>
        </div>
      </form>
    </div>

    <div class="col-lg-3"></div>
    <div class="col-lg-6">
      <div>
        <form style="max-width: 580px;margin: 0 auto;">
          <div class="frb frb-ps form-margin30">
            <div class="float-left">
              <a routerLink="/settings/account-settings"> Back to settings page </a>
            </div>
            <div *ngIf="!successfulCancelMessage && !currentUserProfile.enterprisePlanUser" class="float-right">
              <button type="submit" data-toggle="modal" data-target="#cancelSubscriptionPlan"
                      class="btn btn-checkout cta-button">Proceed to
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
</div>
<div class="modal fade" id="cancelSubscriptionPlan" tabindex="-1" role="dialog" aria-labelledby="cancelSubscriptionPlan"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to cancel your subscription?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary cancel-subscription-plan-btn-no" data-dismiss="modal">No</button>
        <button type="button" (click)="doCancelSubscription()"
                class="btn btn-success btn-primary cancel-subscription-plan-btn-yes" data-dismiss="modal">Yes
        </button>
      </div>
    </div>
  </div>
</div>
