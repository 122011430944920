import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PsSubscriptionPlanService {

  constructor(private http: HttpClient) {

  }

  doGetSubscriptionPlanDetails(): any {
    return this._getSubscriptionPlanDetail();
  }

  doGetSubscriptionUpdateSession(): any {
    return this._getSubscriptionUpdateSession();
  }

  doCancelSubscription() {
    return this._doCancelSubscription();
  }

  private _getSubscriptionPlanDetail() {
    let myThis = this;
    const url = `${environment.apiRoot}/subscription/plan`;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            console.log('Error occurred: ', error);
          }
        );
    });
  }

  private _getSubscriptionUpdateSession() {
    let myThis = this;
    const url = `${environment.apiRoot}/subscription/get-subscription-session`;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            if (data && data.success) {
              resolve(data);
            } else {
              reject(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private _doCancelSubscription() {
    let myThis = this;
    const url = `${environment.apiRoot}/subscription/cancel-subscription`;

    return new Promise((resolve, reject) => {
      let httpHeaders = new HttpHeaders()
        .set('Content-type', 'application/json');
      myThis.http.get(url, {headers: httpHeaders})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            console.log('Error occurred: ', error);
          }
        );
    });
  }
}
